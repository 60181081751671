const xh = {

  loan: {
  "Loan":"Kulungile, makhe sibone ukuba singakwenzela ntoni kwicandelo lelowuni!",
  "Loan2":"Ilowuni uyifumana phantsi kwezinto ezimbini - iNgozi nokuyiFikelela.",
  "Loan3":"Ingozi ilinganiswa ngetyala onalo.  Kujongwa ekuyisebenziseni kwakho ngoku, nakuphi na ukungahlawuli, iintlawulo oziphosileyo okanye ukutyityinjiselwa umnwe. Amanqaku aphantsi akalunganga. Amanqaku aphezulu alungile.",
  "LoanAffordability":"Ukuyifikelela kuthetha umvuzo wakho ngaphandle kwenkcitho yakho. Bonke abanikezeli ngamatyala banendlela abayijonga ngayo ingozi. Ngoko isiphumo sakho siyabonisa. Makhe sijonge ukuyifikelela kwakho",
  "LoanPassFail":"Amanqaku akho etyala abonisa ukuba uyingozi ephantsi kodwa ukufikelela kwakho ngokwezimali ukuhlawula ityala elikhoyo nelitsha kubi kakhulu. Usenokungalinikwa ityala.",
  "LoanFailFail":"Amanqaku akho etyala mabi yaye akunayo imali yokuhlawula ityala elikhoyo okanye ityala elitsha. Uxolo, kodwa sisenokungakuvuleli ityala.",
  "LoanFailPass":"Uyakwazi ukuhlawula ityala onalo kunye nelitsha, kodwa amanqaku akho etyala abonisa ukuba uyingozi enkulu. Usenokungalivulelwa ityala okanye ubizwe inzala enkulu.",
  "LoanPassPass": "Unamanqaku amahle etyala yaye imeko yakho yemali iyakuvumela ukuba uhlawule ityala elikhoyo nelitsha. Uqhube kakuhle! Usenokufanelekela ukufumana ityala elingakumbi.",
  },
  dc: {
  otherServicesPrompt: "Uyafuna ukujonga ezinye iinkonzo zethu?",
  "DC":"Uyalufanelekela ululeko lwetyala ukuba uphangela ixesha elizeleyo yaye unamatyala agqithiseleyo. ",
  "DC2":"Umthetho okhokelayo uthi unamatyala agqithiseleyo ukuba ngaphezu kwesiqingatha somvuzo wakho emva kokutsalwa kwerhafu, sihlawula amatyala.",
  "saveYou1":"Kubonakala ngathi sikongela",
  "saveYou2":"nyanga nganye. Uyafuna omnye wabantu endisebenza nabo akufowunele?",
  "DCFailPass":"Ngokokufikelela kwakho, kubonakala ngathi ungazihlawula izivumelwano zakho zetyala. Ukuba utsala nzima nyanga nganye, ungajonga enoba ufuna ukunciphisa iinkcitho zakho. ",
  "DCPassPass":"Ngokokufikelela kwakho, kubonakala ngathi ungazihlawula izivumelwano zakho zetyala. Ukuba utsala nzima nyanga nganye, ungajonga enoba ufuna ukunciphisa iinkcitho zakho. ",
  "budgetTool":"Ukuba isakukhathaza imeko yakho yemali, kutheni ungayi ku-www.meerkat.co.za ukuze ujonge isixhobo sethu sokubhajetha.",
  },
  cc: {
    "CreditCheck": "Yiprofayili yakho yetyala exelela ababolekisi enoba ukuba ulifanele na ityala ofaka isicelo salo. ",
    "CreditCheck2": "Iprofayili yakho isekelwe kwindlela olisebenzisa ngayo ityala ngoku, ukungahlawuli okanye ukutyityinjiselwa umnwe Amanqaku aphantsi mabi yaye amanqaku aphezulu alungile. ",
    "CreditCheckPassFail": "Iprofayiil yakho intle kakhulu 😎 Uqhube kakuhle! Makhe ndijonge ukufikelela kwakho?",
    "FailFail": "Iprofayili yakho ayikho ntle  🙁 Ungasokola ukufumana ityala.",
    "CreditCheckPassPass": "Ngokokufikelela kwakho, kubonakala ngathi ungazihlawula izivumelwano zakho zetyala. Ukuba utsala nzima nyanga nganye, ungajonga enoba ufuna ukunciphisa iinkcitho zakho.",
    "saveYou1": "Ndinga nako ukuthobela ukuyofikelela kwi R",
    "saveYou2": "Ingaba omnye wabasebenzi bethu bangakutsalela umnxeba?"
  },
  debtC: {
    "DebtConsolidation":"Ukuhlanganisa iTyala kuxa uthatha ilowuni ukuze uhlawule amatyala akho.  Uba nemali enye oyihlawulayo nyanga nganye. Kufuneka ufanelekele ityala. Noko ke, ukuhlanganisa amatyala akukuncedi unciphise amatyala.",
    "DebtC2":"Ululeko lwamatyala luyafana nokuhlanganisa amatyala kodwa akuthathi lowuni. Kunoko imali oyihlawula ngenyanga iyadityaniswa ibe yintlawulo enye ehlisiweyo yaye inzala idla ngokuba phantsi. Ngokungafaniyo nokuhlanganiswa kwetyala apho inzala inokuba phezulu",
  },
  cl: {
    "cl":"Great! Most of your loans and accounts have credit life insurance included as part of your premium. We might be able to reduce this to a more affordable amount.",
    "yourDebt": `according to our records you have "X accounts with Rx of DEBT.`,
    "cl2": `Normally we can save our customers on replacements. Putting more money back in your pocket.`,
    "clHelp": `Would you like us to have a look at this for you?`,
    "clNeedSig": "Thanks! I just need your permission for us to contact your credit providers on your behalf to confirm the actual amount of insurance you are paying.",
    "SmokerYes": "Yes, I smoke",
    "SmokerNo":"No, I don't"
  },
  intro: 'Molo 👋  ndinguMoku, umncedisi wakho oyirobhothi. Ndizokukunceda ungabi namatyala.',
  "intro_2":"Ukuba undinika inkcazelo ndingabona ukuba indlela obhatala ngayo amatyala imi kakuhle okanye kakubi na, ukuba ufuna ukufumana ilowuni okanye ufuna le nkqubo yethu yokuhlolwa kwamatyala.",
  "start": "Ngaba uyafuna ukuqhubeka?",
  "GetStarted":"Qaisla",
  "first":"Ngubani igama lakho?",
  "firstName":"ndicela ubhale igama lakho",
  "last":"Ngubani ifani yakho?",
  "Nice":"Ndiyavuya ukukubhalela lo myalezo",
  "AreUaCitizen":"! Ngaphambi kokuba siqale, ndicela ukubuza ukuba unayo na i-ID yaseMzantsi Afrika?",
  "soSorry":"Uxolo",
  "notSA":"okwangoku sikwazi ukunceda abemi abasisigxina eMzantsi Afrika",
  "smile":"Kodwa loo nto ayithethi kuthi andinakukonwabisa. Ngaba ufuna ukubona into emnandi nyhani?",
  "menu":"Uyafuna ndikuncede namhlanje",
  "letMeSee":"Makhe ndibone ukuba ndithini",
  "needPermission":"Ndifuna imvume yakho yokuthatha inkcazelo efunekayo, ndicela wamkele iMimiselo neMiqathango yethu. Ungayifunda ngokucofa kuphawu lwe ?",
  "anywayCanWeCall":"Kulungile. Ngaba ndingacela omnye wabantu endisebenza nabo ukuba akufowunele?",
  "anywayCall":"Kulungile. Khawundiphindele ifowuni namba yakho?",
  "thanksanyway":"Enkosi! Iinkcukacha zakho ndizithumele kwi-call centre yethu. Omnye umntu endisebenza nabo uza kukufowunela kungekudala!",
  "pity":"Ngelishwa",
  "getting":"Bendisaqala ukukwazi. Yiya ku-www.meerkat.co.za ukuba ufuna nayiphi na inkcazelo engakumbi.",
  "ThankYou":"Enkosi",
  "confirm":"Ukuba akukhathazeki ndicela undiphindele ifowuni namba yakho?",
  "invalidCell":"Ifowni namba yakho asiyiyo",
  "enterCell":"ndicela ufake ifowni namba yakho",
  "working":"Enkosi 👍. Le nto ingabonakala ikukuthanda iindaba, kodwa kufuneka ndikubuze ukuze ndiqiniseke ukuba ndingakunceda. Uyaphangela?",
  "otherIncome":"Ngaba ikho enye imali oyifumana qho?",
  "sorry":"Yho uxolo. Sinceda kuphela abantu abafumana imali qho. Yiya ku-www.meerkat.co.za ukuze ufumane inkcazelo engakumbi ngetyala.",
  "incomeDetails":"Ok, kulungile. Intsapho yakho ifumana malini qho ngenyanga?",
  "income2":"Ukuba ibhekisa kuni, uthini umvuzo wakho noweqabane lakho?",
  "number":"Ndicela ubhale inani",
  "justNumber":"Ndicela ubhale nje inani",
  "idPrompt":"Ndicela undinike ne-ID namba yakho?",
  "idNumberPrompt":"Ok, ndicela ubhale i-ID namba yakho",
  "marriedPrompt":"Ndicela ukubuza, utshatile okanye uyahlalisana? Ezi nkcukacha ziya kundinceda ndichane ngakumbi xa ndikunceda.",
  "dependentsPrompt":"Bangaphi abantu abaxhomekeke kuwe (kuquka iqabane lakho kunye nezalamane) obanyamekelayo?",
  "expensesPrompt":"Khawundixelele ubuncinane zingakanani zizonke iinkcitho zenyanga?",
  "emailPrompt":"Ngaphambi kokuba senze uhlalutyo lwethu, uyayifuna na ikopi yengxelo yakho yetyala?",
  "getEmail":"Ithini i-imeyili adresi yakho?",
  "noEmail":"Ndicela ubhale i-imeyili yakho",
  "invalidEmail":"I-imeyili adresi yakho ayisebenzi",
  "challenge1":"Kufuneka sihlole ukuba ungubani ngaphambi kokuba sikhuphe le nkcazelo. Ndicela uphendule le mibuzo ilandelayo.",
  "passedValidaton":"Enkosi uluphumelele olu vavanyo! Ndiyayithumela ngoku le ngxelo.",
  "SendMsg":"ingxelo ithunyelwe, nalu ushwankathelo lwegrafiki lolinganiso lwakho lwengozi",
  "verificationFailed":"Uxolo, awuluphumelelanga uhlolo, ngoko andinakuku-imeylela ingxelo kodwa ndisengazenzela olwam uhlalutyo.",
  "saveBureau1":"Enye into enokunceda, yinkqubo yethu yoluleko yetyala. Mhlawumbi ndingakwazi ukukunceda",
  "saveBureau2":"qho ngenyanga kodwa, ekubeni ndimtsha kule nto yokuncokola, ngaba ndingacela kwaba bantu ndisebenza nabo ukuba akufowunele?",
  "youSure1":"Nyhani? Awufuni konga",
  "youSure2":"  qho ngenyanga?`",
  "so":"So,",
  "callYou":"ngaba omnye wabantu endisebenza nabo angakutsalela umnxeba onganyanzelisiyo?",
  "checkNumber":"Ndingakufowunela",
  "newNumber":"Ithini ifowni namba yakho?",
  "timeOfDay":"Singakufowunela xesha liphi?",
  "thanks":"Kulungile! Omnye wabantu endisebenza nabo uza kukufowunela.",
  //"working":"uyaphangela?'",
  "sorry1":"Uxolo",
  "sorry2":"kodwa okwangoku sikwazi ukunceda abantu abaphangelayo",
  "bandMessage":"Sinabantakwethu sinebhendi. Iintsuku zokhenketho ziza kukhutshwa kungekudala 😉. Ube nosuku oluhle!",
  "okThen":"Kulungile ke",
  "eMeet":"Kube mnandi ukukubhalela umyalezo",
  "saveEnd":"😀 Kube mnandi kakhulu ukukubhalela umyalezo!",
  "errorEnd":"🙈 Uxolo kodwa ingathi kusasetyenzwa ngam.",
  "callAnyway":"Uyafuna ukuba omnye wabantu abasebenza nam akufownele aze aqhubeke nale ncoko?",
  "saveLeadError":"🙈  Uxolo kodwa ingathi kusasetyenzwa ngam. Ndicela uphinde ubuye ngelinye ixesha.",
  "comeAgain":"Ukuba ufuna siphinde sincokole, ungandifumana apha, okanye, undifowunele kule namba 021 003 4535",
  "byeBye":"Sawubonana",
  "whatHelpPrompt":"Ngoko, ungathanda ukuncedwa ngeyeiphi?",
  "yesPlease": "Ewe Nceda",
  "noThanks": "Hayi Enkosi",
  "keepChattingYes": "Ndingathanda ukuqhubeka ndixoxa.",
  "keepChattingNo": "Ndingathanda ukuba umntu anditsalele umnxeba",
  "invalidID": "Inombolo Yakho ye ID ayivumelekanga",
  "Yes":"Ewe",
  "No":"Hayi",
  "Married":"Utshatile",
  "Single":"Akutshatanga",
  "LiveTogether":"Niyahlalisana",
  "Divorced":"Uwuqhawule umtshato",
  "Separated":"Nahlukene",
  "Now":"Ngoku",
  "Afternoon":"Emva kwemini",
  "Morning":"Kusasa",
  "Evening":"Ebusuku",
  "Thanks":"Enkosi",
  "occupationPmt": "What is your occupation?",
  "occupation": "Please enter your occuptation",
  "employerPmt":"Where are you employed (name of employer)?",
  "employer": "Please enter your employer?",
  'menuLoan': "Inkcitho",
  'menuDC': 'UkuCetyiswa kweMatyala',
  'menuCC': 'I-Credit Check',
  'menuDConsolidate': 'UkuXhobisa amatyala',
  'menuCLI':'I-Insuranceer engabizi kwiiNgxowa',
  'placeHolder':'Nceda thayipha apha',
  'accept': 'Yamukela',
  'cancel':'Khansela',
  'selfEmployed': 'Self Employed', 'partTime': 'Part-Time', 'fullTime': 'Fully Employed',
  'employerTypePmt':'How are you employed?'

}

export default xh