import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import SignatureCanvas from 'react-signature-canvas';
import {
  Card,
  Button,
  Col,
  message,
  Row,
  Form,
  Input,
  Spin,
  AutoComplete
} from 'antd';
import moment from 'moment';
import { meerkatHeader, meerkatFooter} from '../base64';
import { API_URL } from '../config';
import fileDownload from 'js-file-download'

const statusMessage = message;
statusMessage.config({ top: '80%', right: '20%' });

export const banks = [
  'ABSA Bank',
  'African Bank',
  'Bidvest Bank',
  'Capitec Bank',
  'FNB',
  'Investec Bank',
  'Mercantile',
  'Nedbank',
  'Standard Bank',
  'Ubank'
];

export const bankBranches = [
  {
    label: 'ABSA Bank',
    code: '632005'
  },
  {
    label: 'African Bank',
    code: '430000'
  },
  {
    label: 'Bidvest Bank',
    code: '462005'
  },
  {
    label: 'Capitec Bank',
    code: '470010'
  },
  {
    label: 'FNB',
    code: '250655'
  },
  {
    label: 'Investec Bank',
    code: '580105'
  },
  {
    label: 'Mercantile',
    code: '450905'
  },
  {
    label: 'Nedbank',
    code: '198765'
  },
  {
    label: 'Standard Bank',
    code: '51001'
  },
  {
    label: 'Ubank',
    code: '431010'
  }
];

const inputStyle = {
  background: 'rgb(255, 255, 255)',
  color: 'rgb(34, 148, 144)',
  border: '2px solid rgb(34, 148, 144)',
  borderRadius: 8,
  width: 300,
  height: 48
};

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 8 }
};

class DO extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disableSave: true,
      showSpinner: false,
      location: this.props.steps.location,
      clientId: this.props.clientId,
      firstNames: this.props.steps.firstNames,
      surname: this.props.steps.surname,
      idNumber:  this.props.steps.idNumber,
      payment: ' ',
      paymentDay: '',
      bank: this.props.steps.bankName ,
      accountNumber: this.props.steps.accountNo ,
      banks: this.props.banks,
      notDone: true
    };
    this.clicked = false;
    this.accountName = this.props.firstNames + ' ' + this.props.surname;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      console.info('On submit, values are ', values);
      console.info('On submit, account name is ', values.name);
      console.info('On submit, account number is ', values.account);
      if (!err) {
        this.accountName = values.name
        this.setState({
          accountNumber: values.account
        })
        this.saveButton(values.name, values.account)
      }
      return false;
    });
  };

  inputChange = value => {
    this.setState({
      accountNumber: value
    });
  }

  handleChange = value => {
    //console.log('value is ', value)
    this.setState({
      bank: value,
      disableClick: value.length < 2,
      data:
        value.length > 1
          ? banks
              .filter(bank => {
                if (
                  bank &&
                  bank.toUpperCase().indexOf(value.toUpperCase()) > -1
                ) {
                  return true;
                }
                return false;
              })
              .slice(0, 10)
          : []
    });
  };

  saveButton = (name, account) => {
    console.log('save Button was clicked, signaturepad is  ', this.signaturePad + ' and clicked is ', this.clicked)
    console.log('save Button was clicked, state is ', this.state)

    if (this.signaturePad && !this.clicked) {
      console.log('save clicked')
      if (!this.signaturePad.isEmpty()) {
        this.setState({ disableSave: true });
        if (!this.clicked) {
          this.clicked = true;
          // save signature
          let signature = this.signaturePad.toDataURL('image/png');
          console.log('signature is  ', signature)

          this.setState({ showSpinner: true, alertTitle: 'Saving pdf file' });

          request(this.state.clientId, this.state.firstNames, this.state.surname, this.state.idNumber, this.state.location.city, this.state.location.country_name, signature, name, this.state.bank, account, this.state.payment, moment()
          .add(1, 'd')
          .format('D/MM/YYYY'))
            .then ((response) => {
              console.log('create doMandate  returned ', response);
              this.setState({ showSpinner: false });
              })

          this.setState({ notDone : false})

          console.log('save clicked & signature ');

        }
      } else {
        statusMessage.error('Please sign in the box', 5);
      }
    }
  };


  saveSig = () => {
    this.setState({ fromDataURL: this.signaturePad.getTrimmedCanvas().toDataURL('image/png') });

    console.log('enable save ', this.state.disableSave)
    if (this.state.disableSave) {
      console.log('enable save')
      this.setState({ disableSave: false });
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { isMobile } = this.props

    console.log('doMandate props are ', this.props);
    const fullName = this.state.firstNames + ' ' + this.state.surname;
    const amount = this.state.payment;
    const showSpinner = this.state.showSpinner

    if (this.state.notDone) {
      return (
        <Fragment>
          <Form {...formItemLayout} onSubmit={this.handleSubmit}>
            <div
              id="printMe"
              style={{
                width: isMobile ? '98%' : '21cm', overflow: 'auto', margin: isMobile ? "-5% 1% 1%" : '0mm 45mm 30mm 45mm',
                border: '1px solid rgb(34, 148, 144)',
                borderRadius: 15,
                textAlign: 'left'
              }}
            >
              <div
                style={{ marginLeft: 25 }}
                dangerouslySetInnerHTML={meerkatHeader}
              />
              <div
                style={{
                  marginLeft: '5%',
                  marginRight: '5%',
                  marginTop: 7,
                  fontWeight: 400
                }}
              >
                <b>AUTHORITY AND MANDATE FOR PAYMENT INSTRUCTIONS</b>
                <br />
                <br />
                <p>
                  I,{' '}
                  <span style={{ textDecoration: 'underline', fontWeight: 'bold' }}>
                    {fullName.toUpperCase()}
                  </span>{' '}
                  of identity number,{' '}
                  <span style={{ textDecoration: 'underline', fontWeight: 'bold' }}>
                    {this.state.idNumber}
                  </span>
                  , hereby authorise <b>MyMeerkat (PTY) Ltd </b> of{' '}
                  <b>203, Pine Park, 1 Logan Way, Pinelands, 7504</b>, to issue and
                  deliver payment instructions to my banker for collection of
                  insurance premiums against my bank account, being:
          </p>
                <Form.Item {...formItemLayout} label="Account Holder Name">
                  {getFieldDecorator(
                    'name',
                    { initialValue: fullName },
                    {
                      rules: [
                        {
                          required: true,
                          message: "Please input account holder's name"
                        }
                      ]
                    }
                  )(
                    <Input placeholder="Name of account holder" style={inputStyle} />
                  )}
                </Form.Item>
                <Form.Item {...formItemLayout} label="Bank">
                  {getFieldDecorator('bank',
                    { initialValue: this.state.bank },
                    {
                    rules: [
                      {
                        required: true,
                        message: 'Please enter your bank'
                      }
                    ]
                  })(
                    <AutoComplete
                      dataSource={banks}
                      style={inputStyle}
                      //onChange={this.handleChange}
                      onSelect={this.handleSelect}
                      placeholder="Enter your Bank"
                      defaultActiveFirstOption
                      backfill
                      autoFocus
                      filterOption={(inputValue, option) =>
                        option.props.children
                          .toUpperCase()
                          .indexOf(inputValue.toUpperCase()) > -1
                      }
                    />
                  )}
                </Form.Item>
                <Form.Item {...formItemLayout} label="Account Number">
                  {getFieldDecorator('account',
                    { initialValue: this.state.accountNumber },
                    {
                    rules: [
                      {
                        required: true,
                        message: 'Please enter account number'
                      }
                    ]
                  })(<Input placeholder="Enter account number" style={inputStyle} />)}
                </Form.Item>
                <p>
                  The first payment instruction {amount ? ' for R' + amount : ''} will
            be issued and delivered on or after{' '}
                  {moment()
                    .add(1, 'd')
                    .format('D/MM/YYYY')}{' '}
                  and subsequent payment instructions will continue to be delivered in
                  terms of this authority unless cancelled by written notice by myself
                  or MyMeerkat (Pty) Ltd.
          </p>
                <p>
                  I recognise and accept that the Debit Order and/or NAEDO
                  instructions will emanate from MyMeerkat (Pty) Ltd, giving them the
                  authority to collect the monthly insurance premiums from my account.
          </p>
                <p>
                  I accept liability for any bank charges or penalties incurred in the
                  event of unpaid collections for whatever reason.
          </p>
                Signed at­­­­­­­­{' '}
                <span style={{ textDecoration: 'underline', fontWeight: 'bold' }}>
                  {' '}
                  {this.state.location.city}, {this.state.location.country_name}{' '}
                </span>{' '}
                on this date{' '}
                <span style={{ textDecoration: 'underline', fontWeight: 'bold' }}>
                  {moment().format('Do MMMM YYYY')}
                </span>
                <br />
                <br />
                <Row gutter={16} type="flex" justify="start" align="middle">
                  <Col span={12} offset={2}>
                    <div
                      style={{
                        textAlign: 'left',
                        border: '1px solid #229490',
                        borderRadius: '10px',
                        backgroundColor: '#F2F6FC',
                        padding: 3,
                        width: 285,
                        height: 150
                      }}
                    >
                      <SignatureCanvas penColor='black'
                        canvasProps={{ width: 250, height: 153, className: 'sigCanvas' }} ref={(ref) => { this.signaturePad = ref }} onEnd={this.saveSig} />

                      <br />
                      <br />
                      Signature,
                <br />
                      {this.state.firstNames} {this.state.surname}
                      <br />
                      <br />


                      <div style={{ float: 'right' }}>
                        <Button
                          id="clearButton"
                          icon="delete"
                          shape="round"
                          onClick={() => this.signaturePad.clear()}
                        >
                          Clear{' '}
                        </Button>
                        <Button
                          id="saveButton"
                          type="primary"
                          icon="check"
                          shape="round"
                          style={{ marginLeft: 7 }}
                          disabled={this.state.disableSave}
                          htmlType="submit"
                        >
                          Sign and Save{' '}
                        </Button>

                      </div>
                    </div>
                  </Col>
                </Row>
                <br />
                <br />
                <br />
              </div>
              <div
                style={{ textAlign: 'center' }}
                dangerouslySetInnerHTML={meerkatFooter}
              />
            </div>
          </Form>
          <Spin spinning={showSpinner} size="large" style={{ position: 'absolute', bottom: 69, left: '54%' }} delay={1000}>
          </Spin>
        </Fragment>
      );}
    else return (
      <Card style={{ marginTop:21, fontWeight: 'bold',fontSize: 16, border: '1px solid rgb(34, 148, 144)', borderRadius: 15, textAlign: 'center', width: isMobile ? '98%' : '21cm', overflow: 'auto', margin: isMobile ? "-5% 1% 1%" : '0mm 45mm 30mm 45mm' }} >
      "Thank You"
      </Card>
    );
  }
}

export const DOForm = Form.create({ name: 'dynamic_rule' })(DO);

const request = async (clientId, firstNames, surname, idNumber, city, country_name, signature, accountName, bank, accountNumber, payment, startDate) => {
  const accessToken = localStorage.getItem('token');
  const poaSteps = {
    firstNames: firstNames,
    surname: surname,
    idNumber: idNumber,
    city: city,
    country_name: country_name,
    signature: signature,
    date: moment().format('Do MMMM YYYY'),
    accountName: accountName,
    bank: bank,
    bankAccount: accountNumber,
    payment: payment,
    startDate: startDate
  }
  console.log('poaSteps are ', poaSteps)
  const response = await fetch(`${API_URL}client/${clientId}/pdf/do`, { method: 'post', body: JSON.stringify(poaSteps), headers: { 'Content-Type':'application/json', 'Authorization': accessToken ? `JWT ${accessToken}` : null } })
  const data = await response.blob()
  console.log('response is ', data )
  const file = await fileDownload(data, `DOMANDATE-${idNumber}.pdf`)
  //const file = await fileDownload(data, `DOMANDATE-${idNumber}.htm`)
}