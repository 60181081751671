//first value should be same as the trigger in steps

const so = {
  loan: {
  "Loan":"Monate feela! E re re bone hore re ka o thusa kang lefapheng la rona la lounu!",
  "Loan2":"O ka kgona ho fumana lounu ho itshetlehile ka maemo a mabedi - Dikotsi le Bokgoni.",
  "Loan3":"Dikotsi di lekolwa ka boemo ba hao ba mokitlane. Ho hlahloba tshebediso ya hao ya hona jwale, ho sitwa ho patala, ditefello tse fetileng tse sa lefuwang kapa dikotlo tseo o nang le tsona. Boemo bo tlase bo bobe. Boemo bo hodimo bo botle.",
  "LoanAffordability":"Ka bokgoni ba hao ho bolelwa tjhelete eo o e fumanang ha ho se ho ntshitswe ditshenyehelo. Bafani bohle ba mekitlane ba na le tsela ya bona ya ho sheba dikotsi. Kahoo sephetho sa hao ke pontsho ya boemo ba hao. E re re hlahlobe Bokgoni",
  "LoanPassFail":"Boemo ba hao ba mokitlane bo bontsha hore ha o kotsing e kaalo empa bokgoni ba hao ba ditjhelete ba ho patala mokitlane o teng kapa o motjha bo bobe. O ka nna wa tingwa mokitlane.",
  "LoanFailFail":"Boemo ba hao ba mokitlane bo bobe mme ha o na tjhelete ya ho patala sekoloto se teng kapa se setjha. Ka maswabi, o ka nna wa se fumane mokitlane.",
  "LoanFailPass":"O na le tjhelete ya ho patala sekoloto sa hao se teng  kapa se setjha, empa boemo ba hao ba mokitlane bo bontsha hore o kotsing e kgolo. O ka nna wa tingwa mokitlane kapa wa patadiswa phaello e hodimo.",
  "LoanPassPass": "O na le boemo bo botle ba mokitlane mme o ka kgona ho patala sekoloto se teng kapa se setjha. Re ya o babatsa! O ka nna wa fumana mokitlane o eketsehileng.",
  },
  dc: {
  otherServicesPrompt: "Na o ka lakatsa ho sheba ditshebeletso tse ding tsa rona?",
  "DC":"O tshwaleneha ho fumana keletso ka dikoloto haeba o sebetsa ka nako tsohle mme o kganngwe ke dikoloto. ",
  "DC2":"Tsela e ntle ya ho tseba hore o kganngwe ke dikoloto ke haeba halofo ya mokgolo wa hao ka mora ho ntsha lekgetho e patala dikoloto. ",
  "saveYou1":"Ho bonahala re o boloketse tjhelete e kalo ka",
  "saveYou2":"kgwedi le kgwedi. Na e mong wa basebetsi mmoho le nna wa motho a ka o founela?",
  "DCFailPass":"Ho ya ka bokgoni ba hao, ho bonahala eka o ka kgona ho patala ditumellano tsa hao tsa hona jwale tsa mekitlane. Haeba o hula ka thata kgwedi le kgwedi, o ka nna wa leka ho bona hore na o ka se fokotse ditshenyehelo tsa hao tsa kgwedi le kgwedi. ",
  "DCPassPass":"Ho ya ka bokgoni ba hao, ho bonahala eka o ka kgona ho patala ditumellano tsa hao tsa hona jwale tsa mekitlane. Haeba o hula ka thata kgwedi le kgwedi, o ka nna wa leka ho bona hore na o ka se fokotse ditshenyehelo tsa hao tsa kgwedi le kgwedi. ",
  "budgetTool":"Haeba o ntse o tshwenyehile ka ditjhelete tsa hao, ke hobaneng o sa ye ho www.meerkat.co.za ho hlahloba thulusi ya rona ya ho etsa bajete. ",
  },
  cc: {
    "CreditCheck": "Porofaele ya hao ya mokitlane ke yona e bolellang bafani ba mokitlane hore na o motho ya loketseng kapa tjhe bakeng sa mokitlane oo o e kopang.",
    "CreditCheck2": "Porofaele ya hao e itshetlehile ka tshebediso ya mokitlane wa hao hona jwale, ho sitwa ho patala kapa dikotlo tseo o ka nnang wa ba le tsona. Boemo bo tlase bo bobe mme boemo bo hodimo bo botle.",
    "CreditCheckPassFail": "Porofaele ya hao e ntle haholo 😎 Re ya o babatsa! E re ke shebe le bokgoni ba hao?",
    "FailFail": "Porofaele ya hao ha e ntle 🙁 O ka nna wa thatafallwa ho fumana mokitlane. ",
    "CreditCheckPassPass": "Ho ya ka bokgoni ba hao, ho bonahala eka o ka kgona ho patala ditumellano tsa hao tsa hona jwale tsa mekitlane. Haeba o hula ka thata kgwedi le kgwedi, o ka nna wa leka ho bona hore na o ka se fokotse ditshenyehelo tsa hao tsa kgwedi le kgwedi. ",
    "saveYou1": "Nka gona hore ke hobokeletse bokana R",
    "saveYou2": "O ka a mohela mohala ko e mong e kesebetsang le ena?",
  },
  debtC: {
    "DebtConsolidation":"Ho kgahlanyetsa sekoloto ke moo o nkang lounu ho patala dikoloto tsa hao.  Ebe o lefa ha nngwe kgwedi le kgwedi. O lokela ho tshwaneleha ho fumana lounu. Empa ho kgahlanyetsa sekoloto ha ho thuse ho fokotsa dikoloto  tsa hao. ",
    "DebtC2":"Keletso ka dikoloto e tshwana le ho kgahlametsa sekoloto empa yona ha o nke lounu. Ho e na le hoo, tefo ya hao ya kgwedi le kgwedi e tla kopangwa e be tefo e le nngwe e tlase mme hangata le sekgahla sa tswala sea fokotswa. Ho fapana le ha o kgahlanyetsa sekoloto moo phaello e ka bang hodimo",
  },
  cl: {
    "cl":"Great! Most of your loans and accounts have credit life insurance included as part of your premium. We might be able to reduce this to a more affordable amount.",
    "yourDebt": `according to our records you have "X accounts with Rx of DEBT.`,
    "cl2": `Normally we can save our customers on replacements. Putting more money back in your pocket.`,
    "clHelp": `Would you like us to have a look at this for you?`,
    "clNeedSig": "Thanks! I just need your permission for us to contact your credit providers on your behalf to confirm the actual amount of insurance you are paying.",
    "SmokerYes": "Yes, I smoke",
    "SmokerNo":"No, I don't"
  },
  intro: "Dumela 👋 Ke nna Moku, mothusi wa hao wa roboto. Ke mona ho o thusa ho tswa dikolotong.",
  "intro_2":"Ha o ka mpha boitsebiso bo itseng, nka bona hore na boemo ba hao ba ho nka sekoloto bo hantle kapa tjhe, o ka kgona ho thola lounu kapa hore na o ka ruwa molemo tseleng ya rona ya ho hlahloba dikoloto.",
  "start": "O batla ho tswela pele?",
  "GetStarted": "Qala",
  "first":"Lebitso la hao o mang?",
  "firstName":"re kopa o kenye lebitso la hao",
  "last":"Fane ya hao ke mang?",
  "Nice":"Re thabela ho kopana le wena mona ho marangrang ",
  "AreUaCitizen":"! Pele re qala, ke lakatsa ho tiisa hore na o na le nomoro ya ID ya Afrika Borwa?",
  "soSorry":"Phephi",
  "notSA":"ha jwale re kgona ho thusa feela baahi ba Afrika Borwa",
  "smile":"Sena ha se bolele hore ke o qhalla matsoho. O batla ke o bontshe ntho e nngwe e ntle?",
  "menu":"O batla ke o thuse kang kajeno",
  "letMeSee":"E re ke bone he nka etsa eng",
  "needPermission":"Ke hloka tumello ya hao ho bokella boitsebiso bo hlokahalang, jwale he, ke kopa o dumele Melao le Dipehelo tsa rona. O ka e bala ka ho tlanya ho ?",
  "anywayCanWeCall":"OK, ho lokile. O batla ke re mosebetsi mmoho le nna wa motho a o founele?",
  "anywayCall":"Ok. Ke kopa o ko netefatse dinomoro tsa hao tsa founu?",
  "thanksanyway":"Tanki! Ke rometse boitsebiso ba hao ho lefapha la rona la mehala. Ho na le mosebetsi mmoho le nna wa motho ya tla o founela haufinyane!",
  "pity":"Ke maswabi",
  "getting":"Ke ne ke lakaditse ho o tseba.  Etela www.meerkat.co.za haeba o hloka boitsebiso leha e le bofe bo eketsehileng.",
  "ThankYou":"Ke ya leboha",
  "confirm":"Na o ka nnetefaletsa nomoro ya hao ya founu?",
  "invalidCell":"Nomoro ya hao ya sele ha e sebetse",
  "enterCell":"ke kopa o kenye nomoro ya sele",
  "working":"Monate feela 👍. Ena e ka nna ya ba taba e bonahalang e le ya lekunutu, empa ke tlameha ho o botsa yona hore ke tiise hore nka o thusa. O wa sebetsa?",
  "otherIncome":"Na ho na le mokgwa o itseng o o kenyetsang tjhelete kamehla?",
  "sorry":"Eish, phephi. Re ka thusa feela batho ba fumanang tjhelete kamehla. Ka kopo etela www.meerkat.co.za bakeng sa dintlha tse eketsehileng ka sekoloto.",
  "incomeDetails":"Ok, taba tse ntle. Le fumana bokae lapeng la hao kgwedi le kgwedi?",
  "income2":"Haeba ho kgoneha, tjhelete ya hao le ya molekane wa hao?",
  "number":"Ka kopo kenya palo",
  "justNumber":"Ka kopo kenya palo feela",
  "idPrompt":"Na o ka mpha le Nomoro ya hao ya ID?",
  "idNumberPrompt":"Ok, ka kopo kenya nomoro ya hao ya ID",
  "marriedPrompt":"E re ke botse, na o lenyalong kapa o dula le motho e mong? Boitsebiso bona bo tla nthusa hore ke nepisise dintho hantle ha ke o thusa.",
  "dependentsPrompt":"Jwale o hlokomela batho ba bakae (ho akarelletsa molekane wa hao le ba leloko)?",
  "expensesPrompt":"Na o ka mpolella hore na palo hare ya ditshenyehelo tsa hao tsa kgwedi ke bokae?",
  "emailPrompt":"Pele re etsa ditekolo, na o ka lakatsa ho fumana khophi ya tlaleho ya hao ya mokitlane?",
  "getEmail":"Aterese ya hao ya imeile e reng?",
  "noEmail":"Ka kopo kenya imeile ya hao",
  "invalidEmail":"Aterese ya imeile ha e sebetse",
  "challenge1":"Re tlameha ho hlahloba boitsebiso ba hao pele re fana ka tlhahisoleseding ena. Re kopa o arabe dipotso tse latelang.",
  "passedValidaton":"Monate feela, o pasitse teko ena! Jwale ke romela tlaleho.",
  "SendMsg":"tlaleho e rometswe, kerafo ke ena ya kakaretso ya tlhahlobo ya hao",
  "verificationFailed":"Ke maswabi, ha o wa pasa tlhahlobo ya netefatso, kahoo nka se romele tlaleho, empa ntse nka tswela pele ka tekolo yaka.",
  "saveBureau1":"Tsela e nngwe e ka thusang, ke mokgwa wa rona wa keletso ka dikoloto. Nka nna ka kgona ho o behela",
  "saveBureau2":"kgwedi le kgwedi, empa kaha ke motjha nthong ena ya ho qoqa, na o ka founelwa ke mosebetsi mmoho le nna wa motho?",
  "youSure1":"Kannete? Ha o batle ho beha tjhelete",
  "youSure2":" kgwedi le kgwedi?`",
  "so":"Jwale,",
  "callYou":"na mosebetsi mmoho le nna wa motho a ka o founela feela o sa itlame ka letho?",
  "checkNumber":"Nka founa ho",
  "newNumber":"Nomoro ya hao ya sele e reng?",
  "timeOfDay":"Re foune ka nako mang?",
  "thanks":"Monate feela! Jwale he e mong wa basebetsi mmoho le nna o tla o tshwara ka mohala.",
  //"working":"o wa sebetsa?'",
  "sorry1":"Phephi",
  "sorry2":"ho na jwale re kgona ho thusa feela batho ba sebetsang",
  "bandMessage":"Nna le banabeso re na le sehlopha sa mmino. Matsatsi a ho eta a tla lokollwa haufinyane 😉. Eba le letsatsi le monate! ",
  "okThen":"Ho lokile he",
  "eMeet":"ke thabetse ho kopana le wena mona ho marangrang",
  "saveEnd":"😀 Ke thabile haholo hore ebe ke kopane le wena mona ho marangrang",
  "errorEnd":"🙈 Ke maswabi feela ho bonahala ho na le ntho e ntseng e lokiswa.",
  "callAnyway":"Na o lakatsa o founelwe ke mosebetsi mmoho le nna wa motho ho ntshetsa pele moqoqo oo?",
  "saveLeadError":"🙈  Ke maswabi empa ho bonahala ho na le ntho e ntseng e lokiswa. Ka kopo kgutla ha morao.",
  "comeAgain":"Haeba o batla ho qoqa hape, o ka mphumana mona kapa o ka nfounela ho 021 003 4535 ",
  "byeBye":"Sala hantle",
  "whatHelpPrompt":"Jwale ke hokae moo o hlokang thuso teng?",
  "yesPlease": "Ea ke a kopa.",
  "noThanks": "Tshe ke a leboha.",
  "keepChattingYes": "Nka thaba ho bowa le wena hape.",
  "keepChattingNo": "Ke kopa hore motho ang letsetse mohala",
  "invalidID": "Nomoro ya hao ya pasa e fosa etse.",
  "Yes":"E",
  "No":"Tjhee",
  "Married":"Lenyalong",
  "Single":"Lesoha",
  "LiveTogether":"Dula mmoho",
  "Divorced":"Hladile",
  "Separated":"Arohane",
  "Now":"Hona jwale",
  "Afternoon":"Thapama",
  "Morning":"Hoseng",
  "Evening":"Mantsiboya",
  "Thanks":"Tanki",
  "occupationPmt": "What is your occupation?",
  "occupation": "Please enter your occuptation",
  "employerPmt":"Where are you employed (name of employer)?",
  "employer": "Please enter your employer?",
  'menuLoan': "Loan",
  'menuDC': 'Debt Counselling',
  'menuCC': 'Credit Check',
  'menuDConsolidate': 'Debt Consolidation',
  'menuCLI':'Cheaper Insurance on Loans',
  'placeHolder':'Please type here',
  'accept': 'Accept',
  'cancel':'Cancel',
  'selfEmployed': 'Self Employed', 'partTime': 'Part-Time', 'fullTime': 'Fully Employed',
  'employerTypePmt':'How are you employed?'
  }


export default so