//first value should be same as the trigger in steps

const en = {
  loan: {
  "Loan":"Great, let's see what we can do for you in the loan department!",
  "Loan2":"You qualify for a loan based on two elements - Risk and Affordability.",
  "Loan3":"Risk is measured by your credit score. It looks at your current usage, any defaults, missed payments or judgements you may have. A low score is bad. A high score is good.",
  "LoanAffordability":"Affordability is your income less your expenses. All credit providers have their own view on risk. Your result is therefore indicative. Let's check affordability",
  "LoanPassFail":"Your credit score indicates you are low risk but your financial capacity to fund existing and new credit is poor. You may be declined credit.",
  "LoanFailFail":"Your credit score is poor and you do not have the financial capacity to fund existing or new debt. I'm sorry, but you are unlikely to receive credit.",
  "LoanFailPass":"You have the financial capacity to fund your existing and new debt, but your credit score indicates you are high risk. You may be declined credit or be charged higher interest rates.",
  "LoanPassPass": "You have a good credit score and the financial capacity to fund existing and new debt. Well done! You are likely to qualify for further credit.",
  },
  dc: {
  otherServicesPrompt: "Would you like to have a look at our other services?",
  "DC":"You qualify for debt counselling if you are in full time employment and are over-indebted. ",
  "DC2":"A good rule of thumb is that you are over indebted if more than half of your salary after tax, is going to debt repayments. ",
  "saveYou1":"it looks like we save you up to ",
  "saveYou2":" each month. Can one of my human colleagues give you a call?",
  "DCFailPass":"From your affordability, it looks like you can pay your current credit agreements. If you are struggling each month, you might want to see if you can reduce your monthly outgoings. ",
  "DCPassPass":"From your affordability, it looks like you can pay your current credit agreements. If you are struggling each month, you might want to see if you can reduce your monthly outgoings. ",
  "budgetTool":"If you're still worried about your finances, why not go to www.meerkat.co.za to check out our budgeting tool.",
  },
  cc: {
    "CreditCheck": "Your credit profile is what tells lenders whether or not you are a good candidate for the credit you're applying for. ",
    "CreditCheck2": "Your profile is based on current credit usage, defaults or judgements you may have. A low score is bad and a high score is good. ",
    "CreditCheckPassFail": "You're profile's pretty good 😎 Well done! Let me check your affordability as well?",
    "FailFail": "Your profile's not great 🙁 You might struggle to get credit.",
    "CreditCheckPassPass": "From your affordability, it looks like you can pay your current credit agreements. If you are struggling each month, you might want to see if you can reduce your monthly outgoings. ",
    "saveYou1": "I might be able to save you up to ",
    "saveYou2": "Can one of my human colleagues give you a call?"
  },
  debtC: {
    "DebtConsolidation":"Debt Consolidation is where you take out a loan to settle your debts.  You then have one repayment each month.You need to qualify for a loan. However, debt consolidation doesn't help you reduce your debts.",
    "DebtC2":"Debt counselling is similar to debt consolidation but you do not take out a loan. Instead your monthly payment will be consolidated into one reduced monthly repayment and often the interest rates are reduced. Unlike debt consolidation where interest rates can be high",
  },
  cl: {
    "cl":"Great! Most of your loans and accounts have credit life insurance included as part of your premium. We might be able to reduce this to a more affordable amount.",
    "yourDebt": `according to our records you have "X accounts with Rx of DEBT.`,
    "cl2": `Normally we can save our customers on replacements. Putting more money back in your pocket.`,
    "clHelp": `Would you like us to have a look at this for you?`,
    "clNeedSig": "Thanks! I just need your permission for us to contact your credit providers on your behalf to confirm the actual amount of insurance you are paying.",
    "SmokerYes": "Yes, I smoke",
    "SmokerNo":"No, I don't",
  },
  intro: "Hey 👋 I'm Moku, your robo-assistant. I'm here to help you to become debt free.",
  "intro_2":"If you give me some info I can see if your credit score is good or bad, if you would likely qualify for a loan or if you would benefit from our debt review process.",
  "start": "Would you like to continue?",
  "GetStarted": "Get Started",
  "first":"What's your first name?",
  "firstName":"please enter your first name",
  "last":"What's your last name?",
  "Nice":"Nice to e-meet you",
  "AreUaCitizen":"Before we get started, I'd like to confirm whether you have a South African ID number?",
  "soSorry":"I am so sorry",
  "notSA":"but at the moment we are only able to offer assistance to South African permanent residents",
  "smile":"That doesn't mean I can't still put a smile on your face. Do you want to see something really cool?",
  "menu":"How would you like me to help you today",
  "letMeSee":"Let me see what I can do",
  "needPermission":"I need your permission to gather the necessary information, so please accept our Terms and Conditions. You can read them by clicking ? below",
  "anywayCanWeCall":"Ok, that's fine. Should I get one of my human colleagues to give you a call?",
  "anywayCall":"Ok. Please confirm your phone number for me?",
  "thanksanyway":"Thanks! I've sent your details to our call centre. One of my human colleagues will call you back shortly!",
  "pity":"That's a pity",
  "getting":"I was just getting to know you. Visit www.meerkat.co.za if you need any more information.",
  "ThankYou":"Thank you",
  "confirm":"Do you mind confirming your phone number for me please?",
  "invalidCell":"Your cell number is invalid",
  "enterCell":"please enter a cell number",
  "working":"Great 👍 . This may seem a bit personal, but I need to ask it to make sure I can help you. Are you employed?",
  "otherIncome":"Do you have any other sort of regular income?",
  "sorry":"Oops, sorry. We can only help people who have a regular income. Please visit www.meerkat.co.za for more info on debt.",
  "incomeDetails":"Ok, perfect. What is your income before tax each month?",
  "income2":"If applicable, yours and your partner's income?",
  "number":"Please enter a number",
  "justNumber":"Please enter just the number",
  "idPrompt":"Can I also have your ID Number?",
  "idNumberPrompt":"Ok, please enter your ID number",
  "marriedPrompt":"Could I also ask if you are married or living with someone? The info will help me be more accurate when assisting you.",
  "dependentsPrompt":"And how many dependants (including your spouse and extended family) are you responsible for?",
  "expensesPrompt":"Could you let me know what your total monthly expenses are, on average?",
  "emailPrompt":"Before we do our analysis, would you like a copy of your credit report?",
  "getEmail": "What is your email address?",
  "noReport": "Ok, let's see what we can do for you",
  "noEmail":"Please enter your email",
  "invalidEmail":"Email address not valid",
  "challenge1":"We are required to check your identity before we can release this information. Please answer the following questions.",
  "passedValidaton":"Great, you passed the challenge! I'm sending the report now.",
  "SendMsg":"report sent, here is a graphical summary of your risk rating",
  "verificationFailed":"I'm sorry, you didn't pass the validation check, so I can't email the report but I can still do my analysis.",
  "saveBureau1":"One option that could assist, is our debt counselling process. I might be able to save you up to",
  "saveBureau2":"each month but, since I'm new to this chat thing can one of my human colleagues give you a call?",
  "youSure1":"Really? You don't want to save",
  "youSure2":" each month?",
  "so":"So,",
  "callYou":" can one of my human colleagues give you a no obligation call?",
  "checkNumber":"Can I call you on ",
  "newNumber":"What's your mobile number?",
  "timeOfDay":"What time of day should we call?",
  "thanks":"Great! One of my colleagues will be in touch with you then.",
  //"working":"are you working?'",
  "sorry1":"I am so sorry ",
  "sorry2":"but at the moment we are only able to offer assistance to those who are working",
  "bandMessage":"My brothers and I have a band. Tour dates to be released soon 😉. Have a great day!",
  "okThen":"Ok then",
  "eMeet":"it was nice to e-meet you",
  "saveEnd":"😀 It was very nice to e-meet you!",
  "errorEnd":"🙈 I'm sorry but it seems my internals are being worked on.",
  "callAnyway":"Would you like one of my human colleagues to call you to continue this chat?",
  "saveLeadError":"🙈 I'm sorry but it seems my internals are being worked on. Please come back a bit later.",
  "comeAgain":"If you want to chat again, you can find me here, else, you can call me on 021 003 4535",
  "byeBye":"Bye bye",
  "whatHelpPrompt":"So, which would you like help with?",
  "yesPlease": "Yes Please",
  "noThanks": "No thanks",
  "keepChattingYes": "Yes",
  "keepChattingNo": "No",
  "invalidID": "Your ID number is invalid",
  "Yes":"Yes",
  "No":"No",
  "Married":"Married",
  "Single":"Single",
  "LiveTogether":"Live Together",
  "Divorced":"Divorced",
  "Separated":"Separated",
  "Now":"Now",
  "Afternoon":"Afternoon",
  "Morning":"Morning",
  "Evening":"Evening",
  "Thanks": "Thanks",
  "occupationPmt": "What is your occupation?",
  "occupation": "Please enter your occupation",
  "employerPmt":"Where are you employed (name of employer)?",
  "employer": "Please enter your employer?",
  'menuLoan': "Loan",
  'menuDC': 'Debt Counselling',
  'menuCC': 'Credit Check',
  'menuDConsolidate': 'Debt Consolidation',
  'menuCLI': 'Cheaper Insurance on Loans',
  'placeHolder': 'Please type here',
  'accept': 'Accept',
  'cancel': 'Cancel',
  'selfEmployed': 'Self Employed', 'partTime': 'Part-Time', 'fullTime': 'Fully Employed',
  'employerTypePmt':'How are you employed?'
}


export default en