// export const isRequired = value => value ? undefined : I18n.t('validation_error.cannot_be_blank')
// export const isNumber = value => value && isNaN(Number(value)) ? I18n.t('validation_error.must_be_number') : undefined
// export const isInteger = value => value && !/^\d+$/.test(value) ? I18n.t('validation_error.whole_number_only') : undefined
// export const isPositive = value => value && Number(value) < 0 ? I18n.t('validation_error.positive_number_only') : undefined
// export const isValidEmail = value => value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? I18n.t('validation_error.invalid_email') : undefined
// export const minLength = min => value => value && value.length < min ? I18n.t('validation_error.min_length', { min }) : undefined
// export const maxLength = max => value => value && value.length > max ? I18n.t('validation_error.max_length', { max }) : undefined
// export const fieldValueBetween = (field, min, max) => value && value <= max && value >= min ? I18n.t('validation_error.field_value_between', { field, min, max }) : undefined

import moment from 'moment';

import {age} from './calculations'

export const isRequired = value =>
  value ? undefined : 'This field is required';
export const isInteger = value =>
  value && !/^\d+$/.test(value) ? 'Whole numbers only' : undefined;

const allowedPrefixes = [
  '060',
  '061',
  '062',
  '063',
  '064',
  '071',
  '072',
  '073',
  '074',
  '076',
  '078',
  '079',
  '081',
  '082',
  '083',
  '084',
  '085'
];

export const isValidCellphoneNumber = value => {
  return !value ||
    value.length === 0 ||
    allowedPrefixes
      .map(prefix => new RegExp('^' + prefix + '.{7}$'))
      .some(rx => rx.test(value))
    ? undefined
    : 'Invalid cell phone number';
};

export const doesDOBMatchIdNumber = (value, d1, memberIndex) => {
  if (
    d1.members &&
    d1.members[memberIndex] &&
    d1.members[memberIndex].idNumber
  ) {
    // TODO: The year portion is hard-coded to 19XX
    const DOBFromIdNumber = moment(
      `19${d1.members[memberIndex].idNumber.slice(0, 6)}`,
      'YYYY MM DD'
    );
    const DOB = moment(d1.members[memberIndex].DOB);
    if (DOBFromIdNumber.diff(DOB) === 0) {
      return undefined;
    } else {
      return '"Birth Date" does not match "ID Number" above';
    }
  }
};

export const ageMatchesID = (age, idNumber) => {
  if (age && idNumber) {
    const DOBFromIdNumber = moment(`19${idNumber.slice(0, 6)}`, 'YYYY MM DD');
    const currentAgeFromDOB = moment().diff(DOBFromIdNumber, 'years');

    if (currentAgeFromDOB == age) {
      return undefined;
    } else {
      return 'Your age" does not match your ID Number above';
    }
  }
  return undefined;
};


export const creditCheck = idNumber => {
  console.log('ID is ' + idNumber);
  return 'fantastic';
};

export const isValidEmail = value =>
  !value ||
  value.length === 0 ||
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? undefined
    : 'Invalid email address';
//export const isValidAge = value => value && (value >= 18 && value <= 60)

const ageCheck = (value, min, max) => {
  if (value < min || value > max) {
    return `Age must be between ${min} and ${max}`;
  } else {
    return undefined;
  }
};

export const isValidAge = (value, minAge, maxAge) => {
  if (value && value !== null && value !== undefined) {
    // Date object
    if (typeof value === 'object') {
      ageCheck(moment().diff(value, 'year'), minAge, maxAge);
    } else {
      ageCheck(value, minAge, maxAge);
    }
  } else {
    return `Age must be between ${minAge} and ${maxAge}`;
  }
};

// TODO: Validate sex (male/female)
// Adapted from the solution here: http://geekswithblogs.net/willemf/archive/2005/10/30/58561.aspx
export const isValidIdNumber = value => {
  if (value) {
    value = value.toString().replace(' ', '');
  } else {
    return undefined;
  }
  if (value == '0000000000000') return 'Invalid ID number';
  if (!/^\d{10}[0-1]\d{2}$/.test(value)) return 'Invalid ID number';
  let n = value;
  let p1 =
    parseInt(n[0], 10) +
    parseInt(n[2], 10) +
    parseInt(n[4], 10) +
    parseInt(n[6], 10) +
    parseInt(n[8], 10) +
    parseInt(n[10], 10);
  let p2 = (
    parseInt(n[1] + n[3] + n[5] + n[7] + n[9] + n[11], 10) * 2
  ).toString();
  let p3 = 0;
  for (let i = 0; i < p2.length; i++) {
    p3 += parseInt(p2[i]);
  }
  let check = 10 - (p1 + p3).toString()[(p1 + p3).toString().length - 1];
  let check_char = check > 9 ? check.toString()[1] : check.toString();
  if (check_char != value[12]) return 'Invalid ID number';
  return undefined;
};
