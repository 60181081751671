import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Signature } from './customComponents';
import { Modal, Button, message } from 'antd';
import 'antd/lib/button/style/css';
import 'antd/lib/modal/style/css';
import 'antd/lib/message/style/css';
import { buttonStyle } from './menu'
import { english, afrikaans, xhosa, zulu } from './strings/terms'
import I18n from './strings/i18n'

const statusMessage = message;
statusMessage.config({ top: '80%', right:'20%' });

const text = () => {

  switch (I18n.locale) {
    case 'af-US':
      // code block
      return afrikaans
      break;
    case 'xh-US':
      return xhosa
      break;
    case 'zu-US':
      return zulu
      break;
    default:
      return english
  }

}

export class Terms extends Component {
  constructor(props) {
    super(props)
    this.state = {
      text: text()
    }
    this.clicked = false
  }
  state = {
    modal1Visible: false,
    disableButtons: true
  }


  showModal = () => {
    this.setState({
      modal1Visible: true,
    });
  }

  hideModal = () => {
    this.setState({
      modal1Visible: false
    });
  }

  cancelButton = () => {
    if (!this.clicked) {
      this.clicked = true
      this.hideModal()
      console.log('cancel clicked')
      setTimeout(() => { this.props.triggerNextStep({ value: 'no', trigger: 'terms' }) }, 100)
    }
  }
  saveButton = () => {
    console.log('save clicked')
    if (!this.clicked ) {
          this.clicked = true
          this.hideModal()
          console.log('save clicked & signature')
          setTimeout(() => { this.props.triggerNextStep({ value: 'yes', trigger: 'phone' }) }, 100)
    }
  }

  render() {
    return (
      <Fragment>
        <Button style={buttonStyle} style={{ border:0} } onClick={() => this.showModal()}>
          ?
        </Button>

        <Modal title="Meerkat Terms and Conditions September 2017"
          centered
          width='100%'
          bodyStyle={{ backgroundColor: 'rgb(242, 246, 252)' }}
          visible={this.state.modal1Visible}
          onOk={() => this.saveButton()}
          okText={I18n.t('accept')}
          //okButtonProps={{disabled:this.state.disableButtons}}
          onCancel={() => this.cancelButton()}
          cancelText={I18n.t('cancel')}
        //cancelButtonProps={{disabled:this.state.disableButtons}}
        >
          <div style={{ overflow: 'auto', height: '80vh' }} dangerouslySetInnerHTML={this.state.text}>
          </div>
        </Modal>
      </Fragment>
    );
  }
}