import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { Query, Mutation } from 'react-apollo';
import { message, Spin } from 'antd';
import { POA } from './poa';
import { DOForm } from './do';
import { TERMS } from './t&c';
import { getLocation } from '../customComponents';

const statusMessage = message;
statusMessage.config({ top: '80%', right: '20%' });

const writeForm = gql`
mutation StoreClientDocument($clientId: String, $document:String, $name:String, $description:String)
 {
  storeClientDocument(clientId: $clientId, document:$document, name:$name, description:$description)
}
`;

const CREDITLIFE = gql`
  query GetClientInfo($clientId: String!) {
    getClientInfo(clientId: $clientId) {
      firstNames
      surname
      idNumber
      payment
      paymentDay
      bankName
      accountNo
    }
  }
`;
export class Mandates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disableSave: true,
      location: this.props.location,
      clientId: this.props.clientId ,
      isMobile: this.props.isMobile ,
    };
    this.clicked = false;
  }


  render() {
    const clientId = this.props.clientId
    const isMobile = this.state.isMobile
    const { location } = this.state

    console.log('wrapper for client id ', clientId)
    console.log('wrapper props are ', this.props)
    console.log('wrapper state ', this.state)

    if (location) {
      return (

        <Query query={CREDITLIFE} variables={{ clientId, location, isMobile }}>
          {({ loading, error, data }) => {

            if (loading) {
              console.log('loading ', loading);
              return null;
            }
            if (error) {
              console.log('error is ', error);
              return `We're sorry but the network is busy. Please try again in 5 minutes`;
            }
            if (data) {
              { console.log('client data in wrapper is ', data.getClientInfo) }

              const { firstNames, surname, idNumber, payment, paymentDay,  bankName, accountNo} = data.getClientInfo

              if (this.props.esign == 'poa') {
                return <TERMS steps={{firstNames, surname, idNumber, payment, paymentDay, location }}  clientId={clientId } isMobile={isMobile} from={"esign"}/>
              } else
                if (this.props.esign == 'do') {
                  return <DOForm steps={{ firstNames, surname, idNumber, payment, paymentDay, bankName, accountNo, location }} clientId={clientId } isMobile={isMobile} from={"esign"}/>
                }
                if (this.props.esign == 'tc') {
                  return <TERMS steps={{firstNames, surname, idNumber, payment, paymentDay, location }}  clientId={clientId } isMobile={isMobile} from={"esign"}/>
                }
            }
          }}
        </Query>

      )
    }
    else {

      console.log('mandate location is ', this.state.location);
      console.log('mandate id is ', this.state.clientId);

      if (!this.state.location) {
        console.log('Wrapper: find the location from getLocation() is ')
          getLocation().then(obj => {
            this.setState({
              location: obj
            });
            console.log('Wrapper: location from getLocation() is ', obj)
          });
      }
      return <center><Spin tip="loading ..." /></center>
    }
  }
}
