//first value should be same as the trigger in steps

const ve = {
  loan: {
  "Loan":"Zwo ita, irani ndi vhone uri ri nga ni thusa hani kha muhasho wa lounu!",
  "Loan2":"Ni a fanelea u wana lounu zwo thewa kha zwithu zwivhili - Uri ni nga kona u i Badela na u i Swikelela.",
  "Loan3": "Mbadelo i kalwa nga tshikoro tshaṋu tsha tshikolodo. Hu sedziwa mashumisele aṋu a zwino a masheleni, u kundwa u badela, mbadelo dze na dzi pfuka kana mavhonele ane na nga vha ni nao. Tshikoro tsha fhasi a tsho ngo luga. Tshikoro tsha nṱha ndi tshavhuḓi.",
  "LoanAffordability":"U swikelela ri hu vhona musi ro bvisa tshinyalelo dzaṋu kha muholo waṋu. Vhaṋei vhoṱhe vha tshikolodo vha tea u vhona arali ni tshi nga kona u badela tshikolodo. Mvelelo dzaṋu dzi sumbedza zwenezwo. Kha ri vhone arali ni tshi nga swikelela tshikolodo",
  "LoanPassFail":"Tshikoro tshaṋu tsha tshikolodo tshi sumbedza uri ni nga kona u badela, fhedzi masheleni aṋu a sumbedza uri a ni vhuimoni havhuḓi ha u badela tshikolodo tshine na vha natsho na tshiswa. Ni nga kha ḓi sa ṋewa tshikolodo.",
  "LoanFailFail":"Tshikoro tshaṋu tsha tshikolodo a si tshavhuḓi nahone a ni na masheleni a u badela tshikolodo tshine na vha natsho kana tshiswa. Ni mpfarele, fhedzi ni nga kha ḓi sa ṋewa tshikolodo.",
  "LoanFailPass":"Ni na masheleni a u badela tshikolodo tshine na vha natsho na tshiswa, fhedzi tshikoro tshaṋu tsha tshikolodo tshi sumbedza ni nga kha ḓi sa kona u badela. Ni nga kha ḓi sa ṋewa tshikolodo kana ni nga badeliswa nzwalelo dza nṱha.",
  "LoanPassPass": "Ni na tshikoro tshavhuḓi tsha tshikolodo na masheleni ane na nga kona u badela tshikolodo tshine na vha natsho na tshiswa. No shuma! Ni nga kha ḓi fanelea u wana tshiṅwe tshikolodo.",
  },
  dc: {
  otherServicesPrompt: "Ni nga ṱoḓa u vhona dziṅwe tshumelo dzashu?",
  "DC":"Ni a fanelea u ṋewa nyeletshedzo nga ha tshikolodo arali ni na mushumo wa tshifhinga tshoṱhe nahone ni si na zwikolodo zwinzhi. ",
  "DC2":"Tshithu tshine tsha thusa u vhona arali ni na zwikolodo zwihulwane ndi musi masheleni ane a fhira hafu ya muholo waṋu musi ho no bviswa muthelo, a tshi shumiselwa u badela zwikolodo. ",
  "saveYou1":"zwi tou nga ro ni thusa u vhulunga",
  "saveYou2":"ṅwedzi muṅwe na muṅwe. Naa muṅwe wa vhashumisani na nṋe wa muthu a nga ni rwela luṱingo?",
  "DCFailPass":"U ya nga zwine na nga zwi swikelela, zwi vhonala u nga ni nga kona u badela zwikolodo zwaṋu zwa zwino.  Arali ni tshi kundelwa ṅwedzi muṅwe na muṅwe, zwi nga kha ḓi ṱoḓa uri ni vhone arali ni tshi nga fhungudza tshinyalelo dzaṋu dza ṅwedzi muṅwe na muṅwe. ",
  "DCPassPass":"U ya nga zwine na nga zwi swikelela, zwi vhonala u nga ni nga kona u badela zwikolodo zwaṋu zwa zwino.  Arali ni tshi kundelwa ṅwedzi muṅwe na muṅwe, zwi nga kha ḓi ṱoḓa uri ni vhone arali ni tshi nga fhungudza tshinyalelo dzaṋu dza ṅwedzi muṅwe na muṅwe.  ",
  "budgetTool":"Arali ni tshi kha ḓi vhilaedziswa nga masheleni aṋu, zwi ḓo vha zwavhuḓi uri ni dalele www.meerkat.co.za uri ni vhone tshishumiswa tshashu tsha mugaganyagwama.",
  },
  cc: {
    "CreditCheck": "Nyimele yaṋu ya tshikolodo i ita uri vhahadzimisi vha vhone arali vha tshi nga ni hadzima masheleni ane na khou a humbela. ",
    "CreditCheck2": "Nyimele yaṋu yo thewa kha mashumisele aṋu a tshikolodo, u sa badela kana mavhonele ane na nga vha ni nao. Tshikoro tsha fhasi a si tshavhuḓi nahone tshikoro tsha nṱha ndi tshavhuḓi. ",
    "CreditCheckPassFail": "Nyimele yaṋu ndi yavhuḓi vhukuma 😎 No shuma! Irani ndi sedze na zwine na nga kona u zwi swikelela.",
    "FailFail": "Nyimele yaṋu a si yavhuḓi  🙁 Ni nga kha ḓi sa ṋewa tshikolodo.",
    "CreditCheckPassPass": "U ya nga zwine na nga zwi swikelela, zwi vhonala u nga ni nga badela tshikolodo tshaṋu tsha zwino. Arali ni tshi kundelwa ṅwedzi muṅwe na muṅwe, zwi nga kha ḓi ṱoḓa uri ni vhone arali ni tshi nga fhungudza masheleni aṋu ane na a shumisa ṅwedzi muṅwe na muṅwe. ",
  },
  debtC: {
    "DebtConsolidation":"U Hadzima Masheleni a u Fhungudza Tshikolodo ndi u ita lounu u itela u badela zwikolodo zwaṋu. Ni badela luthihi ṅwedzi muṅwe na muṅwe. Ni tea u fanelea u ṋewa lounu. Naho zwo ralo, u hadzima masheleni a u fhungudza tshikolodo a zwi thusi u tsitsa zwikolodo zwaṋu.",
    "DebtC2":"U ṋewa nyeletshedzo nga ha tshikolodo zwi fana na u hadzima masheleni a u fhungudza tshikolodo, fhedzi a ni iti lounu. Nṱhani hazwo mbadelo yaṋu ya ṅwedzi muṅwe na muṅwe i ḓo fhungudzwa ya vha mbadelo ya ṅwedzi muthihi nahone kanzhi nzwalelo dzi a fhungudzwa. A i fani na u hadzima masheleni a u fhungudza tshikolodo ane a nga vha na nzwalelo ya nṱha",
  },
  cl: {
    "cl":"Great! Most of your loans and accounts have credit life insurance included as part of your premium. We might be able to reduce this to a more affordable amount.",
    "yourDebt": `according to our records you have "X accounts with Rx of DEBT.`,
    "cl2": `Normally we can save our customers on replacements. Putting more money back in your pocket.`,
    "clHelp": `Would you like us to have a look at this for you?`,
    "clNeedSig": "Thanks! I just need your permission for us to contact your credit providers on your behalf to confirm the actual amount of insurance you are paying.",
    "SmokerYes": "Yes, I smoke",
    "SmokerNo":"No, I don't",
  },
  intro: "Ri a vusa 👋 Ndi nṋe Moku, muthusi waṋu wa robotho. Ndi ḓo ni thusa u fhelisa tshikolodo tshaṋu.",
  "intro_2":"Arali na nga mmbudza zwo engedzeaho ndi nga ni thusa uri ni vhone arali tshikoro tshaṋu tsha tshikolodo tshi tshavhuḓi kana tshi si tshavhuḓi, arali ni tshi nga fanelea u wana lounu kana arali ni tshi nga vhuyelwa nga maitele a mvusuluso yashu ya tshikolodo.",
  "start": "Ni ṱoḓa u bvela phanḓa?",
  "GetStarted": "Thomani",
  "first":"Ni pfi nnyi?",
  "firstName":"ri humbela uri ni ṅwale dzina ḽaṋu",
  "last":"Tshifani ndi nnyi?",
  "Nice":"Ndi takalela u ni ḓivha",
  "AreUaCitizen":"! Ri sa athu thoma, ndi ṱoḓa u ḓivha arali ni na Basa ya Afurika Tshipembe?",
  "soSorry":"Ni mpfarele nga maanḓa",
  "notSA":"fhedzi zwa zwino ri kona u thusa vhadzulapo vha Afurika Tshipembe fhedzi",
  "smile":"Fhedzi huna zwiṅwe zwithu zwi takadzaho zwine nda nga ni itela zwone. Naa ni ṱoḓa u vhona zwiṅwe zwithu zwavhuḓi vhukuma?",
  "menu":"Ni ṱoḓa uri ndi ni thuse hani ṋamusi",
  "letMeSee":"Irani ndi vhone nḓila ine nda nga ni thusa ngayo",
  "needPermission":"Ni tea u nṋea thendelo uri ndi wane mafhungo o engedzeaho, ndi humbela uri ni ṱanganedze Milayo yashu yo Tiwaho. Ni nga i vhala nga u puṱedza kha ?",
  "anywayCanWeCall":"Ok, zwo luga. Naa ndi nga humbela muṅwe mushumisani na nṋe wa muthu uri a ni rwele luṱingo?",
  "anywayCall":"Ok. Ndi humbela uri ni ṅwalele nomboro yaṋu ya luṱingo.",
  "thanksanyway":"Ndo livhuwa! Ndo rumela zwidodombedzwa zwaṋu kha senthara yashu ya dziṱhingo. Muṅwe wa vhashumisani na nṋe wa muthu u ḓo ni rwela luṱingo hu si kale!",
  "pity":"Pfarelo nandi",
  "getting":"Ndo vha ndo takalela u ni ḓivha. Dalelani www.meerkat.co.za arali ni tshi ṱoḓa mafhungo o engedzeaho.",
  "ThankYou":"Ndo livhuwa",
  "confirm":"Ni nga ṅwalela nomboro yaṋu ya luṱingo?",
  "invalidCell":"Nomboro yaṋu a i shumi",
  "enterCell":"ndi humbela uri ni ṅwale nomboro ya luṱingothendeleki",
  "working":"Zwo ita 👍.  Zwi nga vhonala u nga ndi ṱoḓa u ḓivha mafhungo a tshiphiri, fhedzi ndi ṱoḓa u a vhudzisa u khwaṱhisedza uri ndi nga ni thusa. Ni a shuma?",
  "otherIncome":"Huna masheleni ane na a wana tshifhinga tshoṱhe?",
  "sorry":"Pfarelo. Ri kona fhedzi u thusa vhathu vhane vha vha na masheleni ane a dzhena tshifhinga tshoṱhe. Ri humbela uri ni dalele www.meerkat.co.za uri ni wane zwo engedzeaho nga ha tshikolodo.",
  "incomeDetails":"Ok, zwo ita. Ni wana vhugai muṱani waṋu ṅwedzi muṅwe na muṅwe?",
  "income2":"Arali zwi tshi shuma, masheleni aṋu na a muthu ane na dzula nae ndi vhugai?",
  "number":"Ndi humbela uri ni ṅwale tshivhalo",
  "justNumber":"Ndi humbela uri ni sokou ṅwala tshivhalo fhedzi",
  "idPrompt":"Ndi humbela uri ni ṅwale Nomboro yaṋu ya Basa?",
  "idNumberPrompt":"Ok, ndi humbela uri ni ṅwale nomboro yaṋu ya Basa",
  "marriedPrompt":"Naa ni mbinganoni kana ni na muthu ane na dzula nae? Hezwi zwi ḓo ita uri ndi kone u ni thusa nga nḓila yo teaho.",
  "dependentsPrompt":"Ndi vhathu vhangana (u katela na mufarisi waṋu na miṅwe miraḓo ya muṱa) vhane na vha thusa? ",
  "expensesPrompt":"Tshinyalelo dzaṋu dza ṅwedzi ndi nngana, nga u tou angaredza?",
  "emailPrompt":"Ri sa athu ita tsenguluso ashu, ni nga ṱoḓa kopi ya muvhigo wa tshikolodo tshaṋu?",
  "getEmail":"Ni nga ṅwalela ḓiresi ya imeiḽi yaṋu?",
  "noEmail":"Ndi humbela uri ni ṅwale imeiḽi yaṋu",
  "invalidEmail":"Ḓiresi yaṋu ya imeiḽi a i shumi",
  "challenge1":"Ri tea u ṱolisisa muthu ri sa athu mu vhudza enea mafhungo. Ndi humbela uri ni fhindule mbudziso dzi tevhelaho.",
  "passedValidaton":"Khezwo, no phasa khaedu yashu! Ndi khou ni rumela muvhigo zwino.",
  "SendMsg":"muvhigo wo rumelwa, kheyo girafu ine ya sumbedza manweledzo a vhuimo haṋu ha masheleni",
  "verificationFailed":"Pfarelo, a no ngo phasa khwaṱhisedzo yashu, ndi nga si kone u ni rumela muvhigo, fhedzi ndi nga ita tsenguluso yanga.",
  "saveBureau1":"Tshiṅwe tshine tsha nga thusa ndi maitele ashu a u ṋea nyeletshedzo nga ha zwikolodo. Ndi nga kona u ni vhulungela masheleni a linganaho ",
  "saveBureau2":"ṅwedzi muṅwe na muṅwe, fhedzi samusi ndi tshee muswa kha haya maitele a nyambedzano, naa ndi nga humbela muṅwe wa vhashumisani na nṋe wa muthu uri a ni rwele luṱingo?",
  "youSure1":"Zwa vhukuma? A ni ṱoḓi u vhulunga",
  "youSure2":" ṅwedzi muṅwe na muṅwe?`",
  "so":"Nga zwenezwo,",
  "callYou":"naa muṅwe wa vhashumisani na nṋe wa muthu a nga ni rwela luṱingo ni sa ḓivhofhi nga tshithu?",
  "checkNumber":"Ndi ngani rwela luṱingo kha",
  "newNumber":"I ri mini nomboro ya luṱingothendeleki lwaṋu?",
  "timeOfDay":"Ri ni rwele luṱingo nga tshifhinga-ḓe?",
  "thanks":"Zwo luga! Muṅwe wa vhashumisani na nṋe u ḓo ni kwama.",
  //"working":"ni a shuma?'",
  "sorry1":" Ni mpfarele nga maanḓa ",
  "sorry2":"fhedzi zwa zwino ri kona u thusa vhathu vhane vha shuma fhedzi",
  "bandMessage":"Nṋe na vhakomana vhanga ri na bennde. Hu si kale ri ḓo vhudzwa uri maḓuvha ashu a u thora ndi afhio 😉. Ni vhe na ḓuvha ḽavhuḓi!",
  "okThen":"Ok, a hu na dinani",
  "eMeet":"ndo takalela u amba na inwi",
  "saveEnd":"😀 Ndi takalela nga maanḓa u amba na inwi!",
  "errorEnd":"🙈 Ni mpfarele, fhedzi zwithu zwanga zwi tou nga a zwi khou tshimbila zwavhuḓi.",
  "callAnyway":"Ni nga ṱoḓa uri muṅwe wa vhashumisani na nṋe a bvele phanḓa na yeneyi nyambedzano na inwi?",
  "saveLeadError":"🙈  Ni mpfarele, fhedzi zwithu zwanga zwi tou nga a zwi khou tshimbila zwavhuḓi. Ndi humbela uri ni vhuye nga murahunyana.",
  "comeAgain":"Arali ni tshi ṱoḓa u bvela phanḓa na nyambedzano, ni nga ngwana hafha, kana ni nga nkwama kha 021 003 4535",
  "byeBye":"Ndo ṱuwa",
  "whatHelpPrompt":"Ni ṱoḓa u thuswa nga ifhio?",
  "Yes":"Ee",
  "No":"Hai",
  "Married":"Ndi mbinganoni",
  "Single":"A thi mbinganoni",
  "LiveTogether":"Ndi na muthu ane nda dzula nae",
  "Divorced":"Ro ṱalana",
  "Separated":"Ro fhambana",
  "Now":"Zwino",
  "Afternoon":"Masiari",
  "Morning":"Matsheloni",
  "Evening":"Madekwana",
  "Thanks":"Ndi a livhuwa",
  "occupationPmt": "What is your occupation?",
  "occupation": "Please enter your occuptation",
  "employerPmt":"Where are you employed (name of employer)?",
  "employer": "Please enter your employer?",
  'menuLoan': "Loan",
  'menuDC': 'Debt Counselling',
  'menuCC': 'Credit Check',
  'menuDConsolidate': 'Debt Consolidation',
  'menuCLI':'Cheaper Insurance on Loans',
  'placeHolder':'Please type here',
  'accept': 'Accept',
  'cancel':'Cancel',
  'selfEmployed': 'Self Employed', 'partTime': 'Part-Time', 'fullTime': 'Fully Employed',
  'employerTypePmt':'How are you employed?'
  }


export default ve