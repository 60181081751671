const xhosa = {
  __html: `
  <p><strong>Amagatya kule Migaqo kunye neemeko ezibaluleke okanye ezithatha inqanaba lomngcipheko kuwe unesibindi. Kucelwa uhlawule ngokukodwa ezi ziqendu uze uqinisekise ukuba uyaziqonda. Ukuba awuyiqondi into ethile, nceda usenze ukuba sikuchazele yona.</strong></p>
  <p><strong>1 Iinkcazo</strong></p>
  <p>1.1 "Umhla Wokumkela" uthetha umhla owawwamkelayo isiVumelwano, ngendlela ye-elektroniki medium, umzekelo ngokuchofoza "Ndivuma" kwiphepha lewebhu okanye ngefowuni yakho okanye ngezwi elirekhodiweyo kwifowuni ngeziko lethu loLwazi;</p>
  <p>1.2 "Isivumelwano" sithetha isivumelwano esenziwe phakathi kwakho kunye noMeerkat esi sivumelwano siya kulawulwa kuphela ngala migaqo neemeko kunye nemigaqo-nkqubo yobumfihlo kunye nokuthumela imixholo;</p>
  <p>1.3 "Usuku lwezeShishini" lithetha ngoMvulo ukuya kuLwesihlanu, kodwa alubandakanyi iMigqibelo kunye nosuku oluvulekileyo loluntu kwiRiphablikhi yaseMzantsi Afrika;</p>
  <p>1.4 "iiyure zoShishino" kuthetha iiyure eziphakathi kwe-08h00 no-17h00 kwiShishini loShishino;</p>
  <p>1.5 "UMthetho we-ECT" uthetha uMthetho woThishino loThishino noTshintsho, ngo-2002;</p>
  <p>1.6 "Meerkat", "MyMeerkat" "thina", "us" kunye "yethu" kuthetha iMyMeerkat (Proprietary) Limited;</p>
  <p>I-1.7 "I-Meerkat Marks" ithetha naziphi na izimpawu zokuthengisa, iingubo, abalinganiswa, amagama omnxeba, amagama oorhwebo, amagama e-domain okanye amanye amagama okanye amanqaku kaMeerkat nokuba ubhalisiwe okanye awukho</p>
  <p>I-1.8 "Amalungelo oLondolozo lwePropati" ichaza ilungelo lobunikazi kunoma yimuphi umsebenzi ngokubhekiselele kuMthetho we-Copyright, No. 98 ka-1978, kwaye uquka ngaphandle kokunciphisa ilungelo lokuvelisa loo msebenzi, amalungelo ngokubhekiselele kumashishini athengiswe nguMthetho weMarhwebo , UNombolo 194 ka-1993, amalungelo ngokuphathelele koyilo owenziwe nguMthetho oyi-Designs, uNombolo 195 ka-1993, kunye namalungelo ngokubhekiselele kumthetho we-Patent, uMthetho No. 57 ka-1978 kubandakanye naziphi na izicelo ngasentla kunye nawaphi na amagama, iilayisenisi, uyazi njani, iimfihlo zorhwebo kunye nedatha ehambelana nale nto;</p>
  <p>1.9 "uMntu oMthethweni" uthetha inkampani okanye iqumrhu elifutshane kwaye liquka iqumrhu lequmrhu, ubambiswano, umbutho okanye ithemba;</p>
  <p>1.10 "Izaziso zeZaziso zezomthetho" zithetha http://www.meerkat.co.za/imithombo;</p>
  <p>1.11 "I-NCA" ithetha uMthetho weSizwe weNtengo ka-2005;</p>
  <p>1.12 "Umboneleli weNkonzo yeThathu" uthetha umntu wesithathu onika iinkonzo kubaBhalisi nge-website ye-Meerkat;</p>
  <p>1.13 "iNkonzo" ithetha iingcebiso zezezimali kunye neenkonzo ezinikezwa nguMeerkat;</p>
  <p>1.14 "Umbhalisi", "wena" okanye "uMthengi" uthetha umsebenzisi weyiphi imveliso okanye iinkonzo zethu;</p>
  <p>I-1.15 "Isiganeko esingalawulekiyo" sithetha (kuquka ngaphandle kokunciphisa) nawuphi na umlilo, umkhukula, inyikima, izinto zendalo okanye izenzo zikaThixo, izibambano, ukuphazamiseka kwabantu, ukuvukela okanye ukuguqulwa kwamanye amazwe okanye nayiphi na isizathu esingaphaya kolawulo olufanelekileyo lweMeerkat kuquka ukupheliswa okanye ukumiswa kwenkonzo okanye umkhiqizo owenzelwe umntu wesithathu, onokubangela ukulibaziseka okanye ukungaphumeleli ukubonelela nayiphi na imveliso okanye inkonzo; kwaye</p>
  <p>I-VAT "ithetha iRhafu eHlaziweyo yeRhafu njengoko ihlinzekwe kuMthetho weRhafu oThengisiweyo, ngo-1991.</p>
  <p>1.17 "IWebhsayithi" ithetha nge-www.meerkat.co.za, www.mymeerkat.com, www.mymeerkat.co.uk, www.mymeerkat.co.za kunye ne-www.mkat.co:</p>
  <p><strong>2.0 Ukuqaliswa, ixesha, ukuCima kunye nokuCoca</strong></p>
  <p>2.1 IsiVumelwano siza kuqala ngomhla woMkeleko kwaye sinyamezele ngonaphakade kuze sichithwe njengoko kulungiselelwe kwesi siVumelwano.</p>
  <p>2.2 Isi sivumelwano siquka ngokubhekiselele kwiMigqaliselo yeeWebhsayithi kunye neMibandela neMgaqo-nkqubo weMfihlo eyenziwe kuwe kule website kwaye uzimisele ukuyifunda nokuyiqonda. Ukuba awuyiqondi into, sicela usicele ukuba sikuchazele yona.</p>
  <p>2.3 I-Meerkat inokuphelisa esi siVumelwano ngokukhawuleza kwesaziso kuwe ngenxa yokuphulwa kweMvumelwano.</p>
  <p>2.4 Nangona ukuphela kweSivumelwano, kwimeko apho uyaqhubeka ukusebenzisa iimveliso okanye iinkonzo nangona ukuphela kweSivumelwano, uya kuhlala ubophelelekile kwaye uhlawule ngokukhawuleza zonke iimali eziya kuba ngenxa yeMerkat ngenxa yesiphumo ukusetyenziswa okanye ukufikelela kwimveliso okanye ngenkonzo kwaye esi siVumelwano siya kuthathwa ukuba siqhubeke sisebenza kuze kubekho ixesha elifanayo njengoko zonke iimali ngenxa ye-Meerkat zihlawulwe ngokupheleleyo, kwaye siya kuba nelungelo lokuphelisa isivumelwano nanini na.</p>
  <p>2.5 Ukuba isiVumelwano siphumela kuyo nayiphi na indlela ngqo kuMeerkat okanye ukuthengiselana ngekhompuyutha njengoko kuchazwe kuMthetho we-ECT, uya kuba nelungelo lokukhansela iSivumelwano kwisaziso esibhaliweyo kuMeerkat ngaphandle kwesizathu okanye isohlwayo ngaphakathi kwe-5 (ezintlanu) iintsuku zoShishino -</p>
  <p>2.5.1 kwimeko yeenkonzo kuphela ezinikezelwa ngokweSivumelwano - Usuku lokuMkela; kwaye</p>
  <p>2.5.2 kwimeko apho iimpahla zinikezelwa kwaye zenze isihloko sesiVumelwano, nokuba sisebenzisana neenkonzo okanye ngokwazo-umhla wokuziswa kwezi zinto.</p>
  <p>2.6 Ngenjongo yesigatshana 2.5.2, iimpahla ziquka nayiphi na incwadi, umculo, ifoto, umfanekiso weshukumiso, umdlalo, ulwazi, isofthiwe yeenkcukacha, ikhowudi, izibalo, imodeli, iindlela zoshishino okanye enye imveliso engabonakaliyo okanye nayiphi na imvume yokusebenzisa loo mveliso engabonakaliyo.</p>
  <p><strong>3 Iimeko zokufikelela</strong></p>
  <p>3.1 I-Meerkat iya kwenza inkonzo isebenze kuwe ngomhla woMkeleko.</p>
  <p>3.2 I-Meerkat iya kuthi, apho kufanelekileyo, kukhupha igama lomsebenzisi kunye nephasiwedi kuwe ngaphambi koMhla wokuMkela ukuze ukwazi ukufikelela kunye / okanye ukusebenzisa isevisi. Kwimeko enjalo, awukwazi ukufikelela kunye / okanye ukusebenzisa isevisi ngaphandle kwegama lomsebenzisi kunye nephasiwedi.</p>
  <p>3.3 Uyavuma ukuba:</p>
  <p>3.3.1 uza kusebenzisa igama lakho lomsebenzisi kunye nephasiwedi ukusetyenziswa kwakho kuphela;</p>
  <p>3.3.2 awuyi kutyhila igama lakho lomsebenzisi kunye nephasiwedi kunoma yimuphi umnye umntu naziphi na izizathu nokuba uya kugcina imfihlo yayo;</p>
  <p>3.3.3 kwimeko apho igama lakho lephasiwedi lihlehlisiwe, uya kuzisa ngokukhawuleza i-Meerkat kwaye utshintshe iphasiwedi yakho;</p>
  <p>3.3.4 wena, njengomnini wegama lomsebenzisi kunye nephasiwedi, uyavuma ukuba uyenaxanduva kuphela kwiintlawulo zonke malunga nenkonzo ehlawulwe kwi-akhawunti yakho yaseMeerkat, kungakhathaliseki ukuba inkonzo isetyenzisiwe okanye isetyenziswe nguwe okanye kwaye ngokufanelekileyo yonke imali esele ihlawulwe kwi-akhawunti yakho yaseMeerkat iya kuthathwa ukuba ivela kwi (okanye idibana) nokufikelela kwakho kunye / okanye ukusetyenziswa kweenkonzo;</p>
  <p>3.3.5 uyavuma ukuba bonke abantu abasebenzisa nayiphi na imveliso okanye iinkonzo phantsi kwe-akhawunti yakho okanye negunya lakho lokuthobela isiVumelwano. Zonke izenzo okanye ukushiya bonke abantu abasebenzisa iinkonzo phantsi kwe-akhawunti yakho okanye ngokugunyazwa kwakho kuya kunyangwa kuzo zonke iinjongo njengoko izenzo zakho okanye ukushiya;</p>
  <p>3.3.6 I-Meerkat inikezela ngeenkonzo zokukhumbuza iphasiwedi. Siza kuthumela iphasiwedi kwidilesi yakho ye-imeyile ebhalisiweyo okanye inombolo yefowuni ukuba ulibele iphasiwedi yakho. Ukubhaliselwa kule nkonzo kukuzithandela. Izikhumbuzi zephasiwedi ziza kuthunyelwa kuphela kwiinkcukacha zakho ezibhalisiweyo kwaye ukuba iinkcukacha ezinjalo ziguqulwe yimbopheleleko yakho yokwazisa.</p>
  <p>3.3.7 awuyi kuthi, nangaliphi na ixesha, uvumele imvume kunye / okanye uqalise ukungena kwenethiwekhi ngexesha elifanayo; kwaye</p>
  <p>3.3.8 awuyi kuzama ukukhupha iinkqubo zokuqinisekiswa komsebenzisi waseMeerkat okanye ujoyine imizamo yokufikelela kwinethiwekhi yaseMeerkat apho ungagunyazisiwe ngokucacileyo ukuba wenze njalo.</p>
  <p><strong>4.0 Ukunikezelwa kweenkonzo, ukufumaneka kweenkonzo</strong></p>
  <p><strong> </strong>4.1 I-Meerkat iya kusebenzisa iinjongo ezifanelekileyo ukwenza iinkonzo zayo zifumaneke kubhalisi bayo, kwaye kugcinwe ukufumaneka kwayo kubasebenzisi bayo. Nangona kunjalo, sinika iinkonzo "njengoko kunjalo" kwaye "njengokuba zikhoyo" kwaye awuyikuqinisekisa okanye iinkonzo zokuba iinkonzo ziza kuba zikhululekile kwiiphene okanye ukuphazamiseka. , zikhuseleke kwaye zithembeke.</p>
  <p>4.2 I-Meerkat iya kusebenzisa okusemandleni ukukwazisa kwangaphambi kwayo nayiphi na isondlo kunye nokulungiswa okungabangela ukungabikho kwenkonzo, kodwa ayikwazi ukuqinisekisile oku njalo. Ukongezelela, asikwazi ukuqinisekisa ukuba iinkonzo ziya kusebenza ngokufanelekileyo kuzo zonke izixhobo eziphathekayo okanye ukuba ziya kuhlala zifumaneka ngenxa yemicimbi yokuxhunywa kwenethiwekhi.</p>
  <p>4.3 Kucelwa uqaphele ukuba singumniki-mali weNkonzo wezeMali kwiRiphablikhi yaseMzantsi Afrika. Imveliso kunye neenkonzo zethu azenzelwe, kwaye akunakulungele nayiphi na ilizwe. Sincoma ukuba uqinisekise ukuba ukusebenzisa iinkonzo akuphuli imithetho yeliphi na ilizwe onokulawulwa ngalo. Ukusetyenziswa kwewebhusayithi yethu nangayiphi na injongo kusemngciphekweni wakho.</p>
  <p><strong>5 UNxibelelwano kunye neSigqibo soSombululo</strong></p>
  <p>5.1 Uyavuma ukuba i-Meerkat inokuthi inokuthumela iinkcukacha ngokubhekiselele (ngaphandle kokukhawulelana) kunye nokunikezelwa ngezaphulelo apho i-Meerkat inokuthi ixoxisane ngayo kwaye inikezele kubasebenzisi bayo, utshintsho olwenziwayo oluchaphazela iinkonzo kunye / okanye iinkonzo ezitsha okanye iimveliso ziqaliswe . Yonke inxibelelwano iya kuhambisana neMigaqo-nkqubo Yobumfihlo kunye nomthetho osebenzayo. Uyakuhlala unelungelo lokumxelela ngokubhaliweyo ukuba awunqweneli ukufumana okanye uqhubeke nokufumana olo xwebhu kunye nokuba ungumthengi njengoko kuthethwa kwiCPA, ukukhusela kwangaphambili ukufumana olo hlobo lwanxibelelwano.</p>
  <p>5.2 Nayiphi na ingxabano phakathi kwamaqela angathunyelwa ekubambisanweni aze agqitywe ngokuhambelana nemimiselo yeSiseko soMlambo saseMzantsi Afrika. Ukubambisana okunjalo kuya kubanjelwa eKapa, ​​kwaye kuqhutywe ngolwimi lwesiNgesi ngaphambi kokuba omnye umbambano amiselwe ngokuhambelana nale mithetho. Nayiphi na ibhaso iya kuba yinto yokugqibela kwaye ayiyi kubakho isikhalazo. Esi sivumelwano sokumlingana siya kugxininiswa kuyo, kwaye isigwebo malunga nawuphi na umvuzo singangeniswa kuyo nayiphi na inkundla yeliphi ilizwe elinamandla afanelekileyo. Ingxabano iya kuthathwa ukuba ivele xa elinye iqela likwazisa elinye iqela ngokubhala kuloo mpawu.</p>
  <p>5.3 Umququzeleli uya kuba negunya lokunika isigwebo esingagqibekanga ukuba nayiphi na inxaxheba ihluleka ukwenza izicelo ngokukhawuleza kwaye / okanye ihluleka ukuvela kwi-arbitration.</p>
  <p>5.4 Amalungiselelo achazwe ngasentla akayi kuthintelwa nayiphi na iqela ukuba ifike kuyo nayiphi nayiphi na inkundla yamandla afanelekileyo ukufumana ixesha elifutshane okanye olunye uncedo kwiimeko zokukhawuleza.</p>
  <p><strong>6 Intlawulo</strong></p>
  <p>6.1 I-Meerkat iya kufumana umvuzo we-3.25% (ezintathu iiposenti ezimbini kweepesenti ezintlanu) kwanoma iyiphi i-premium yenyanga ehlawulwa nguwe kwi-OMART ngenjongo yokutshintshwa kweenkonzo ezinikezelweyo.</p>
  <p><strong>7 Ubumfihlo kunye nokuThengwa Kwetyala</strong></p>
  <p>7.1 I-Meerkat iya kujongana nolwazi lwakho ngokubhekiselele kumalungiselelo eMgaqo-nkqubo Wobumfihlo ofumaneka kwi-http: //www.meerkat.co.za/imithombo kunye nokuthobela yonke imithetho efanelekileyo.</p>
  <p>7.2 Isicelo sakho senkonzo okanye umkhiqizo sinokuxhomekeka kwinkqubo yokuhlola ngetyala okanye inkqubo yokuhlola ingozi. Oku kuthetha ukuba i-Meerkat ingacela kwaye ifumane ulwazi lwakho loNgcaciso, uLwazi lweKharityhulam lwaBathengi kunye nolwazi oluMiselweyo (njengoko kuchaziwe kwi-NCA) ("Ulwazi loVavanyo") kwiivenkile zemali ezibhalisiweyo ukwenzela ukuba uvavanyo lweendlela zemali.</p>
  <p>7.3 I-Meerkat inelungelo lokwenza olu vavanyo lweendlela zezimali rhoqo xa ufaka isicelo senkonzo okanye imveliso kwaye nangaliphi na ixesha ngexesha lobukho nawuphi na inkonzo kunye nathi okanye ukuvavanya ukufaneleka kwakho kwemali ngexesha elizayo.</p>
  <p>7.4 Kulo mba uyavuma ukuba u-Meerkat ucele, ufumane kwaye ubike ingxelo yakho yoVavanyo ukusuka kwi-ofisi ye-credit bureaus ngokuhambelana nemimiselo kunye neenjongo ze-NCA; kunye nokwabelana ngeNkcukacha ngee-credit bureaus kunye nabanye abantu njengoko kucacisiwe kwi-NCA, ngenjongo enqunyiwe ye-NCA.</p>
  <p>7.5 Ukuba isicelo sakho sibe sesiphumo sokuthunyelwa ngumcebisi wetyala, uyavuma ukuba u-Meerkat ucele, ufumane kwaye uxelele ulwazi lwakho loVavanyo kunye nabacebisi bentyala abhalisiweyo ngokuhambelana noMgaqo-nkqubo Wobumfihlo.</p>
  <p><strong>8 Amalungelo ePropati yoLuntu</strong></p>
  <p>8.1 Uyavuma ukuthobela yonke imithetho esebenzayo kunoma yiliphi ilungelo leMpahla yokuPhathwa kwePropati ngokuphathelele nayiphi na idatha, iifayile kunye / okanye ulwazi olufumanekayo, olufunyenwe okanye olugcinwe nguwe ngokusebenzisa kwakho nayiphi na inkonzo kunye nemveliso.</p>
  <p>8.2 Uvumelekile ukuba usebenzise nayiphi na iMark Marks ngaphandle kwemvume ebhaliweyo yaseMeerkat.</p>
  <p><strong>9 Ukutyunjwa kweeMviwo</strong></p>
  <p>9.1 Ndiqinisekisa ubungqina be-MyMeerkat (Pty) Ltd., kunye nabameli bayo, abagunyazisiweyo ngokufanelekileyo, njengoMcebisi wezeMali.</p>
  <p>9.2 Ndiyaqonda kwaye ndiyavuma:</p>
  <p>9.2.1 ukuba iingcebiso ezifunyenweyo zingamkela i-MyMeerkat (Pty) Ltd. kunoma yiyiphi imbuyekezo yokuhlaziywa kweembuyekezo kunye / okanye iikhomishini ezinokuhlawulwa phantsi kwamatyala athile kunye / okanye imigaqo-nkqubo.</p>
  <p>9.3 ukuba olo hlobo lugqitywa luya kuphela:</p>
  <p>9.3.1 Amacandelo emveliso apho i-MyMeerkat (Pty) Ltd ilayisenisi yeBhodi yeeNkonzo zeMali, kunye</p>
  <p>9.3.2 Iinkampani eziye zenze i-MyMeerkat (Pty) Ltd iqulethe izivumelwano zoMlomo.</p>
  <p><strong>10 FAIS Ukwaziswa</strong></p>
  <p>10.1 Igama lebhizinisi nguMyMeerkat (Pty) Ltd.</p>
  <p>10.2 Inombolo yobhaliso yenkampani: 2015/102401/07.</p>
  <p>10.3 I-MyMeerkat (Pty) Ltd yinkonzo yemali egunyazisiweyo, ilayisenisi ye-FSB (iBhodi yeeNkonzo zezeMali) phantsi kwezi zilandelayo zigaba</p>
  <p> 1.1 Umshuwalense Wangexesha Elide: Udidi A</p>
  <p> 1.2 Intshurensi yangexesha elifutshane: iiLines Personal</p>
  <p> 1.3 Intshurensi yangexesha elide: Icandelo B</p>
  <p> 1.4 Umshuwalense wexesha elide: Icandelo C</p>
  <p> 1.5 IiNzuzo zoPhulo lweRhafu</p>
  <p> 1.6 Umshuwalense wexesha elifutshane: iiLine zorhwebo</p>
  <p> 1.7 Izinzuzo zeNgxowa-mali zePensheni (ngaphandle kweeNzuzo zePension ye-Retail)</p>
  <p> 1.14 Inzala echaphazelekayo kwiCandelo elinye okanye ngaphezulu kweeNkqubo zokuTyala</p>
  <p> 1.17 Ii-Deposithi njengoko kuchaziwe kuMthetho weeBhanki - ngaphezulu kweenyanga ezili-12</p>
  <p> 1.18 IiDosti njengoko kuchazwe kuMthetho weBhanki - iinyanga ezili-12 okanye ngaphantsi</p>
  <p>10.4 Inombolo yeFSP: 46535.</p>
  <p>Idilesi ebhalisiweyo: 203 I-Pine Park, 1 Logan Way, iPinelands, eKapa, ​​7450.</p>
  <p>10.6 Idilesi yePhysical and Postal Address: 203 iPine Park, 1 Logan Way, iPinelands, eKapa, ​​7450.</p>
  <p>10.7 Iwebhusayithi: https://www.meerkat.co.za</p>
  <p>10.8 Inombolo. +27 (021) 003 4535.</p>
  <p>10.9 Ukuba awukwaneliseki nayiphi na imiba yomgaqo-nkqubo okanye iinkonzo ezinikezwa nguMeerkat egameni le-OMART, nceda uqhagamshelane nathi kwi: <a href="mailto:complaints@meerkat.co.za">complaints@meerkat.co.za</a></p>
  <p>10.10 Igosa lokuLawula iMeerkat yi-Masthead (Pty) Ltd, ifowuni 021 686 3588. Igosa eliLawulayo lijongene nemiba ephathelele ukuthotyelwa kukaMeerkat kunye noMthetho woMcebisi ngezeMali kunye no-Intermediary Services Act, 37 ka-2002 (uMthetho we-FAIS).</p>
  <p>10.11 Ukuba uvakalelwa kukuba uMeerkat uye waphula imithetho ye-FAIS Act, kunye / okanye unikele iinkonzo zezimali ngendlela enokukuchaphazela, sicela uqhagamshelane nathi. Ngenye indlela, ungaqhagamshelana ne-FAIS Ombudsman: 012 470 9080 / info@faisombud.co.za / www.faisombud.co.za.</p>
  <p>10.12 Ukuba unesikhalazo esixhomekeke kwinkontrakthi yomshuwalense, ungaqhagamshelana ne-Ombudsman ye-Intshurensi ende. 021 657 5000 / info@ombud.co.za / www.ombud.co.za. I-Ombudsman ifumaneka ngcebiso malunga nezikhalazo malunga namabango okanye nayiphi na enye into engaphendululwanga ngokufanelekileyo ngoMeerkat.</p>
  <p>Umthengi weMveliso ye-10.13: UkuTshintshwa koMngcipheko woMnye woMdala we-Mutual Limited, ibharensi yexesha elide elibhaliswe kunye ne-Subscriber ye-Old Mutual Group.</p>
  <p>10.14 Abacebisi bethu abangenakuhlangabezana nezidingo ezipheleleyo kunye nezidingo ezifanelekileyo njengoko kuchazwe yi-FAIS iinkonzo phantsi kolawulo lolawulo.</p>
  <p>I-10.15 i-Meerkat ine-inshorensi ye-indemnity insurance.</p>
  <p>I-10.16 i-Meerkat ine kwaye igcina iMigaqo-nkqubo yokuLawula iMali ngokuhambelana noMthetho we-FAIS.</p>
  <p>I-10.17 i-Meerkat igcina iirekhodi zonke iintsebenzo ezibandakanya iingxoxo zefoni. Ezi ziya kutholakala kumthengi ngesicelo.</p>
  <p>10.18 Akukho lilungu leqela laseMeerkat eliphethe ngaphezulu kwe-10% yezabelo ezikhutshwe yiphina umniki-mveliso.</p>
  <p>10.19 I-Meerkat ithole ngaphezu kwe-30% yikhomishini yonyaka odlulileyo kunye nomvuzo ovela kwi-Old Mutual Alternative Risk Transfer Limited.</p>
  <p>10.20 Kucelwa uqaphele ukuba ngokuhambelana nomthetho sigcina irejista yokuchazwa ngokutsha. Le rejista ikukwazisa, umxhasi wethu, kuzo zonke iimfuno zemali kunye nobunikazi esinokuba nelungelo lokuzibandakanya kunye noluhlu lweentsebenziswano zoshishino endiba nazo nabathengi bemveliso. Olu xwebhu luqinisekisa ukucaca ekusebenzisaneni kwethu nabathengi bethu kwaye luyakwazi ukuhlolwa.</p>
  <p><strong>11 Ukuthengiselana ngekhompyutha, ukunxibelelana kunye neirekhodi</strong></p>
  <p>11.1 Uyamkela umngcipheko wokukhetha ukhetho loqhagamshelwano lwe-elektroniki njengoko isitya esisetyenziswe ngamaqela. Unoxanduva lokuqinisekisa ukuba i-Meerkat ifumene ulwazi oluthunyelwe ngekhompyutha. Ugunyazisa uMeerkat ukuba asebenze ngolwazi othumela ngalo ngekhompyutha. Ngaloo ndlela uyavuma ukuba zonke izivumelwano zecomputer, izaziso, ukuchazwa kunye nezinye iinkcukacha ezithunyelwa nguMeerkat ziyanelisa nayiphi na imfuneko yomthetho ukuba olo hlobo lwanxibelelwano lube lulotshiwe.</p>
  <p>11.2 Uyavuma kwaye uyavuma ukuba iMeerkat, ngokwengqiqo yayo, inokugcina kunye nokugcina uxhulumaniso lwakho lwekhompyutha njengoko lunokufunwa ngokomthetho. Uyavuma ukuba iirekhodi zekhompyutheni ezigcinwe yiMeerkat ziza kubonisa ubungqina obuqinileyo (kungenakuze kube ngumngeni) kumxholo weirekhodi.</p>
  <p>11.3 Nayiphi na i-imeyile, iSMS okanye isaziso sekhompyutheni esiyithunyelwe kuwe siya kuthathwa ukuba samukelwe nguwe xa usithunyelwa nguMeerkat.</p>
  <p>11.4 Sithatha onke amanyathelo alungileyo ukukhusela ulwazi lwakho lomntu kwaye silondoloze imfihlo, kuquka nokusetyenziswa kobugcisa bokubhala. Nangona kunjalo, asikwazi ukuqinisekisa ukuba ukhuseleko lwaluphi na ulwazi olusithumela kuyo kwi-intanethi kwaye wenze njalo kumngcipheko wakho. I-Meerkat ayiyi kuhlawulwa nayiphi na ilahleko okanye umonakalo onokubandezeleka ngenxa yokubambisana ngamaqela angagunyaziswanga kunye nayiphi na imisebenzi engekho mthethweni kunye engagunyaziswanga.</p>
  <p>11.5 Ngokomthetho woThishino loThishino noTshintsho. Nceda uqaphele oku kulandelayo:</p>
  <p>11.5.1 Igama elipheleleyo kunye nesimo sezomthetho: MyMeerkat (PTY) Ltd., inkampani yabucala efakwe ngokuhambelana nemithetho yeRiphabhlikhi yaseMzantsi Afrika;</p>
  <p>11.5.2 Inombolo yokubhalisa: 2015/102401/07;</p>
  <p>11.5.3 Idilesi yendawo: 203 I-Pine Park, 1 i-Logan Way, i-Pinelands, eKapa, ​​7450;</p>
  <p>11.5.4 Inombolo yocingo: (021) 003 4535;</p>
  <p>11.5.5 Idilesi yewebhusayithi: www.meerkat.co.za;</p>
  <p>11.5.6 Idilesi ye-imeyile: <a href="mailto:info@meerkat.co.za">info@meerkat.co.za</a>.</p>
  <p><strong>12 Iziqinisekiso zakho</strong></p>
  <p>12.1 Uyavuma ukuba ungubani (nguwe otshoyo ukuba ungubani) kwaye unokubonisa ubungqina bakho ukuba kufuneka sikwenze ukuba wenze njalo.</p>
  <p>12.2 Uyavuma ukuba yonke inkcazelo oyinikezelayo nayiphi na ixesha ku-Meerkat usebenzisa i-website ye-Meerkat, ngokubhaliweyo, okanye kwabasebenzi baseMeerkat, iya kuba zonke iimeko zikhoyo, zizalisekile kwaye zichanekile. Kungumnqweno wakho ukugcina ulwazi olufikeleleko kwaye luchanekile.</p>
  <p>12.3 I-Meerkat inelungelo lokunqaba ukunikezela iinkonzo kuwe, kwaye ukhansele zonke izivumelwano nawe, ukuba unganikezeli ngolwazi, okanye unikezele ngolwazi olungalunganga, okanye ukuba asikwazi ukuqinisekisa okanye ukuqinisekisile nayiphi na inkcazelo oyinikezelayo.</p>
  <p><strong>13 Ulwazi olungenakunikwa</strong></p>
  <p>Ngokuthumela nayiphi na ingcaciso engaceliweyo kunye nezinto eziphathekayo, kubandakanye izimvo, iingcamango, imibuzo, ukuyila kunye nezinye iinkcukacha ezifanayo (kunye kunye, "Ulwazi olungenakunikwa"), uyavuma ukuba ubophelelwe yimimiselo neemeko ezilandelayo. Ukuba awuvumelani nale migaqo neemeko, akufanele unikeze nayiphi na iNkcukacha engenziwanga kwiSayithi. Zonke iinkcukacha ezingenakunyanzelwa ziya kuthathwa njenge-UNON-CONFIDENTIAL NON-PROPRIETARY. Singasebenzisa olo nxibelelo okanye izinto eziphathekayo nayiphi na injongo, kubandakanywa, kodwa kungaphelelanga, ukuveliswa, ukudalulwa, ukuhanjiswa, ukupapashwa, ukusasazwa kunye nokuthunyelwa kwakhona. Ngaphezulu, sikhululekile ukusebenzisa nayiphi na imbono, iingcamango, ukwazi, okanye iindlela eziqulethwe naluphina unxibelelwano okanye izinto ozithumela kwisayithi nayiphi na injongo, kuquka, kodwa kungaphelelanga, ukuphuhlisa, ukuvelisa, kunye nemveliso yokuthengisa. Ngokuthumela nayiphi na iNgcaciso engaphendulwanga, usinika ilungelo elingenammiselo, elingenasigodlo kunye nelungelo elingenakuguqulwa kunye nelayisenisi yokusebenzisa, ukuvelisa, ukuguqula, ukulungelelanisa, ukushicilela, ukuguqulela, ukusabalalisa, ukuhambisa, ukubonisa esidlangalaleni, ukwenza uwonke-wonke, i-sublicense, ukudala imisebenzi evela , udlulise kwaye uthengise ulwazi olunjalo olungenakunyulwa kwaye usebenzise igama lakho kunye nolunye ulwazi oluchongekileyo malunga nolwazi olungenakunikwa.</p>
  <p><strong>14 Ulwaphulo</strong></p>
  <p>14.1 Ngokuxhomekeke kunoma nayiphi na imiqathango echazwe kule miqathango kunye nemimiselo, ukuba unokuphulaphula nawuphi na umqathango wesi siVumelwano, iMerkatta iya kuba nelungelo, ngaphandle kokunyanzelisa nayiphi na amalungelo enokuthi ibe nayo kunye nemeko efunekayo okanye evumelekileyo, njengoko kunjalo, ngomthetho, ngokukhawuleza:</p>
  <p>14.1.1 ukukunika ithuba elifanelekileyo lokulungisa ulwaphulo, ngokuqwalasela uhlobo lokuphulwa kombuzo; okanye</p>
  <p>14.1.2 umise ukufikelela kwakho kwiNkonzo; okanye</p>
  <p>14.1.3 ukhansele zonke izivumelwano ezigqityiweyo phakathi kwethu.</p>
  <p><strong>15 Inzuzo</strong></p>
  <p>15.1 Ngaloo ndlela ungekho mthethweni kwaye unganakanqwenela ukuhlawula i-Meerkat kwaye uyavuma ukuhlawulela kwaye ubambe i-Meerkat engenazo nxamnye nokulahlekelwa, ukulimala, amabango, iimbopheleleko kunye / okanye iindleko, nayiphi na imeko, nokuba yintoni na xa ivela, ihlupheke okanye iyenze yi-Meerkat ngenxa yesibango uqaliswe kuMeerkat ngumbutho wesithathu (ngaphandle kwakho) ngenxa ye (ngaphandle kwemida):</p>
  <p>15.1.1 ukusebenzisa kwakho iiNkonzo zethu ngaphandle kokuvunyelwa okanye kumiselwe kwisiVumelwano;</p>
  <p>15.1.2 nayiphi na isizathu esithile malunga nesiVumelwano okanye ukunikezelwa kweeNkonzo kuwe apho wenze ngokungalunganga okanye uhlulekile ukwenza okuthile xa unomsebenzi wokwenza njalo.</p>
  <p><strong>16 Akukho zimelo, iziqinisekiso okanye iziqinisekiso kunye nokunciphisa uxanduva</strong></p>
  <p>16.1 Gcina kwindlela enikezelwe ngayo kwesi siVumelwano okanye apho unelungelo lokuthembela okanye ukufumana, ngokusebenza komthetho, nayiphi na imimiselo, iziqinisekiso okanye iziqinisekiso, asenzi okanye sinikeze naziphi na izimvo, ukuqinisekiswa okanye iziqinisekiso malunga ukufumaneka, ukuchaneka, ukuthembeka, ixesha elifanelekileyo, umgangatho okanye ukhuseleko kwanoma yimuphi umveliso okanye inkonzo.</p>
  <p>Nangona sinyamekela ukunika ulwazi oluchanileyo kwiwebhusayithi, ulwazi oluboniswe kwiwebhusayithi lunikezelwa ngaphandle kwewaranti enokuvakalisa okanye echaziweyo (isiqinisekiso sokuba ulwazi luchanekileyo) nayiphi na uhlobo.</p>
  <p>16.3 Lonke ulwazi, kubandakanywa iimveliso kunye neenkonzo okanye nayiphi na imimiselo okanye imiqathango ephathelele kubo, iya kutshintshwa nanini na. Zonke izibalo ezenziwe kwiwebhusayithi kufuneka ziqinisekiswe ngaphambi kokuba zithembele kuzo. I-Meerkat ayinaso uxanduva malunga naziphi na iziphoso, ukungasebenzi, okanye ukungalungiswanga ekusebenzeni kwanoma yimuphi umzekelo osetyenziswayo kubalo.</p>
  <p>16.4 Le ngcebiso isekelwe kwidata enikezelwe nguwe kwaye ifunyenwe nguwe. Iimfuno zakho zingahluka kwizinto ezichazwe, kuxhomekeke kwiinkcukacha esingazibambanga (kubandakanywa, kodwa kungaphelelanga, iingeniso zomqeshi wakho, iinkonzo zakho zenkxaso-mali edibeneyo kunye nembali yakho yempilo).</p>
  <p>16.5 Olunye ulwazi lwethu luvela kwimithombo yangaphandle. Asixanduva lenkcazelo engafanelekanga efunyenwe kule mimandla yangaphandle. Nangona sisebenzisa imizamo efanelekileyo yokuqinisekisa ukuba icebiso lichanekile, akukho mboniso okanye iwaranti, ebonisa okanye echaziweyo, inikwa ngokubhekiselele ekuchanekeni kwayo okanye malunga nokuchaneka kwanoma yimuphi umxholo.</p>
  <p>16.6 Ngaphandle kokunciphisa ubuninzi beemimiselo zesigatshana 16.2, uMeerkat akayi kuba noxanduva kwaye awuyi kubakho ibango lento enxamnye neMeerkat ngenxa</p>
  <p>16.6.1 ukulahlekelwa okanye ukufikelela kuwo nawaphi na amagama abasebenzisi kunye namaphasiwedi ofunekayo ukukhusela kwaye ungavumeli ukufikelela okungagunyaziswanga ekuqondeni ukuba siya kuba nelungelo lokucinga ukuba ungumntu ngokusebenzisa okanye ukufumana nayiphi na inkonzo okanye iakhawunti apho Igama lakho lomsebenzisi kunye nephasiwedi isetyenziswa;</p>
  <p>16.6.2 nayiphi na ukungabikho, okanye ukuphazamiseka kwiNkonzo ngenxa yeNgqungquthela engalawulekiyo;</p>
  <p>16.6.3 nayiphi na umonakalo, ukulahleka, indleko okanye ibango enokuthi uhlupheke okanye uvela kuyo nayiphi na ukumiswa okanye ukupheliswa kweenkonzo / s nayiphi na isizathu esichazwe kwisiVumelwano.</p>
  <p>16.7 Ukongezelela kwaye ngaphandle kokubandlulula nayiphi na iminye imingcele yemfanelo enikezelwe kwisiVumelwano kwaye ngokugcwele ngokuvunyelwe ngumthetho osebenzayo, uMeerkat akayi kuba noxanduva kuwe nayiphi na umonakalo othe ngqo okanye uya kuhlawulwa omnye nangayiphi na into ekhethekileyo, engazange ichaneke, engabonakaliyo, eyimfuneko okanye isohlwayo esiza kuvela okanye ehambelana nesi siVumelwano, nokuba ingabangelwa ukunganakwa, ukuphulaphula okanye nayiphi na isizathu. Kwinqanaba lokuba inkundla okanye isigqeba esilungileyo okanye isigqeba esilungele ukuphikisa ingxabano okanye isigunyaziso ngokugqibeleleyo sinquma, kungakhathaliseki ukukhutshwa kwesiqendu, ukuba iMeerkat inecala kuwe nayiphi na umonakalo, uxanduva lukaMeerkat kuwe nawuphi na umonakalo oza kwenzeka kwiimali ezihlawulwa ngawe phantsi kwesi siVumelwano ngokuqwalaselwa kwinkonzo okanye umkhiqizo ngexesha lexesha elidlulileyo le-12 (elishumi elinesibini) elingaphambi kweenkonzo okanye umkhiqizo owanikezela uxanduva kumbuzo.</p>
  <p><strong>17 Ukunikezelwa kunye nokuBelwa</strong></p>
  <p>Awukwazi ukuthengisa, ukunikezela, ukunikezela, ukudlulisela okanye nayiphi na enye indlela eyahlukanisa okanye ukulahla nayiphi na okanye onke amalungelo akho kunye nezibophelelo ngaphantsi kwesi sivumelwano ngaphandle kwemvume ebhaliweyo yaseMeerkat. I-Meerkat iya kuba nelungelo lokuthengisa, ukunikwa, ukwabela, ukunikezela, ukwahlukanisa, ukulahla okanye ukudlulisela nayiphi na okanye onke amalungelo ayo kunye nezibophelelo phantsi kwayo kwaye ngokwemigaqo yesi siVumelwano kunoma yiyiphi na inxaxheba okanye kubani na umntu wesithathu ngaphandle kwemvume yakho kwaye ngaphandle kwesaziso unikezele ukuba awunakucaluli ngokungabalulekanga ngenxa yoko. "Abaxhamli" kule nkalo kubandakanya inkampani yokubamba i-Meerkat, inkampani ebambeleleyo (ies) ye-holder ye-Meerkat (kunye kunye "neenkampani zayo zokubambisa"), iinkampani zayo zenkxaso, iinkampani zenkampani zenkampani kunye nokunye na iinkampani ezilawulwa ngqo okanye ngokungathandwanga nguMeerkat okanye ziphantsi kolawulo oluqhelekileyo kunye ne-Meerkat.</p>
  <p><strong>18 Ubukhosi</strong></p>
  <p>Ngaloo mvume uvuma imvume yolawulo lweNkundla kaMantyi kwiRiphabhlikhi yaseMzantsi Afrika malunga naluphi na inkqubo enokuqaliswa nguMeerkat ovela kwesi siVumelwano, ukuba uMeerkat unelungelo, ngokufanelekileyo, ukuba aqalise inkqubo enjalo iNkundla ePhakamileyo yeNtshona Afrika kwaye, kwimeko enjalo, uyavuma ukulawula kweso senkundla. Ubukhosi beNkundla yeMangalo encinci ibandakanywa ngokukodwa, njengoko amaqela avunyelwe ukuba alandele inkqubo yokubambisana echazwe kwicandelwana 5 ngasentla.</p>
  <p><strong>19 Ukulungiswa kwesi sivumelwano</strong></p>
  <p>UMeerkat unelungelo lokuchibiyela esi sivumelwano ngezikhathi ezithile. Naluphi na uhlobo olutsha lweSivumelwano luya kuboniswa kwiWebhsayithi yethu kunye nomhla okuza kusebenza ngayo, ongeke ube ngaphantsi kweentsuku ezingama-30 (amashumi amathathu) emva komhla wokushicilelwa ngawo okokuqala. Kuyimfanelo yakho yokutyelela i-webhsayithi yethu rhoqo ukuze kuqinisekiswe ukuba kukho naziphi na izilungiso ezenziwe.</p>
  <p><strong>20 Jikelele</strong></p>
  <p>20.1 Amaqela avuma kwaye avuma ukuba esi siVumelwano sisenza sonke isivumelwano phakathi kwabo kunye nokuba akukho zivumelwano, iziqinisekiso, izigqibo okanye izibhengezo, nokuba ngababhaliweyo okanye ngokubhaliweyo, ngokubhekiselele kwimiba yesi siVumelwano esingabandakanywa kwesi siVumelwano kuya kuba ukubophelela kumaqela. Akukho tshintsho okanye ukongeza kwesi siVumelwano okanye ifomu yoFakelo liya kubophelela nayiphi na imibutho ngaphandle kokuba ibhaliwe ngokubhaliweyo kwaye isayinwe ngamaqela amabini.</p>
  <p>20.2 Meerkat ngokwemigaqo yecandelo lama-43 lomThetho we-ECT kufuneka ukuba wenze iinkcukacha zayo zokuqhagamshelana, i-domicilia citandi et executandi kunye nolunye ulwazi olufumanekayo kubhalisi balo abangena kwiinkcukacha ze-elektroniki kunye no-Meerkat. Olu lwazi lufumaneka kwiwebhusayithi yethu.</p>
  <p>20.3 Uyavuma ukuba nayiphi na izaziso esizithumela kuwe ngokwemvumelwano nayiphi na eyenziwe phakathi kwethu inokuthunyelwa nge-imeyile ngaphandle kokuba yenziwe ngomthetho.</p>
  <p>20.4 Akukho mfuneko, ukunyaniseka okanye ukwandiswa kwexesha uMeerkat angakunika okanye akubonise ngalo naluphi na uhlobo ukubandlulula iMeerkat okanye ukuvimbela iMerkat ekusebenziseni nayiphi na amalungelo ayo kwixesha elizayo.</p>
  <p>20.5 Uyavuma ukuba ngomhla wokusayinwa zonke iinkcukacha ezinikezelwe nguMeerkat ziyinyani kwaye zichanekile kwaye uza kuzisa iMeerkat xa kwenzeka nayiphi na inguqu kwiinkcukacha ezinjalo.</p>
  <p>20.6 Yonke imimiselo nemibandela yethu ingafumaneka, igcinwe, kwaye iphinda iphinde yenziwe ngekhompyutha yakho.</p>
  <p>20.7 Idilesi yendawo apho iMeerkat izakufumana inkonzo yomthetho yamaphepha / domicilium citandi et executandi yile ilandelayo:</p>
  <p>203 iPine Park<br />1 Logan Way<br />Pinelands<br />Kapa 7450</p>
  <p>Mzantsi Afrika</p>
  <p>Umhla wokuhlaziywa kokugqibela: 24 uJanuwari 2019</p>

`}

export default xhosa