const zulu = {
  __html: `<p>Amagatya kule Migomo Nemibandela ebaluleke kakhulu noma ephethe izinga
  lokubeka ingozi kuwe ngesibindi. Sicela uqaphele ngokukhethekile lezi
  zigaba bese uqinisekisa ukuthi uyaziqonda. Uma ungaqondi okuthile,
  sicela usithole ukuthi sikuchazele<br><br>
  Izincazelo ezingu-1<br>
  1.1 "Usuku Lokwamukelwa" lisho usuku owemukele ngalo Isivumelwano,
  ngendlela ye-medium medium, isibonelo ngokuchofoza "Ngiyavuma" ekhasini
  lewebhu noma ngefoni yakho noma ngezwi elirekhodiwe nge-Call Center
  yethu;<br>
  1.2 "Isivumelwane" kusho isivumelwano esiphethwe phakathi kwakho
  noMeerkat isivumelwano esiyobe sibhekiswe kuphela ngale migomo
  nemibandela kanye nenqubomgomo yobumfihlo nemigomo yokuthumela
  okuqukethwe;<br>
  1.3 "Usuku lwebhizinisi" lisho uMsombuluko kuya kuLwesihlanu, kodwa
  alubandakanyi iMgqibelo kanye nosuku oluyiholide olusemthethweni
  eRiphabhliki yaseNingizimu Afrika;<br>
  1.4 "Amahora Omabhizinisi" kusho amahora phakathi kuka-08h00 no-17h00 ngosuku lwebhizinisi;<br>
  1.5 "Umthetho we-ECT" kusho uMthetho wezokuThintana nokuThengiswa kwe-Electronic, 2002;<br>
  1.6 "Meerkat", "MyMeerkat" "thina", "us" kanye "yethu" kusho i-MyMeerkat (Proprietary) Limited;<br>
  1.7 "I-Meerkat Marks" isho noma yiziphi izimpawu zokuhweba, ama-logos,
  izinhlamvu, amagama amabizo, amagama okuhweba, amagama esizinda noma
  amanye amagama noma amamaki kaMeerkat noma abhalisiwe noma cha;<br>
  1.8 "Amalungelo Okuphathwa Kwezimpahla" kusho ilungelo lobunikazi kunoma
   yimuphi umsebenzi ngokwemigomo ye-Copyright Act, No. 98 ka-1978, futhi
  kuhlanganisa ngaphandle kokunciphisa ilungelo lokukhiqiza lowo msebenzi,
   amalungelo ngokuphathelene nomsebenzi owenziwe nguMthetho Wamaketha
  Wezohwebo , No. 194 ka 1993, amalungelo ngokuphathelene nomklamo
  ohlinzekwe yi-Designs Act, No. 195 ka-1993, kanye namalungelo maqondana
  ne-patent ehlinzekwe nguMthetho Wezentela, No. 57 ka-1978 kufaka
  phakathi noma yiziphi izicelo okungaphambilini nanoma yimaphi amagama,
  amalayisense, ukwazi ukuthi, izimfihlo zokuhweba kanye nedatha ehlobene
  nalokhu okushiwo;<br>
  1.9 "Umuntu oMthethweni" kusho inkampani noma i-close corporation futhi
  ihlanganisa inkampani, ubambiswano, inhlangano noma ukuthembana;<br>
  1.10 "Izaziso Zezomthetho Iwebhusayithi" kusho http://www.meerkat.co.za/imithombo;<br>
  1.11 "NCA" kusho uMthetho kaZwelonke weziKweletu ka 2005;<br>
  1.12 "Umhlinzeki Wenkonzo YesiThathu" kusho umuntu wesithathu ohlinzeka ngezinsizakalo kubhalisile nge-website ye-Meerkat;<br>
  1.13 "Isevisi" kusho iseluleko sezezimali kanye nezinsizakalo ezinikezwe yiMeerkat;<br>
  1.14 "Umbhalisi", "wena" noma "iKhasimende" kusho umsebenzisi weyiphi imikhiqizo noma amasevisi ethu;<br>
  1.15 "Indawo engalawuleki" kusho (kufaka ngaphandle kwemingcele) noma
  yikuphi umlilo, izikhukhula, ukuzamazama komhlaba, izici zemvelo noma
  izenzo zikaNkulunkulu, izihlukumezi, ukuphazamiseka komphakathi,
  ukuhlubuka noma ukuvuselelwa kunoma yiliphi izwe noma yiziphi ezinye
  izizathu ezingaphezu kokulawulwa kahle kweMeerkat kuhlanganise nokuqedwa
   noma ukumiswa kwensizakalo noma umkhiqizo ohlinzekwa umuntu wesithathu,
   okungabangela ukubambezeleka noma ukwehluleka ukuhlinzeka noma yimuphi
  umkhiqizo noma isevisi; futhi<br>
  I-1.16 "i-VAT" kusho intela ye-Value Added njengoba kuhlinzekwe ku-Value Added Tax Act ka-1991.<br><br>
  1.17 Iwebhusayithi "kusho www.meerkat.co.za, www.mymeerkat.com, www.mymeerkat.co.uk, www.mymeerkat.co.za naku www.mkat.co:<br>
  2 Ukuqalwa, Ukuqeda, Ukuqeda kanye nokucima<br>
  2.1 Isivumelwano sizoqala ngosuku lwe-Acceptance futhi sikhuthazele kuze
   kube yilapho likhanselwa njengoba kuhlinzekwe kulesi Sivumelwano.<br>
  2.2 Lesi Sivumelwano sihlanganisa ngokubheka imigomo nemibandela
  yewebhusayithi nenqubomgomo yobumfihlo eyenziwe kuwe kule website
  nokuthi uzimisele ukufunda nokuqonda. Uma ungaqondi lutho, sicela
  usicele ukukuchazela.<br>
  I-2.3 Meerkat ingaqeda lesi Sivumelwano masinyane ngesaziso kuwe ngokuphulwa komthetho kwalesi Sivumelwano.<br>
  2.4 Naphezu kokuqedwa kwesivumelwano, uma kwenzeka uqhubeka ukusebenzisa
   imikhiqizo noma amasevisi naphezu kokuqedwa kwesivumelwano, uzohlala
  ubophekile futhi ukhokhe ngokushesha ngokufunwa zonke izimali ezizobe
  zibangelwa uMeerkat njengomphumela weSivumelwano. ukusetshenziswa noma
  ukufinyelela kumkhiqizo noma isevisi futhi lesi Sivumelwano sizobheka
  ukuthi sizoqhubeka nokufaka isicelo kuze kube yilapho leso sikhathi
  njengoba zonke izimali ezidingekayo ngenxa ye-Meerkat zikhokhwe
  ngokugcwele, futhi siyoba nelungelo lokuqeda isivumelwano nganoma
  yisiphi isikhathi.<br>
  2.5 Uma lesi sivumelwano sivela kunoma yikuphi ukuqondana okuqondile
  kuwe nge-Meerkat noma ukuthengiswa kwe-elektroniki njengoba kuhlongozwe
  kuMthetho we-ECT, uzoba nelungelo lokukhansela lesi Sivumelwano
  esibhalwe phansi ku-Meerkat ngaphandle kwesizathu noma inhlawulo
  ezinsukwini ezingu-5 (ezinhlanu) zeBhizinisi -<br>
  2.5.1 esimweni sezinsizakalo kuphela ezihlinzekwa ngokweSivumelwano - Usuku Lokwamukela; futhi<br>
  2.5.2 uma kwenzeka izimpahla zinikezwa futhi zakha isihloko
  seSivumelwano, kungakhathaliseki ukuthi sisebenzisana nezinsizakalo noma
   sodwa - usuku lokudilizwa kwezimpahla.<br>
  2.6 Ngokwezinhloso zesigatshana 2.5.2, izimpahla zihlanganisa noma
  yiziphi izincwadi, umculo, isithombe, isithombe sokunyakaza, umdlalo,
  ulwazi, isofthiwe yedatha, ikhodi, izibalo, amamodeli, izindlela
  zebhizinisi noma omunye umkhiqizo ongabonakali noma noma iyiphi
  ilayisense yokusebenzisa umkhiqizo ongenakwenzeka.<br>
  3 Imibandela yokufinyelela<br>
  3.1 Meerkat izokwenza isevisi itholakale kuwe ngosuku lokumukelwa.<br>
  3.2 I-Meerkat, lapho kufanele khona, ikhiphe igama lomsebenzisi
  nephasiwedi kuwe ngaphambi koSuku Lokwamukela ukuze sikwazi ukuthola
  ukufinyelela kanye / noma ukusebenzisa isevisi. Uma kwenzeka, ngeke
  ukwazi ukufinyelela futhi / noma ukusebenzisa isevisi ngaphandle kwegama
   lomsebenzisi nephasiwedi.<br>
  3.3 Uyavuma ukuthi:<br>
  3.3.1 uzothatha igama lakho lomsebenzisi nephasiwedi ngokusebenzisa kwakho siqu kuphela;<br>
  3.3.2 ngeke ukwazi ukudalula igama lakho lomsebenzisi nephasiwedi kunoma
   yimuphi omunye umuntu nganoma yisiphi isizathu nokuthi uzogcina imfihlo
   yayo;<br>
  3.3.3 uma kwenzeka iphasiwedi yakho isonakele, uzokwazisa ngokushesha i-Meerkat futhi ushintshe iphasiwedi yakho;<br>
  3.3.4 wena, njengomnikazi wegama lomsebenzisi nephasiwedi, uyavuma
  ukuthi unomthwalo wemfanelo kuphela kukho konke ukukhokha mayelana
  nensizakalo ekhokhiswa ku-akhawunti yakho ye-Meerkat, kungakhathaliseki
  ukuthi isevisi isetshenzisiwe noma isetshenziswa wena noma cha futhi
  ngokufanele yonke imali evelele kwi-akhawunti yakho ye-Meerkat izobheka
  ukuthi ivele evela (noma iqondise) ukufinyelela kwakho futhi / noma
  ukusetshenziswa kwensizakalo;<br>
  ukugunyazwa kwakho ukuhambisana nesivumelwano. Zonke izenzo noma
  ukushiya bonke abantu abasebenzisa izinsizakalo ngaphansi kwe-akhawunti
  yakho noma ngokugunyazwa kwakho bazophathwa ngazo zonke izinhloso
  njengoba izenzo zakho noma ukushiya;<br>
  3.3.6 I-Meerkat iphinde inikeze isevisi yokukhumbuza iphasiwedi.
  Sizothumela iphasiwedi yakho ekhelini lakho le-imeyli elibhalisiwe noma
  inombolo yeselula uma ngabe ukhohliwe iphasiwedi yakho. Ukubhaliselwa
  kule nsizakalo ngokuzithandela. Izikhumbuzi zephasiwedi zizothunyelwa
  kuphela emininingwaneni yakho ebhalisiwe futhi uma imininingwane enjalo
  ingashintsha kuwumthwalo wakho wokwazisa.<br>
  3.3.7 ngeke, noma nini, uvumele futhi / noma uqale ukungena ngemvume kwenethiwekhi kanyekanye; futhi<br>
  3.3.8 ngeke uzame ukuvimbela izinqubo zokuqinisekiswa komsebenzisi
  wakwaMeerkat noma uhlanganyele emizamweni yokufinyelela kwinethiwekhi
  ye-Meerkat lapho kungenalo igunya elicacile lokwenza kanjalo.<br>
  4 Ukulethwa Kwesevisi, Ukutholakala Kwemisebenzi<br>
  4.1 Meerkat izosebenzisa izinyathelo ezifanele ukwenza izinsizakalo zayo
   zitholakale kubhalisile bayo, nokugcina ukutholakala kwayo ukuze
  kusetshenziswe ngabhalisile bayo. Kodwa-ke, sinikela ngezinsizakalo
  "njengenjengoba" futhi "njengezitholakalayo" futhi azivumelisi noma
  siqinisekisa ukuthi izinkonzo zizobe zikhululekile ngeziphambeko noma
  iziphazamiso ngezikhathi zonke, zihlale zikhona, zifanelwe nganoma
  iyiphi injongo, zingaphuli amalungelo , zivikeleke futhi zithembeke.<br>
  4.2 I-Meerkat izosebenzisa imizamo yayo engcono yokukwazisa
  kusengaphambili kwanoma yisiphi isondlo kanye nokulungiswa okungabangela
   ukungabikho kwenkonzo, kodwa akukwazi ukuqinisekisa njalo lokhu.
  Ngaphezu kwalokho, asikwazi ukuqinisekisa ukuthi amasevisi azosebenza
  kahle kuwo wonke amadivaysi eselula noma ukuthi azohlale etholakala
  ngenxa yezinkinga zokuxhumana kwenethiwekhi.<br>
  4.3 Sicela uqaphele ukuthi singumhlinzeki wezezimali ezigunyaziwe
  eRiphabhliki yaseNingizimu Afrika. Imikhiqizo namasevisi wethu
  azenzelwe, futhi angeke afaneleke kwenye izwe. Sincoma ukuthi
  uqinisekise ukuthi ukusetshenziswa kwamasevisi akuphuli imithetho yanoma
   yiliphi izwe ongabuswa ngalo. Ukusebenzisa kwakho iwebhusayithi yethu
  nganoma iyiphi injongo kuyingozi yakho siqu.<br>
  5 UkuCubungula Nezingxabano Zengxabano<br>
  5.1 Uyavuma ukuthi i-Meerkat ingase ikuthumele ngezikhathi ezithile
  ukuxhumana ngokuphathelene (ngaphandle kokukhawulelwa) okunikezwayo
  okukhethekile noma izaphulelo okuyinto i-Meerkat engase ixoxe ngayo
  futhi inikeze kubhalisile bayo, izinguquko zokusebenza ezingathinta
  izinsizakalo kanye / noma amasevisi amasha noma imikhiqizo iqalisiwe .
  Zonke zokuxhumana zizohambisana nenqubomgomo yethu yobumfihlo kanye
  nomthetho osebenzayo. Uzohlala unelungelo lokusitshela ngokubhala ukuthi
   awufisi ukuthola noma ukuqhubeka nokuthola lezo zinkampani futhi uma
  ungumthengi njengoba kucatshangelwe ku-CPA, ukuvimbela ngokuqinile
  ukuthola lokho kuxhumana.<br>
  5.2 Noma yikuphi ukuphikisana phakathi kwalabo ababambe iqhaza
  kungathunyelwa ekubambisaneni futhi ekugcineni kuxazululwe
  ngokuhambisana nemithetho yesiShayamthetho se-Arbitration of Southern
  Africa. Ukubambisana okunjalo kuzobanjelwa eKapa, futhi kwenziwe ngolimi
   lwesiNgisi ngaphambi kokuba ummeli owodwa aqokwe ngokuhambisana nale
  mithetho. Noma yimuphi umklomelo uzoba ngowokugcina futhi ungahambisani
  nokukhala. Lesi sivumelwano sokubambisana sizophoqelelwa, futhi
  isahlulelo kunoma yikuphi ukuklonyelwa singangeniswa kunoma yiliphi
  inkantolo yanoma yiliphi izwe elinamandla afanelekayo. Impikiswano
  izobheka ukuthi ivele lapho iqembu likwazisa omunye umuntu ngokubhala
  kulowo mphumela.<br>
  5.3 Ummangaleli uzoba negunya lokunikeza isahlulelo esizenzakalelayo uma
   noma yiliphi iqembu lihluleka ukwenza ukuthunyelwa ngosuku olufanele
  futhi / noma ehluleka ukuvela ekuhambisaneni.<br>
  5.4 Izinhlinzeko ezibalulwe ngenhla ngeke zivimbele noma yiliphi iqembu
  ukuba lifinyelele kunoma yiliphi inkantolo yamandla afanelekayo ukuthola
   isikhathi esithile noma olunye usizo lapho kwenzeka ukuphuthuma.<br>
  6 Ukukhokha<br>
  6.1 I-Meerkat izothola umholo ka-3.25% (amaphuzu amathathu amabili
  namaphesenti ayisihlanu) kwanoma iyiphi i-premium yenyanga ekhokhwa
  nguwe ku-OMART ngokushintshanisa ngezinsizakalo ezinikeziwe.<br>
  7 Ubumfihlo kanye nokuhlolwa kwezikweletu<br>
  7.1 I-Meerkat izobhekana nolwazi lwakho lomuntu ngokuhambisana
  nezinhlinzeko ze-Inqubomgomo Yobumfihlo etholakalayo
  http://www.meerkat.co.za/mithombo yethu futhi ngokuhambisana nayo yonke
  imithetho efanele.<br>
  7.2 Isicelo sakho somsebenzi noma umkhiqizo singase sibe ngaphansi
  kwenqubo yokuhlola isikweletu noma inqubo yokuhlola ingozi. Lokhu kusho
  ukuthi i-Meerkat ingase icele futhi ithole imininingwane yakho
  Yobumfihlo, Ulwazi Lwesikweletu sabathengi kanye nolwazi olunqunyelwe
  (njengoba kuchazwe ku-NCA) ("Ulwazi Lokuhlola") kusuka kumahhovisi
  okuthenga ngesikweletu abhalisiwe ukuze kwenziwe ukuhlolwa kwezindlela
  zezimali.<br>
  7.3 I-Meerkat inelungelo lokwenza lokhu kuhlolwa kwezindlela zezimali
  njalo uma ufaka isicelo senkonzo noma umkhiqizo noma ngasiphi isikhathi
  ngesikhathi kukhona noma iyiphi insizakalo nathi noma ukuhlola
  ukufaneleka kwakho kwesikweletu ngokuhamba kwesikhathi.<br>
  7.4 Mayelana nalokhu uvumela iMeerkat ecela, yokwamukela nokubika
  imininingwane yakho yokuhlola evela kumabhayisini esikweletu abhalisiwe
  ngokuhambisana nezinhlinzeko kanye nezinhloso ze-NCA; kanye nokwabelana
  ngolwazi olunjalo ngamabhredithi esikweletu abhalisiwe kanye nabanye
  abantu njengoba kuhlongozwe kwi-NCA, ngezinhloso ezinqunyiwe ze-NCA.<br>
  7.5 Uma ngabe isicelo sakho singumphumela wokudluliselwa komeluleki
  wezezikweletu, uyavuma ukuthi uMeerkat ucele, athole futhi abike ulwazi
  lwakho lokuhlola kusuka kumabeluleki abesikweletu abhalisiwe
  ngokuhambisana neMgomo Wobumfihlo.<br>
  8 Amalungelo Okungena Kwempahla<br>
  8.1 Uyavuma ukuhambisana nayo yonke imithetho esebenzayo kunoma yiliphi
  Amalungelo Amalungelo Okuziphendulela ngokuqondene nanoma iyiphi idatha,
   amafayela kanye / noma ulwazi olufinyelelekile, olutholiwe noma
  olugcinwe nguwe ngokusebenzisa kwakho noma iyiphi yamasevisi ethu kanye
  nemikhiqizo.<br>
  8.2 Ungavunyelwe ukusebenzisa noma yimaphi ama-Meerkat Marks ngaphandle kwemvume ebhaliwe yaseMeerkat.<br>
  9 Ukuqokwa kwabaSebenzi<br>
  9.1 Ngiqinisekisa ukubandakanya kwaMyMeerkat (Pty) Ltd, nabamele bayo, abagunyaziwe ngokufanele, njengeMeluleki wami wezeMali.<br>
  9.2 Ngiyaqonda futhi ngiyavuma:<br>
  9.2.1 ukuthi iseluleko esitholiwe singathola i-MyMeerkat (Pty) Ltd
  kunoma yiziphi izimali zokubuyekeza kanye / noma amakhomishana
  angakhokhwa ngaphansi kwezimali zami ezahlukene kanye / noma
  izinqubomgomo.<br>
  9.3 ukuthi ukuqokwa okunjalo kuzobekwa kuphela:<br>
  9.3.1 Izigaba zemikhiqizo lapho i-MyMeerkat (Pty) Ltd ilayisensi ngeBhodi Yezinkonzo Zezezimali, futhi<br>
  9.3.2 Izinkampani eziye zakwaMyMeerkat (Pty) Ltd ziphelile izivumelwano zesikhashana.<br>
  I-FAIS Ukudalulwa<br>
  10.1 Igama lebhizinisi yi-MyMeerkat (Pty) Ltd.<br>
  10.2 Inombolo yokubhalisa yenkampani: 2015/102401/07.<br>
  10.3 I-MyMeerkat (Pty) Ltd ngumhlinzeki wezinsizakalo zezezimali
  ogunyaziwe, onikezwe ilayisensi yi-FSB (iBhodi Yezinkonzo Zezezimali)
  ngaphansi kwezigaba ezilandelayo<br>
   1.1 Umshuwalense Wesikhathi Eside: Isigaba A<br>
   1.2 Umshuwalense Wesikhathi Esifishane: Imigqa Yomuntu siqu<br>
   1.3 Umshuwalense Wesikhathi Eside: Isigaba B<br>
   Umshuwalense Wesikhathi Eside: Isigaba C<br>
   1.5 Izinzuzo zempesheni yokudayisa<br>
   Umshuwalense Wesikhashana Esikhathini Esifishane: Izitayela Zokuhweba<br>
   1.7 Izinzuzo zeSikhwama sePensheni (ngaphandle kweziNzuzo zokuThola iziPhuzo)<br>
   1.14 Izintshisekelo ezibandakanyekayo kwiSikimu esisodwa noma ngaphezulu Sokutshalwa Kwezimali<br>
   1.17 Amaposenti njengoba kuchazwe eMthethweni weBhange - ngaphezulu kwezinyanga ezingu-12<br>
   1.18 Amaposenti njengoba kuchaziwe eMthethweni weBhange - izinyanga ezingu-12 noma ngaphansi<br>
  10.4 Inombolo ye-FSP: 46535.<br>
  10.5 Ikheli Elibhalisiwe: 203 Pine Park, 1 Logan Way, Pinelands, Cape Town, 7450.<br>
  10.6 Ikheli lePhysical and Postal: 203 iPine Park, 1 Logan Way, Pinelands, eKapa, 7450.<br>
  10.7 Iwebhusayithi: https://www.meerkat.co.za<br>
  10.8 Ucingo. +27 (021) 003 4535.<br>
  10.9 Uma ungenelisekile nganoma yiziphi izici zenqubomgomo yakho noma
  izinsizakalo ezinikezwe i-Meerkat egameni le-OMART, sicela uxhumane
  nathi ku-: complaints@meerkat.co.za<br>
  10.10 I-Meerkat Compliance Officer i-Masthead (Pty) Ltd, Ifoni 021 686
  3588. Isikhulu Sokuthobela Imithetho sibhekene nezinkinga ezihambisana
  nokuhambisana noMeerkat noMthetho Wezezimali Nezinsizakalo Zomphakathi,
  ka-37 ka-2002 (uMthetho we-FAIS).<br>
  10.11 Uma unomuzwa wokuthi uMeerkat uphule umthetho we-FAIS Act, futhi /
   noma uhlinzekele ngezinsizakalo zezimali ngendlela engakuthinta kabi,
  sicela uxhumane nathi. Ngenye indlela, ungaxhumana ne-FAIS Ombudsman:
  012 470 9080 / info@faisombud.co.za / www.faisombud.co.za.<br>
  10.12 Uma unesikhalazo esihlobene nenkontileka yomshuwalense,
  ungaxhumana no-Ombudsman for Long-term Insurance. 021 657 5000 /
  info@ombud.co.za / www.ombud.co.za. I-Ombudsman itholakala ngezeluleko
  mayelana nezikhalazo maqondana nezicelo noma nanoma yiluphi olunye udaba
   olungaxazululwanga ngempumelelo nguMeerkat.<br>
  Umthengisi womkhiqizo we-10.13: I-Old Mutual Alternative Transfer
  Limited, i-insurer ebhalisiwe yesikhathi eside kanye noMbhalisi we-Old
  Mutual Group.<br>
  10.14 Ababonisi bethu abangahlangabezane nezidingo ezigcwele ze-Fit
  neZimfanelo njengoba kuchaziwe yi-FAIS ukuhlinzeka ngezinsizakalo
  ngaphansi kokuphathwa kokuphatha.<br>
  10.15 I-Meerkat ine-inshuwalense yokukhokhela inzuzo.<br>
  10.16 I-Meerkat inayo iphinde igcine inqubomgomo ye-Anti-Money Laundering ngokuhambisana noMthetho we-FAIS.<br>
  10.17 I-Meerkat igcina ukuqoshwa kwazo zonke izingxoxo ezibandakanya izingxoxo zocingo. Lezi zizotholakala iklayenti ngesicelo.<br>
  10.18 Akekho ilungu leqembu leMeerkat elithatha amasheya angaphezulu
  kwezingu-10 ezikhishwe yinoma yimuphi umhlinzeki womkhiqizo.<br>
  10.19 U-Meerkat wathola okungaphezu kuka-30% wekhomishini yonyaka odlule
   kanye nomholo kusuka ku-Old Mutual Alternative Risfer Transfer Limited.<br>
  10.20 Sicela wazi ukuthi ngokuhambisana nomthetho sigcina irejista
  yokudalula okubuyekeziwe. Le rejista ikukwazisa, iklayenti lethu, kuzo
  zonke izintshisekelo zezimali nezomnikazi ukuthi singaba nelungelo
  lokubhala futhi sibhale ubudlelwane bebhizinisi enginabo nabahlinzeki
  bemikhiqizo. Le dokhumenti iqinisekisa ukubonakala ngokucacile
  ekusebenzelaneni kwethu namakhasimende ethu futhi iyatholakala ukuze
  ihlolwe.<br>
  11 Ukuthengiswa kwe-elekthronikhi, ukuxhumana kanye namarekhodi<br>
  11.1 Uyamukela ingozi yokukhetha ukukhulumisana nge-elektroniki
  njengesiteshi okufanele sisetshenziswe yizinhlangothi. Unesibopho
  sokuqinisekisa ukuthi i-Meerkat ithola ulwazi oluthunyelwe
  nge-elekthronikhi. Ugunyaza i-Meerkat ukuthi isebenze ngolwazi
  oluthumela nge-elekthronikhi. Ngakho-ke uyavuma ukuthi zonke
  izivumelwano zika-elektroniki, izaziso, ukudalulwa, nokunye ukuxhumana
  okuthunyelwe nguMeerkat t kwanelisa noma yimiphi imfuneko esemthethweni
  ukuthi lezo zinkulumo kufanele zibhaliswe.<br>
  11.2 Uyavuma futhi uvuma ukuthi i-Meerkat, ngokuqonda kwayo, ingagcina
  futhi igcine ukuxhumana kwakho kwe-elektroniki njengoba kungadingeka
  ngokomthetho. Uyavuma ukuthi amarekhodi e-elektroniki agcinwe yi-Meerkat
   azoba ubufakazi obunqunu (angeke kube inselele) kokuqukethwe
  kwamarekhodi.<br>
  11.3 Noma yiluphi u-e-mail, i-SMS noma isaziso se-elekthronikhi
  esizothunyelwa kuwe kuyothathwa ukuthi yamukelwe nguwe lapho ithunyelwa
  yi-Meerkat.<br>
  11.4 Sithatha zonke izinyathelo ezifanele ukuvikela ulwazi lwakho
  lomuntu siqu nokugcina imfihlo, kuhlanganise nokusetshenziswa
  kobuchwepheshe bokubethela. Kodwa-ke, asikwazi ukuqinisekisa ukuthi
  ukuphepha kwanoma yiluphi ulwazi olusithumelela kuwe ku-intanethi futhi
  uyakwenza ngokwakho ubungozi. I-Meerkat ngeke ikwazi ukulahlekelwa noma
  yikuphi ukulahleka noma umonakalo ongase uhlupheke ngenxa yokubambisana
  ngamaqembu angagunyaziwe kanye neminye imisebenzi engekho emthethweni
  futhi engagunyaziwe.<br>
  11.5 Ngokomthetho wezokuThintana nokuThengiswa kwe-Electronic. Sicela uqaphele okulandelayo:<br>
  11.5.1 Igama eligcwele nesimo sezomthetho: MyMeerkat (PTY) Ltd,
  inkampani yangasese efakwe ngokuhambisana nemithetho yeRiphabhliki
  yaseNingizimu Afrika;<br>
  11.5.2 Inombolo yokubhalisa: 2015/102401/07;<br>
  11.5.3 Ikheli lendawo: 203 I-Pine Park, 1 i-Logan Way, i-Pinelands, eKapa, 7450;<br>
  11.5.4 Inombolo yocingo: (021) 003 4535;<br>
  11.5.5 Ikheli lewebhusayithi: www.meerkat.co.za;<br>
  11.5.6 Ikheli le-imeyili: info@meerkat.co.za.<br>
  12 Iziqinisekiso zakho<br>
  12.1 Uqinisekisa ukuthi ungubani (ukuthi ungubani wena othi ungubani)
  nokuthi ungafakazela ukuthi ungubani uma kufanele sikwenze ukuthi wenze
  kanjalo.<br>
  12.2 Uyavuma ukuthi yonke imininingwane oyinikezela nganoma isiphi
  isikhathi ku-Meerkat usebenzisa iwebhusayithi ye-Meerkat, ngokubhala,
  noma kubasebenzi baseMeerkat, kuzo zonke izimo ibe khona, iphelele,
  futhi inembile. Kuyinto enhle kakhulu ukugcina ulwazi olusesikhathini
  futhi olunembile.<br>
  I-12.3 Meerkat inelungelo lokunqaba ukuhlinzeka ngezinsizakalo kuwe,
  futhi ukhansele noma yiziphi izivumelwano nawe, uma ungahlinzeki
  ngolwazi, noma unikeze ulwazi olungalungile, noma uma singakwazi
  ukuqinisekisa noma ukuqinisekisa noma yiluphi ulwazi olunikeza lona.<br>
  13 Ulwazi olungakaxhunyiwe<br>
  Ngokuthumela noma yiluphi ulwazi olungacelwayo kanye nezinto zokwakha,
  kufaka phakathi ukuphawula, imibono, imibuzo, imiklamo, kanye nokunye
  okuxhumana okufanayo (ngokubambisana, "Ulwazi olungacelanga"), uyavuma
  ukuthi uboshiwe yimigomo nemibandela elandelayo. Uma ungavumelani nale
  migomo nemibandela, akufanele unikeze noma yiluphi ulwazi olungavunyelwe
   kulesi Sayithi. Lonke Ulwazi olungavunyelwe luzocatshangwa ukuthi
  LUNGAKHO OKUFANELEKILE futhi LUNGAKHO-PROPRIETARY. Singasebenzisa
  ukuxhumana okunjalo noma impahla nganoma iyiphi inhloso, kuhlanganise,
  kodwa kungapheli, ukukhiqiza, ukudalula, ukudluliselwa, ukushicilelwa,
  ukusakaza nokuthunyelwa okuqhubekayo. Ngaphezu kwalokho, sikhululekile
  ukusebenzisa noma yimiphi imibono, imiqondo, ukwazi, noma amasu aqukethe
   kunoma yikuphi ukuxhumana noma impahla oyithumela kusayithi nganoma
  iyiphi inhloso, kuhlanganise, kodwa hhayi ekukhawuleni, ekuthuthukiseni,
   ekukhiqizeni nasekuthengiseni imikhiqizo. Ngokuthumela noma yiluphi
  ulwazi olungavunyelwe, usinika ilungelo elingenamkhawulo,
  elingenamakhosi futhi elingenakuguqulwa kanye nelayisense yokusebenzisa,
   ukukhiqiza, ukuguqula, ukuvumelanisa, ukushicilela, ukuhumusha,
  ukusabalalisa, ukuthumela, ukubonisa esidlangalaleni, ukufaka
  esidlangalaleni, i-sublicense, ukudala imisebenzi evela , ukudlulisa
  nokuthengisa ulwazi olunjalo olungavunyelwe futhi ukusebenzisa igama
  lakho nolunye ulwazi lokukhomba mayelana nolwazi olunjalo
  olungavunyelwe.<br>
  14 Ukwephula<br>
  14.1 Kuncike kunoma yiziphi ezinye izinhlinzeko ezibekwe kule migomo
  nemibandela, uma ngabe uphula umthetho noma yikuphi ukuhlinzekwa kwalesi
   Sivumelwano, iMeerkat iyoba nelungelo, ngaphandle kokubandlulula noma
  yiliphi elinye amalungelo okungenzeka libe nalo futhi livunyelwe noma
  livunyelwe, njengoba kungenzeka, ngokomthetho, ngokushesha:<br>
  14.1.1 ukukukhokhela ithuba elihle lokulungisa ukuhlukumeza, ngokucabangela uhlobo lokuphulwa okukhulunywa ngalo; noma<br>
  14.1.2 ukumiselela ukufinyelela kwakho ku-Service; noma<br>
  14.1.3 ukukhansela zonke izivumelwano ezenziwe phakathi kwethu.<br>
  15 Isibonelelo<br>
  15.1 Wena ngalokhu ungavumelekile futhi ungavumelani neze noMeerkat
  futhi uyavuma ukubuyisela futhi ubambe i-Meerkat engenalutho ngokumelene
   nakho konke ukulahleka, umonakalo, izimangalo, izibopho kanye / noma
  izindleko, kwanoma yiluphi uhlobo, noma nini lapho luvela khona,
  luhlupheka noma lwenziwa yi-Meerkat ngenxa yoma yisiphi isimangalo
  isungulwe ngokumelene ne-Meerkat yinkampani yesithathu (ngaphandle
  kwakho) ngenxa yalokho (ngaphandle kwemingcele):<br>
  15.1.1 ukusetshenziswa kwakho kweMisebenzi yethu ngaphandle kokuvunyelwa noma okubekiwe kuSivumelwano;<br>
  15.1.2 noma yisiphi esinye isizathu esibangela isivumelwano noma
  ukuhlinzekwa kwezinsizakalo kuwe lapho usenze ngendlela engalungile noma
   uhlulekile ukwenza okuthile uma unomsebenzi wokwenza kanjalo.<br>
  16 Azikho izethulo, iziqinisekiso noma iziqinisekiso kanye nokulinganiselwa kwemfanelo<br>
  16.1 Londoloza ngokwezinga elinikeziwe kulesi Sivumelwano noma lapho
  unelungelo lokuthembela noma ukuthola, ngokusebenza komthetho, noma
  yikuphi ukumelela, iziqinisekiso noma iziqinisekiso, asenzi noma
  sinikeze noma yikuphi ukumelela okuvelayo noma okushiwo, iziqinisekiso
  noma iziqinisekiso mayelana ukutholakala, ukunemba, ukwethenjelwa,
  isikhathi, izinga noma ukuphepha kwanoma imuphi umkhiqizo noma isevisi.<br>
  16.2 Yize sizoqaphela ukunikeza ulwazi olunembile kuwebhusayithi, ulwazi
   oluboniswe kwiwebhusayithi luhlinzekwe ngaphandle kwanoma iyiphi
  iwaranti enikeziwe noma ecacisiwe (isiqiniseko sokuthi ulwazi lulungile)
   nganoma yiluphi uhlobo.<br>
  16.3 Yonke imininingwane, kuhlanganise imikhiqizo namasevisi noma
  yimiphi imigomo noma izimo eziphathelene nabo, ingashintsha nganoma
  isiphi isikhathi. Zonke izibalo ezenziwe kwiwebhusayithi kufanele
  ziqinisekiswe ngaphambi kokuthembela kuzo. I-Meerkat ayinabo obhekene
  nanoma yikuphi ukukhubazeka, ukungasebenzi, noma ukungalungile
  ekusebenzeni kwanoma yimaphi amamodeli asetshenziswa ekubaleni.<br>
  16.4 Iseluleko sisekelwe emininingweni enikezwe nguwe futhi samukelwe
  nguwe. Izidingo zakho zingase zihluke kulokho okuchazwe, kuye
  ngemininingwane esingazange siyibambe (kufaka, kodwa ingagcini, izinzuzo
   zomqashi wakho, imikhiqizo yakho yokuhlinzeka ngemisebenzi yokutshala
  izimali kanye nomlando wakho wezempilo).<br>
  16.5 Olunye ulwazi lwethu luvela emithonjeni yangaphandle. Asinawo
  obhekene nokwaziswa okungalungile okutholiwe kusuka kule mithombo
  yangaphandle. Nakuba sisebenzisa imizamo efanele ukuqinisekisa ukuthi
  iseluleko sinembile, akukho ukumelela noma iwaranti, eveza noma echazwe,
   inikezwa ngokuqondene nokuchaneka kwabo noma ngokuqondene nokuchithwa
  kwanoma yikuphi okuqukethwe.<br>
  16.6 Ngaphandle kokunciphisa ukujwayelekile kwezihlinzeko zesigatshana
  16.2, i-Meerkat ngeke ibe nesibopho futhi ngeke ube nesimangalo salo
  nhlobo ngokumelene neMeerkat ngenxa<br>
  16.6.1 ukulahlekelwa noma ukufinyeleleka kunoma yiliphi amagama
  abasebenzisi namaphasiwedi okudingeka ukuba ulondoloze futhi ungavumeli
  ukufinyelela okungagunyaziwe ekuqondeni ukuthi siyoba nelungelo
  lokucabanga ukuthi ungumuntu ngokusebenzisa noma ukuthola ukufinyelela
  kunoma iyiphi isevisi noma i-akhawunti lapho Igama lakho lomsebenzisi
  nephasiwedi lisetshenzisiwe;<br>
  16.6.2 noma yikuphi ukungahambi, noma ukuphazanyiswa eNkonzweni ngenxa yoMcimbi ongalawulwayo;<br>
  16.6.3 noma yimuphi umonakalo, ukulahlekelwa, izindleko noma isicelo
  ongase usihlupheke noma esivela kunoma yikuphi ukumiswa noma ukuqedwa
  kwenkonzo / s nganoma yisiphi isizathu esihlongozwe kuSivumelwano.<br>
  16.7 Ngaphezu kwalokho ngaphandle kokubandlulula nanoma yikuphi
  ukulinganiselwa kwecala elihlinzekwe kulesi Sivumelwano futhi
  ngokugcwele ngokuvunyelwe ngumthetho osebenzayo, uMeerkat ngeke abe
  nesibopho kuwe nganoma yikuphi ukulimala okuqondile noma yikuphi
  okuvelayo futhi akukho muntu oyobekwa icala noma yikuphi ukulimala
  okukhethekile, okungaqondile, okumele kwenzeke, okulandelanayo noma
  okujezisa okuvela noma okuhlobene nalesi Sivumelwano, kungakhathaliseki
  ukuthi kubangelwa ukunganakwa, ukwephula noma yimuphi omunye umphumela.
  Kuze kube yilapho inkantolo efanele noma inkantolo noma elinye
  inhlangano ekwazi ukuxazulula izingxabano noma igunya ligcina, naphezu
  kokukhipha okushiwo kulo mhlathi, ukuthi iMeerkat inecala kuwe nganoma
  yimuphi umonakalo, inkokhelo kaMeerkat kuwe nganoma yimuphi umonakalo
  noma yikuphi ukuvuka okuyobe kunqunyelwe emalini akhokhelwa ngaphansi
  kwalesi Sivumelwano ngokucubungula isevisi noma umkhiqizo phakathi
  nesikhathi esingaphambi kwenyanga eyi-12 (eyishumi neshumi nambili)
  enyangeni mayelana nensizakalo noma umkhiqizo okwenze ukuthi kube
  nomthwalo wemfanelo.<br>
  17 Ukunikezelwa nokudluliselwa<br>
  Awukwazi ukuthengisa, ukunikeza, ukwabela, ukudlulisela noma nganoma
  iyiphi enye indlela ukuhlukanisa noma ukulahla noma yikuphi noma wonke
  amalungelo akho nezibopho ngaphansi kwalesi Sivumelwano ngaphandle
  kwemvume ebhaliwe yaseMeerkat. I-Meerkat izoba nelungelo lokuthengisa,
  ukunikezela, ukwabela, ukudlulisela, ukuhlukanisa, ukulahla noma
  ukudlulisela noma yiliphi noma wonke amalungelo ayo nezibopho ngaphansi
  kwalo nangokwemibandela yalesi Sivumelwano kunoma yimuphi wabambisene
  nabo noma kunoma yimuphi umuntu ngaphandle kwemvume yakho ngaphandle
  kwesaziso unikeze ukuthi awunabandlululo ngokungadingekile
  njengomphumela. "Abahlanganyeli" ngaleyo nhloso kubandakanya inkampani
  yokuphatha i-Meerkat, inkampani ebambe iqhaza yenkampani ye-Meerkat
  (ngokubambisana "izinkampani zayo zokubamba"), izinsizakalo zayo,
  izinsizakalo zezinkampani zayo zokuphatha nezinye izinkampani ezilawulwa
   ngokuqondile noma ngokungaqondile yiMeerkat noma ngaphansi kokulawula
  okuvamile no-Meerkat.<br>
  18 Umbuso<br>
  Ngalo mvume uvuma igunya leNkantolo yeMantshi eRiphabhuliki
  yaseNingizimu Afrika ngokuphathelene nanoma yiziphi izinqubo ezingase
  ziqaliswe nguMeerkat ezivela kulesi Sivumelwano, uma nje uMeerkat
  angenelungelo lokufaka lolu hlelo iNkantolo eNkulu yaseNingizimu Afrika
  futhi, lapho kwenzeka kanjalo, uyavuma igunya laleyo nkantolo. Ubukhosi
  beNkantolo Yamangalo Emincane Imikhakha ayifakiwe ngokukhethekile,
  njengoba amaqembu avumelanisiwe ukulandela inqubo yokubambisana eshiwo
  esigatshaneni 5 ngenhla.<br>
  19 Ukuchitshiyelwa kwalesi sivumelwano<br>
  I-Meerkat inelungelo lokuchibiyela lesi sivumelwano ngezikhathi
  ezithile. Noma yiluphi uhlobo olusha lwesivumelwano luzoboniswa kwiWebhu
   yethu yethu kanye nosuku oluzosebenza ngayo, olungeke lube ngaphansi
  kwezinsuku ezingu-30 (ezingamashumi amathathu) emva kosuku olushicilelwe
   ngaso kuqala. Kuyisibopho sakho ukuvakashela isayithi lethu njalo ukuze
   ubone ukuthi iziphi izilungiso ezenziwe.<br>
  20 Jikelele<br>
  20.1 Amaqembu avuma futhi avumelana ukuthi lesi Sivumelwano sihlanganisa
   isivumelwano sonke phakathi kwazo nokuthi akukho ezinye izivumelwano,
  iziqinisekiso, izinkampani noma izethulo, noma amazwi noma okubhalwayo,
  eziphathelene naleso sivumelwano asihlanganisiwe kulesi Sivumelwano
  sizobe ebopha amaqembu. Akukho ukuhluka noma ukufakwa kwalesi
  Sivumelwano noma ifomu lesicelo kuzobophezela kunoma yiziphi
  izinhlangothi ngaphandle uma zibhalwe phansi futhi zibhalwe ngabombili.<br>
  20.2 Meerkat ngokwemigomo yesigaba 43 soMthetho we-ECT edingekayo
  ukwenza imininingwana yokuxhumana nayo, i-domicilia citandi et
  executandi yayo kanye nolunye ulwazi olutholakale kubhalisile balo
  abangena ekusebenzisaneni nge-elektroniki ne-Meerkat. Lolu lwazi
  luyatholakala kwiwebhusayithi yethu.<br>
  20.3 Uyavuma ukuthi noma yiziphi izaziso esizithumela kuwe ngokwemigomo
  yanoma yisiphi isivumelwano esenziwe phakathi kwethu singathunyelwa
  nge-imeyli ngaphandle kokunye okushiwo umthetho.<br>
  20.4 Akukho ukuthula, ukwehlisa noma ukunweba kwesikhathi uMeerkat
  angakunikeza noma akubonise kuzokwenza noma yikuphi ukubandlulula
  uMeerkat noma ukuvimbela uMeerkat ukuba asebenzise noma yiliphi
  lamalungelo alo esikhathini esizayo.<br>
  20.5 Uyavuma ukuthi njengosuku lokusayina yonke imininingwane enikezwe
  nguwe eMeerkat kuyiqiniso futhi ilungile nokuthi uzokwazisa i-Meerkat
  uma kwenzeka noma yikuphi ukuguqulwa kulemininingwane.<br>
  20.6 Yonke imigomo nemibandela yethu ingafinyelelwa, igcinwe futhi iphinde ikhiqizwe ngekhompyutha yakho.<br>
  20.7 Ikheli lendawo lapho uMeerkat azothola khona umsebenzi wezomthetho
  wamadokhumenti / domicilium citandi et executandi yilokhu okulandelayo:<br><br>
  203 i-Pine Park<br>
  1 Logan Way<br>
  I-Pinelands<br>
  eKapa<br>
  7450<br>
  Iningizimu Afrika<br><br>
  Usuku lokubuyekezwa kokugcina: 24 Januwari 2019<br><br><br></p>
`}

export default zulu