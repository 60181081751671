import React, { Component, Fragment } from 'react';
import gql from 'graphql-tag'
import { Query, Mutation } from 'react-apollo';
import { AutoComplete, Button, Radio, Icon, Spin } from 'antd';
import PropTypes from 'prop-types';
import { buttonStyle, services } from './menu';
import { affordability, getAllUrlParams, lowRisk, age, Potential_Benefit, shuffle } from './calculations';
import { random, randomAccount, randomCellNumber, randomStreet } from './randomData.js'
import { VictoryPie } from 'victory'
import { mokuSmall } from '../src/images/index.js';
import I18n, { setLocale } from './strings/i18n'

import ReactGA from 'react-ga';
import SignatureCanvas from 'react-signature-canvas';
import jwtDecode from 'jwt-decode'

const mokuVersion = "1.0"

const smallerButtonStyle = {
  background: 'rgb(255, 255, 255)',
  position: 'relative',
  minHeight: '36px',
  margin: '7px',
  color: 'rgb(34, 148, 144)',
  border: '2px solid rgb(34, 148, 144)',
  borderRadius: '15px',
  cursor: 'pointer',
  textAlign: 'center',
  justifyContent: 'center',
  fontSize: '1rem',
  lineHeight: 1,
  width: '90px'
}

const RadioGroup = Radio.Group;
const radioStyle = {
  display: 'block',
  height: '21px',
  lineHeight: '21px',
};



const GetCompany = gql`
{
  employers
}
`;


const GetOccupations = gql`
{
  occupations
}
`;


const FetchRecord = gql`
  query FetchCompuscanCreditRecord($firstName: String, $lastName: String, $idNumber: String),  {
  fetchCompuscanCreditRecord(firstName: $firstName, lastName: $lastName, idNumber: $idNumber, forceCheck:false) {
    verifiedFirstName
    verifiedLastName
    verifiedDeceased
    lifeInsurance
    shortTermInsurance
    allDebt
    totalDebt
    source
    compuScore
    riskColour
    riskType
    monthlyInstallment
    employers {
      companyName
    }
    addresses {
      lines
      city
      zip
    }
    housingDebt
    normalDebt
    livingExpenses
    contactDetails
    vehicleDebt
    client {
      creditorObligations {
        clientCreditor {
          description
        }
        originalBalance
        currentBalance
        reference
        shortTermInsurance
        creditLifeInsurance
        inceptionDate
        accountType
        accountTypeDescription
        statusCodeDescription
        statusCode
        updatedAt
      }
    }
  }
}
`;

// Seems a flaw here as the leads are all written as priority 99, with morning, afternoon, evening .. but we should also have an ASAP value
const createLead = gql`
mutation CreateChatBotLead($firstName: String, $surname: String, $idNumber: String, $email: String, $company: String, $occupation: String, $smoker: String, $monthlyIncome: Float, $cellNumber: String, $leadMessage: String, $leadInfo: [KeyValueInput], $bestContactTime: String, $language :String)
 {
  createChatBotLead (firstName: $firstName, surname: $surname, idNumber: $idNumber, email: $email, company: $company, occupation: $occupation , smoker: $smoker, monthlyIncome: $monthlyIncome, cellNumber: $cellNumber, leadMessage: $leadMessage, leadInfo: $leadInfo, bestContactTime: $bestContactTime, language: $language)
  {
    id
  }
}
`;

const startChatSession = gql`
  mutation StartChatSession ($utmSource: String, $utmMedium: String, $utmCampaign: String, $language: String, $sessionLocation: AddressInput, $ipAddress: String ){
    startChatSession(utmSource: $utmSource, utmMedium: $utmMedium, utmCampaign: $utmCampaign, language:$language, location:$sessionLocation, ipAddress:$ipAddress )
  }
`


const sendReport = gql`
  mutation SendCreditReport($email: String, $firstName: String) {
    sendCreditReport (email: $email, firstName: $firstName )
  }
`
export class Signature extends Component {
  constructor(props) {
    super(props)
    this.hideClear = false
    this.hideSave = true
    this.signed = false
  }

  enableSave = () => {
    if (!this.signed) {
      this.hideSave = false
    }
  }

  saveButton = () => {
    console.log('signature empty', this.SignatureCanvas.isEmpty())
    if (this.SignatureCanvas && !this.SignatureCanvas.isEmpty()) {
      this.signed = true
      this.hideSave = true
      this.hideClear = true
      const signature = this.signatureCanvas.getTrimmedCanvas().toDataURL(); // Save as PNG see https://github.com/szimek/signature_pad for usage
      this.props.triggerNextStep({ value: signature, trigger: this.props.trigger })
    }
  }
  //<Button key="clear" disabled={this.hideClear} style={{ smallerButtonStyle, display: this.hideClear ? 'none' : 'inline', backgroundColor: 'rgb(242, 246, 252)' }} onClick={() => this.SignatureCanvas.clear()}>Clear</Button>
  //<Button key="save" disabled={this.hideSave} style={{ smallerButtonStyle, marginLeft:"7",display: this.hideSave ? 'none' : 'inline', backgroundColor: 'rgb(242, 246, 252)' }} onClick={() => this.saveButton()}>Save</Button>

  render() {
    return (
      <center style={{ borderRadius: '10px', padding: "10", float: 'right', width: 300 }}>
        <div style={{ textAlign: "left", border: '1px solid #229490', borderRadius: '10px', backgroundColor: "#F2F6FC", paddingLeft: 10, paddingTop: 7, paddingBottom: 7, marginBottom: 10 }}>

          <SignatureCanvas redrawOnResize={true} ref={ref => this.SignatureCanvas = ref} options={{ penColor: "rgb(66, 133, 244)", onEnd: this.enableSave() }} />
          <hr width="80%" />
          <span style={{ marginLeft: '10%' }}>Please sign above</span>
        </div>
        <br />
      </center >
    );
  }
}

const options = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0
};



//const myApiKey = 'AIzaSyB926JmMczqLdfnDnsrVUDuwfENnHW-Zr0'
const myApiKey = 'AIzaSyBBVoeXFe-VRkSKrFUxTgFxBCDs2NjWjlM'


const error = (err) => {
  console.warn(`ERROR(${err.code}): ${err.message}`);
}


export const getLocation = async () => {

  return new Promise((resolve, reject) => {
    //resolve(ipStack())
     navigator.geolocation.getCurrentPosition((pos) => resolve(googleLocationApi(pos)), (err) => {console.log("Geolocation returned the following: ", err.message); resolve(ipStack())}, options)
    })
}

const ipStack = async () => {

  /*
  let ip = ''

  getIP().then((response) => {
    console.log('get ip returned ', response);
    ip = response.ip
    console.log('response.ip ', ip)
    ipLocate(ip).then((response) => console.log('response from iplocate is ', response))
  })
*/

  let result = await fetch("https://api.ipstack.com/check?access_key=56a90662652cae75f6a9152eec3e9a34")
  let loc = await result.json()

  console.log('location from ipstack is ', loc)
  if (loc) {
  return { "full": `${loc.city} ${loc.zip}, ${loc.region_name}, ${loc.country_name} `, "suburb": "", "country_name": loc.country_name, "city": loc.city, "region_name": loc.region_name, "zip": loc.zip, "geo": { "latitude": loc.latitude, "longitude": loc.longitude }, "ip": loc.ip }
  }
  else {
    console.log('location was not found at ipstack')
    return { "full": "unknown", "suburb": "", "country_name": "unknown", "city": "unknown", "region_name": "unknown", "zip": "unknown", "geo": { "latitude": "unknown", "longitude": "unknown" }, "ip": "unknown" }
  }

}

const googleLocationApi = async (pos) => {

  console.log('We have permission to access location ')

  // get IP info from ipify.org
  const ipResponse = await fetch("https://api.ipify.org/?format=json")
  const ip = await  ipResponse.json()
console.log('ipify ', ip)
  let loc = ""
  let crd = pos.coords
  let lat = crd.latitude
  let lng = crd.longitude
  console.log(`Your current position is: Latitude : ${crd.latitude}, Longitude: ${crd.longitude}. More or less ${crd.accuracy} meters.`);
  return ipStack()

  const response = await fetch('https://maps.googleapis.com/maps/api/geocode/json?address=' + lat + ',' + lng + '&key=' + myApiKey)
  const responseJson = await  response.json()
  console.log('ADDRESS GEOCODE is BACK!! => ' + JSON.stringify(responseJson));

  if (responseJson.error_message) {
    console.log('Geolocation error => ' + responseJson.error_message);
    return ipStack()
  }

  let formatted = responseJson.results[0].formatted_address
  let locationArray = formatted.split(",")
  let country = locationArray[4]
  let zip = locationArray[3]
  let city = locationArray[2]
  let suburb = locationArray[1]
  let street = locationArray[0]
  let region_name = ""

  console.log('location array is ', locationArray)

  let firstAddress = responseJson.results[1].address_components;
  console.log('firstAddress ', firstAddress)
  let full = responseJson.results[0].formatted_address
  console.log('Full Address ', full)
  let short = responseJson.results[1].formatted_address
  console.log('Short Address ', short)
  if (full.search(/unnamed/)) {
    full = short
  }

  /*
  let suburb = firstAddress[0].long_name
  console.log('street ', suburb)
  let city = firstAddress[1].long_name
  console.log('city ', city)
  let zip = firstAddress[3].long_name
  console.log('zip ', zip)
  let country = firstAddress[2].long_name
  console.log('country ', country)
  console.log('finding region ', responseJson.results[1].address_components)
  let region_name = responseJson.results[1].address_components[4].long_name
  console.log('region_name ', region_name)
*/
  return { "full": full, "suburb": suburb, "country_name": country, "city": city, "region_name": region_name, "zip": zip, "geo": { "latitude": crd.latitude, "longitude": crd.longitude }, "ip": ip.ip }
}


const getIP = async () => {
  let ipResponse = await fetch('https://jsonip.com/')
  let ip = await ipResponse.json()
  return ip
  console.log('ip is ', ip)
}

const ipLocate = async (ip) => {

  const proxyUrl = 'https://cors-anywhere.herokuapp.com/'
  const  targetUrl = `https://www.iplocate.io/api/lookup/${ip}`

  fetch(proxyUrl + targetUrl)
    .then((response) => response.json())
    .then((loc) => {

      console.log('fetch iplocate worked  reponse is ', loc)
      return { "full": `${loc.city} ${loc.postal_code}, ${loc.subdivision}, ${loc.country_name} `, "suburb": "", "country_name": loc.country, "city": loc.city, "region_name": loc.subdivision, "zip": loc.postal_code, "geo": { "latitude": loc.latitude, "longitude": loc.longitude }, "ip": ip }
    })
    .catch((error) => console.log('damm, something happened ', error))
}


export class Moku extends Component {

  constructor(props) {
    super(props)
    this.state = {
      gotLocation: false,
      location: ""
    }
    this.disabled = false
    this.location = ''
  }

  componentDidMount() {
    if (!this.state.gotLocation) {

      getLocation().then((obj) => {
        this.setState({
          gotLocation: true,
          location: obj
        })
        console.log('Browser location from getLocation() is ', obj)
      })

    }
  }

  clicked(locale) {
    this.disabled = true
    setLocale(locale)
    this.props.triggerNextStep({ value: { language: locale, location: this.state.location }, trigger: 'intro' });
  }

  render() {

    return (
      <div style={{ display: this.disabled ? "none" : "block" }}>
        <center
          style={{
            fontFamily: 'Pacifico',
            fontSize: '1.8em',
            color: '#229490'
          }}
        >
          <img height="155px" src={mokuSmall} alt="Moku" />
          <br />
          Meet Moku
          ™

          <div style={{ fontFamily: 'Ariel, Lato, Open Sans', fontSize: '1rem', textAlign: "left", margin: "28 0 28 14" }}>
            <strong>Hey I'm Moku, your robo-assistant.</strong><br /><br />
            I'm here to help you gain control of your finances. <br /><br />

            If you give me some info I can pull your <strong>credit report</strong> and see how it's looking. You can also ask me about <strong>loans</strong> and <strong>debt counselling</strong> if you would like.<br /><br />

            What language would you like to chat in?</div>

          <Button
            key={'af'}
            style={smallerButtonStyle}
            disabled={this.disabled}
            onClick={() => {
              this.clicked("af-US")
            }}
          >
            Afrikaans
          </Button>
          <Button
            key={'en'}
            style={smallerButtonStyle}
            disabled={this.disabled}
            onClick={() => {
              this.clicked("en-US")
            }}
          >
            English
          </Button>
          <Button
            key={'so'}
            style={smallerButtonStyle}
            disabled={this.disabled}
            onClick={() => {
              this.clicked("so-US")
            }}
          >
            Sotho
          </Button>
          <Button
            key={'ve'}
            style={smallerButtonStyle}
            disabled={this.disabled}
            onClick={() => {
              this.clicked("ve-US")
            }}
          >
            Venda
          </Button>
          <Button
            key={'xh'}
            style={smallerButtonStyle}
            disabled={this.disabled}
            onClick={() => { this.clicked("xh-US") }}
          >
            Xhosa
          </Button>
          <Button
            key={'zu'}
            style={smallerButtonStyle}
            disabled={this.disabled}
            onClick={() => { this.clicked("zu-US") }}
          >
            Zulu
          </Button>
        </center>
      </div>
    );
  }
}


export class StartChatSession extends Component {
  constructor(props) {
    super(props)
    this.started = false
    this.gaStarted = false
  }

  startTracking(token) {
    // if you want to use ReactGA then change the test to check for false
    if (this.gaStarted) {
      this.gaStarted = true
      var jwtDecoded = jwtDecode(token);
      console.log('jwt token decoded', jwtDecoded)
      var uuid = jwtDecoded.uuid
      ReactGA.initialize('UA-84216725-54', {
        debug: true,
        titleCase: false,
        gaOptions: {
          userId: uuid
        }
      });
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }

  /* componentDidMount() {
    if (!this.state.gotLocation) {
      locationRequest((location) => {

        if (location.ip == "") {
          console.log('no ip ')
          getIP().then((response) => console.log('getip response is ',response))
        }
        this.setState({
          gotLocation: true,
          location
        });


        console.log('location is ', location)
      })

    }
  }
  */

  render() {
    const { message, language, location } = this.props

    var sessionLocation = {}
    var ipAddress = ''

    return (

      <Mutation mutation={startChatSession}>
        {(startSession, { called, loading, error, data }) => {
          if (!called) {
            console.log('location prop is ', location )
            if (location) {
              console.log('set location variables')
              sessionLocation = { "full": location.full, "suburb": location.suburb, "country": location.country_name, "city": location.city, "region": location.region_name, "zip": location.zip }
              ipAddress = location.ip
            }
            const utmSource = getAllUrlParams().utm_source
            const utmMedium = getAllUrlParams().utm_medium
            const utmCampaign = getAllUrlParams().utm_campaign
            console.log('Starting new session ...' + mokuVersion + ' ' + utmSource + ' ' + utmMedium + ' ' + utmCampaign + ' ' + language + ' ' ,  sessionLocation + ' ' + ipAddress )
            startSession({ variables: { mokuVersion, utmSource, utmMedium, utmCampaign, language, sessionLocation, ipAddress } })
            return <div>{message}</div>
          } else {
            if (loading) {
              return <div>{message}</div>
            } if (error) {
              // TODO: Goto error component
              console.log(error)
              if (!this.started) {
                this.props.triggerNextStep({ value: location, trigger: 'errorEnd' })
                this.started = true
              }
              return <div>{message}</div>
            } else {
              if (!this.started) {
                localStorage.setItem('token', data.startChatSession)
                this.startTracking(data.startChatSession)
                console.log('starting session with location ', sessionLocation)
                this.props.triggerNextStep({ value: location, trigger: 'firstName' })
                this.started = true
              }
              return <div>{message}</div>
            }
          }
        }}
      </Mutation>
    )
  }
}

export class SendCreditReport extends Component {
  constructor(props) {
    super(props)
    this.started = false
  }

  render() {
    const { message } = this.props
    console.log('send email to ' + this.props.steps.email.value)
    const email = this.props.steps.email.value
    const firstName = this.props.steps.firstName.value
    return (
      <Mutation mutation={sendReport}>
        {(sendCreditReport, { called, loading, error }) => {
          if (!called) {
            console.log('Sending credit report')
            sendCreditReport({ variables: { email, firstName } })
            return <div>{message}</div>
          } else {
            if (loading) {
              return <Spin style={{ color: '#fff' }} tip="sending" />
            } if (error) {
              // TODO: Goto error component
              console.log(error)
              if (!this.started) {
                this.props.triggerNextStep({ trigger: 'errorEnd' })
                this.started = true
              }
              return <div>{message}</div>
            } else {
              if (!this.started) {
                this.props.triggerNextStep({ delay: 1000, trigger: 'RiskPie' })
                this.started = true
              }
              return <div>{message}</div>
            }
          }
        }}
      </Mutation>
    )
  }
}

const mokuMessage = (props) => {
  console.log('mokuMessage is ', props.steps)
  try {
    if (props.steps.terms.value == "no") return "User wouldn't give T&C permission but asked us to call."
    if (props.steps.verificationFailed) return "Verification failed but user asked us to call to continue the conversation."
    if (props.steps.keepChatting.value == "no") return "User didn't want to enter an ID number but asked us to call to continue the conversation."
    if (props.steps.menuOption.value == 801) return `User has asked if they will qualify for a loan, Moku advised it is unlikely and we could potentially save them R${props.steps.affordability.value} per month on debt repayments. User says yes to being contacted for assistance.`
    if (props.steps.menuOption.value == 802) return `User has asked if debt counselling can help them. Moku has advised we could potentially save them R${props.steps.affordability.value} per month on debt repayments. User says yes to being contacted for assistance.`
    if (props.compuScore < 620) return `User has asked if they will qualify for a loan, Moku advised it is unlikely and we could potentially save them R${props.steps.affordability.value} per month on debt repayments. User says yes to being contacted for assistance.`
    if (props.steps.menuOption.value == 804) return `User has asked what their credit profile looks like. Moku has advised it looks good but having checked affordability it didn't look so great and we can help save R${props.steps.affordability.value} per month on  debt repayments. User says yes to being contacted for assistance.`
    if (props.steps.menuOption.value == 803) return `User has asked what their credit profile looks like. Moku has advised it is not good but having checked affordability we can help save R${props.steps.affordability.value} per month on  debt repayments. User says yes to being contacted for assistance.`
    if (props.steps.menuOption.value == 805) return `User has asked about Credit Life Insurance. Moku has advised that having checked records we can help save through replacemnt insurance. User says yes to being contacted for assistance.`
    if (props.steps.menuOption.value == 999) return "Moku crashed but user asked us to call to continue the conversation."
    return "No idea why we must call, maybe Moku crashed and user asked us to call."
  }
  catch (errro) {
    return "No idea why we must call, maybe Moku crashed and user asked us to call."
  }
}

const locales = [
  { onestream: "afr", locale: "af-US" }, { onestream: "mku", locale: "en-US" }, { onestream: "sor", locale: "so-US" }, { onestream: "ven", locale: "ve-US" }, { onestream: "xho", locale: "xh-US" }, { onestream: "zul", locale: "zu-US" }
]

class SaveLeadInfo extends Component {

  render() {
    console.log('Writing lead to DB, props are  ', this.props.steps)
    console.log('Locale is  ' + I18n.locale)

    const language = locales.reduce((lang, d) => {
      if (d.locale == I18n.locale) { return d.onestream }
      return lang
    }, "mku")


    console.log('Language is  ' + language)
    const firstName = this.props.steps.firstName ? this.props.steps.firstName.value : ''
    const surname = this.props.steps.lastName ? this.props.steps.lastName.value : ''
    const idNumber = this.props.steps.idNumber ? this.props.steps.idNumber.value : ''
    const cellNumber = this.props.steps.anywayCellNumber ? this.props.steps.anywayCellNumber.value : this.props.steps.newNumber ? this.props.steps.newNumber.value : this.props.steps.cellNumber ? this.props.steps.cellNumber.value : ''
    const company = this.props.steps.employer ? this.props.steps.employer.value : ''
    const occupation = this.props.steps.occupation ? this.props.steps.occupation.value : ''
    const smoker = this.props.steps.smoker ? this.props.steps.smoker.value : ''
    const signature = this.props.steps.clSignature ? this.props.steps.clSignature.value : ''
    const email = this.props.steps.email ? this.props.steps.email.value : ''
    const monthlyIncome = this.props.steps.salary ? this.props.steps.salary.value : null
    const leadMessage = mokuMessage(this.props)
    const bestContactTime = this.props.steps.timePrompt ? this.props.steps.timePrompt.value : 'now'
    console.log('Writing lead to DB 2, message is  ' + leadMessage)

    return (

      <Mutation mutation={createLead} >
        {(createChatBotLead, { loading, error, data, called }) => {
          if (!called) {
            createChatBotLead({ variables: { firstName, surname, idNumber, email, monthlyIncome, cellNumber, leadMessage, bestContactTime, language, company, occupation, smoker, signature } })
            console.log('Writing lead to DB, part 3 props are  ', this.props.steps)
            return <Spin tip="saving the conversation ..." />
          } else {
            return (
              <div>
                {loading && <Spin style={{ color: '#fff' }} tip="saving conversation ..." />}
                {error && <ChatButton {...this.props} button={{
                  value: 999,
                  trigger: 'saveLeadError',
                  text: 'Oh dear, a problem'
                }} />}
              </div>
            )
          }
        }}
      </Mutation>
    );
  }
}

const bubbleStyle = {
  height: 50,
  paddingRight: 14,
  paddingLeft: 14,
  borderRadius: 19,
  paddingLeft: 14,
  position: 'relative',
  backgroundColor: 'black',
  width: '75%'
}

const inputStyle = {
  background: "rgb(255, 255, 255)",
  bottom: 0,
  height: 48,
  paddingLeft: 10,
  color: "rgb(34, 148, 144)",
  border: "2px solid rgb(34, 148, 144)",
  borderRadius: 8,
  cursor: 'pointer',
  width: '100%',
}
const arrowInput = {
  width: 10, border: 0, right: '45', bottom: '-8', zIndex: 99
}
const arrowHide = {
  display: 'none'
}

const chatArrow = (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 500 500"><g><g><polygon points="0,497.25 535.5,267.75 0,38.25 0,216.75 382.5,267.75 0,318.75"></polygon></g></g></svg>
)
class Employer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      value: '',
      disabled: false,
      disableClick: true,
      dataAll: [],
    }
    this.started = false
  }

  handleSelect = (value) => {
    this.setState({
      value
    });
  }

  handleClick = () => {
    //console.log('company selected value is ', this.state.value)
    this.setState({
      disabled: true,
      disableClick: true
    });
    this.props.triggerNextStep({ value: this.state.value, trigger: this.props.steps.menuOption.value == '805' ? 'employTypePmt' : 'emailPrompt' })
  }


  handleChange = (value) => {
    //console.log('value is ', value)
    this.setState({
      value,
      disableClick: value.length < 2,
      data: value.length > 1 ? this.state.dataAll.filter(company => {
        if (company.toUpperCase().indexOf(value.toUpperCase()) > -1) { return true }
        return false
      }).slice(0, 10) : [],
    });
  }

  render() {

    const { data, dataAll } = this.state
    return (

      <Query query={GetCompany} >
        {({ loading, error, data }) => {

          if (loading || !data) return <Spin style={{ color: '#fff' }} tip="Building a list of employers" />

          if (error) {
            return <ChatButton {...this.props} button={{
              value: 999,
              trigger: 'errorEnd',
              text: 'Oh dear, no answer'
            }} />
          }
          else {
            if (data && data.employers && dataAll.length === 0) {
              this.setState({
                dataAll: data.employers,
              });
            }
            return (
              <Fragment>
                <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                  <AutoComplete
                    dataSource={this.state.data}
                    style={this.state.disabled ?
                      bubbleStyle : inputStyle
                    }
                    onChange={this.handleChange}
                    onSelect={this.handleSelect}
                    placeholder="Enter employer"
                    defaultActiveFirstOption
                    backfill
                    disabled={this.state.disabled}
                    autoFocus
                  //filterOption={(inputValue, company) => company.name.toUpperCase().indexOf(inputValue.toUpperCase()) >-1}
                  />
                  <Button
                    onClick={this.handleClick}
                    style={this.state.disableClick ? arrowHide : arrowInput}
                  >
                    {chatArrow}
                  </Button>
                </div>
              </Fragment>
            );
          }
        }}
      </Query>
    );

  }
}

class Occupation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      value: '',
      disabled: false,
      disableClick: true,
      dataAll: [],
    }
    this.started = false
  }

  handleSelect = (value) => {
    this.setState({
      value
    });
  }

  handleClick = () => {
    //console.log('company selected value is ', this.state.value)
    this.setState({
      disabled: true,
      disableClick: true
    });
    this.props.triggerNextStep({ value: this.state.value, trigger: 'employerPmt' })
  }


  handleChange = (value) => {
    //console.log('value is ', value)
    this.setState({
      value,
      disableClick: value.length < 2,
      data: value.length > 1 ? this.state.dataAll.filter(occupation => {
        if (occupation && occupation.toUpperCase().indexOf(value.toUpperCase()) > -1) { return true }
        return false
      }).slice(0, 10) : [],
    });
  }

  render() {

    const { data, dataAll } = this.state
    return (

      <Query query={GetOccupations} >
        {({ loading, error, data }) => {

          if (loading || !data) return <Spin style={{ color: '#fff' }} tip="Building a list of occupations" />

          if (error) {
            return <ChatButton {...this.props} button={{
              value: 999,
              trigger: 'errorEnd',
              text: 'Oh dear, no answer'
            }} />
          }
          else {
            if (data && data.occupations && dataAll.length === 0) {
              this.setState({
                dataAll: data.occupations,
              });
            }
            return (
              <Fragment>
                <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                  <AutoComplete
                    dataSource={this.state.data}
                    style={this.state.disabled ?
                      bubbleStyle : inputStyle
                    }
                    onChange={this.handleChange}
                    onSelect={this.handleSelect}
                    placeholder="Enter occupation"
                    defaultActiveFirstOption
                    backfill
                    disabled={this.state.disabled}
                    autoFocus
                  //filterOption={(inputValue, company) => company.name.toUpperCase().indexOf(inputValue.toUpperCase()) >-1}
                  />
                  <Button
                    onClick={this.handleClick}
                    style={this.state.disableClick ? arrowHide : arrowInput}
                  >
                    {chatArrow}
                  </Button>
                </div>
              </Fragment>
            );
          }
        }}
      </Query>
    );

  }
}


class CompuScan extends Component {

  shouldComponentUpdate() {
    return false
  }

  render() {
    console.log('CompuScan props are ', this.props)
    const { message } = this.props
    const firstName = this.props.steps.firstName.value
    const lastName = this.props.steps.lastName.value
    const idNumber = this.props.steps.idNumber.value
    const menuOption = this.props.steps.menuOption.value

    return (
      <div style={{ width: '100%' }}>

        <Query query={FetchRecord} variables={{ firstName, lastName, idNumber }}>
          {({ loading, error, data }) => {

            if (loading || !data) return <Spin style={{ color: '#fff' }} tip="Just gathering your information" />

            if (error) {
              console.log('CompuScan error is ', error)
              return <ChatButton {...this.props} button={{
                value: 999,
                trigger: 'errorEnd',
                text: 'Oh dear, no answer'
              }} />
            }
            else {

              if (!this.started) {
                console.log('compuscan data ', data)
                console.log('Menu optoin is ', menuOption)
                this.props.triggerNextStep({ value: { ...data.fetchCompuscanCreditRecord }, trigger: menuOption == '805' ? 'occupationPmt' : 'marriedPrompt' })
                this.started = true
              }
              return <div>Thank you</div>
            }

          }}
        </Query>
      </div >
    );
  }
}


const nextTrigger = (step) => {
  let next = 'Loan'
  services.some((d) => {
    if (d.value === step) {
      next = `${d.trigger}`
      return true
    }
  })
  return next
}

class Affordability extends Component {
  constructor(props) {
    super(props)
    this.started = false
  }

  render() {
    const risk = lowRisk(this.props.steps.compuscan.value.compuScore)
    const salary = this.props.steps.salary.value
    const expenses = this.props.steps.expenses ? this.props.steps.expenses.value : 0
    const dependants = this.props.steps.dependants ? this.props.steps.dependants.value : 0
    const married = this.props.steps.married ? this.props.steps.married.value == 'married' : false
    const myAge = age(this.props.steps.idNumber.value)
    const bureauExpenses = this.props.steps.compuscan.value.totalDebt
    const housingDebt = this.props.steps.compuscan.value.housingDebt
    const vehicleDebt = this.props.steps.compuscan.value.vehicleDebt
    const normalDebt = this.props.steps.compuscan.value.normalDebt
    const afford = affordability(salary, expenses, dependants, myAge, married, bureauExpenses)
    let next = nextTrigger(this.props.steps.menuOption.value)

    let value = ''
    if (this.props.steps.menuOption.value == "805") {
      //reference and account
      const unique = {}
      const obligations = this.props.steps.compuscan.value.client.creditorObligations.sort((a, b) => a.updatedAt > b.updatedAt ? -1 : 1).filter(
        obl => {
          if (!unique[obl.reference]) {
            unique[obl.reference] = true
            return (obl.statusCode != 'C' && obl.currentBalance > 0)
          }
          else {
            return false
          }
        }).filter(o => ['C', 'D', 'E', 'G', 'I', 'M', 'N', 'O', 'P', 'R', 'T', 'V', 'Z', 'B', 'Y'].indexOf(o.accountType.toUpperCase()) > -1)
      console.log('Current Obligation ', obligations)
      const outstandingDebt = obligations.reduce((total, a) => total + a.currentBalance, 0)
      const approximateCLI = Math.round((outstandingDebt / 1000) * 4.5)
      value = { "accounts": obligations.length, "debt": outstandingDebt, "cli": approximateCLI }
    }
    else {
      value = Potential_Benefit(housingDebt, vehicleDebt, normalDebt)
    }


    console.log('Potential benefit ', value)
    console.log('affordability steps prop ', this.props)

    if (!this.started) {
      if (risk && afford) { next += 'PassPass' }
      else if (risk) { next += 'PassFail' }
      else if (afford) { next += 'FailPass' }
      else { next += 'FailFail' }

      console.log('next step is ' + next)
      this.started = true
      setTimeout(() => { this.props.triggerNextStep({ value, trigger: next }) }, 100)
    }

    return <div> </div>
  }
}


class ChatButton extends Component {
  render() {
    const { button } = this.props;
    return (
      <center style={{ width: '100%' }}>
        <Button key={button.value} style={buttonStyle} onClick={() => this.props.triggerNextStep({ value: button.value, trigger: button.trigger })}>{button.text}</Button>
      </center>
    );
  }
}


export const Band = () => (
  <img src="https://media.giphy.com/media/vgMmIZoHbR7bi/giphy.gif" width="100%" alt="The Meerkat Band" />
)

const previousVisitor = (cellNumber) => {
  // need to run a query to check cellNumber and see what we have on this person
  return 'previousVisit'
}

const buildArray = (questions, randomFunction) => {

  let fakeCount = 0
  while (fakeCount < 2) {
    let fake = randomFunction()
    if (questions.indexOf(fake) < 0) {
      questions.push(fake)
      fakeCount++
    }
  }
}

const buildTestSet = (array, prop) => {

  console.log('buildTest ', array + ' ' + prop)
  let questions = []

  let correctItem = "NONE"
  if (array && array.length > 0) correctItem = random(array)

  if (prop == "lines") {
    if (correctItem != "NONE") correctItem = correctItem.lines[0] + ' ' + correctItem.lines[1] + ' ' + correctItem.lines[2] + ' ' + correctItem.lines[3] + ' ' + correctItem.zip
    questions.push(correctItem.toUpperCase())
    buildArray(questions, randomStreet)
  }
  else {

    if (prop == "contactDetails") {
      questions.push(correctItem)
      buildArray(questions, randomCellNumber)
    }

    if (prop == "accounts") {
      if (correctItem != "NONE") correctItem = correctItem.clientCreditor.description
      questions.push(correctItem.toUpperCase())
      buildArray(questions, randomAccount)
    }
  }

  shuffle(questions, prop)

  return { questions, correctItem }
}

export class Challenge extends Component {
  constructor(props) {
    super(props)
    console.log('challenge props ', this.props)
    this.state = {
      addressValue: "",
      phoneValue: "",
      accountValue: "",
      addresses: buildTestSet(this.props.steps.compuscan.value.addresses, "lines"),
      phones: buildTestSet(this.props.steps.compuscan.value.contactDetails, "contactDetails"),
      accounts: buildTestSet(this.props.steps.compuscan.value.client.creditorObligations, "accounts")
    }
  }

  onAddressChange = (e) => {
    console.log('radio checked', e.target.value);
    this.setState({
      addressValue: e.target.value,
    });
  }
  onPhoneChange = (e) => {
    console.log('radio checked', e.target.value);
    this.setState({
      phoneValue: e.target.value,
    });
  }


  onAccountChange = (e) => {
    console.log('radio checked', e.target.value);
    this.setState({
      accountValue: e.target.value,
    });
  }

  isIdOwner = (addressValue, correctAddress, phoneValue, correctPhone, accountValue, correctAccount) => {
    console.log('is idowner ' + addressValue + ' ' + correctAddress + ' ' + phoneValue + ' ' + correctPhone + ' ' + accountValue + ' ' + correctAccount)
    if (addressValue == correctAddress && phoneValue == correctPhone && accountValue == correctAccount) {
      console.log('Passed validation')
      this.props.triggerNextStep({ value: "pass", trigger: 'passedValidaton' })
    }
    else {
      console.log('Failed validation')
      this.props.triggerNextStep({ value: "fail", trigger: 'verificationFailed' })
    }
  }

  render() {
    console.log('Challenge props are ', this.props)
    const { addresses, phones, accounts } = this.state
    console.log('addresses, phones, accounts ', { addresses }, { phones }, { accounts })

    return (
      <div style={{
        textAlign: 'left',
        borderRadius: '18px',
        boxShadow: '0 1px 2px 0 rgba(0,0,0,0.15)',
        paddingLeft: "0",
        paddingTop: "0",
        paddingBottom: "0",
        width: "400px"
      }}>
        <p>Select an address where you lived before</p>
        <RadioGroup onChange={this.onAddressChange} buttonStyle="solid" value={this.state.addressValue}>
          {addresses.questions.map(address => <Radio key={address} style={radioStyle} value={address}> {address.substring(0, 72)} </Radio>)}
        </RadioGroup>
        <br /><br />
        <p>Select a phone which you have used before</p>
        <RadioGroup onChange={this.onPhoneChange} buttonStyle="solid" value={this.state.phoneValue}>
          {phones.questions.map(phone => <Radio key={phone} style={radioStyle} value={phone}> {phone}</Radio>)}
        </RadioGroup>
        <br /><br />
        <p>Select an account which you have</p>
        <RadioGroup onChange={this.onAccountChange} buttonStyle="solid" value={this.state.accountValue}>
          {accounts.questions.map(acc => <Radio key={acc} style={radioStyle} value={acc}> {acc} </Radio>)}
        </RadioGroup>
        <br /><br />
        <center style={{ width: '70%' }}>
          <Button style={buttonStyle} onClick={() => { this.isIdOwner(this.state.addressValue, this.state.addresses.correctItem, this.state.phoneValue, this.state.phones.correctItem, this.state.accountValue, this.state.accounts.correctItem) }}>Validate</Button>
        </center>
      </div>
    );
  }
}

const low = 480
const high = 750
const width = "400"

export class RiskPie extends Component {
  constructor() {
    super();
    this.state = {
      data: '',
      text: '',
      firstSector: '',
      lastSector: ''
    };
  }

  componentDidMount() {
    this.setState({
      data: this.getData(this.props.steps.compuscan.value.compuScore),
      text: this.getRange(this.props.steps.compuscan.value.compuScore),
      riskType: this.props.steps.compuscan.value.riskType
    });
  }

  componentWillUnmount() {
    window.clearInterval(this.setStateInterval);
  }

  getData(score) {

    if (score < low) score = low
    let range = high - low
    let firstSector = ((score - low + 1) / range) * 100
    if (firstSector < 50) firstSector = 50

    let secondSector = ((high - score)) / range * 100
    this.setState({ firstSector, secondSector });

    console.log('Bar sectors are ' + firstSector + ' ' + secondSector)

    return [{ x: 1, y: firstSector }, { x: 1, y: secondSector }]
  }

  getRange(score) {
    if (score > 659) return "Excellent"
    if (score > 628) return "Very Good"
    if (score > 610) return "Good"
    if (score > 594) return "Not Good"
    return "VERY HIGH RISK"
  }

  render() {

    console.log('pie state ', this.state)
    console.log('pie props ', this.props)

    let riskColour = this.props.steps.compuscan.value.riskColour
    if (!riskColour || riskColour.toString().indexOf('#') < 0) riskColour = "green"
    console.log('riskColor ', riskColour)

    return (
      <div style={{ height: 30, display: "flex", minWidth: 250, verticalAlign: "middle", margin: "auto" }}>
        <div style={{ border: "1px solid white", display: "flex", height: 30, borderBottomRightRadius: "25px", borderTopRightRadius: "25px", width: (this.state.firstSector + "%"), backgroundColor: riskColour }}>
          <p style={{ marginTop: "2px", marginLeft: "5px" }}  > {this.state.riskType}</p>
        </div>
      </div>
    );
  }
}

const titleCase = (str) => {
  return str.toLowerCase().split(' ').map(function (word) {
    return word.replace(word[0], word[0].toUpperCase());
  }).join(' ');
}

export class PercentPie extends Component {
  constructor() {
    super();
    this.state = {
      data: '',
      firstSector: '',
      lastSector: ''
    };
  }

  componentDidMount() {
    console.log('PercentPie ', this.props.steps)
    this.setState({
      data: this.getData(this.props.steps.compuscan.value.compuScore),
      riskType: titleCase(this.props.steps.compuscan.value.riskType)
    });
  }

  componentWillUnmount() {
    window.clearInterval(this.setStateInterval);
  }

  getData(score) {

    console.log('score is ' + score)

    if (score < low) score = low
    let range = high - low
    let firstSector = Math.floor(((score - low + 1) / range) * 100)
    if (firstSector < 50) firstSector = 50

    let secondSector = ((high - score)) / range * 100
    this.setState({ firstSector, secondSector });

    console.log('Bar sectors are ' + firstSector + ' ' + secondSector)

    return [{ x: 1, y: firstSector }, { x: 1, y: secondSector }]
  }

  render() {

    let riskColour = this.props.steps.compuscan.value.riskColour
    if (!riskColour || riskColour.toString().indexOf('#') < 0) riskColour = "green"

    return (
      <center>
        <svg width={200} height={200}>
          <text x={100} y={110} textAnchor="middle" >
            <tspan x="100" dy="-1rem">{this.state.firstSector}%</tspan>
            <tspan x="100" dy="1rem">{this.state.riskType}</tspan>
          </text>
          <VictoryPie
            standalone={false}
            padAngle={0}
            // used to hide labels
            labelComponent={<span />}
            innerRadius={65}
            width={200} height={200}
            data={[{ 'key': "", 'y': this.state.firstSector }, { 'key': "", 'y': (100 - this.state.firstSector) }]}
            colorScale={[riskColour, "#EEEEEE"]}
          />
        </svg>
      </center>
    );
  }
}




// Example component
class Review extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      idNumber: '',
      income: ''
    };
  }

  componentWillMount() {
    const { steps } = this.props;
    const { firstName, idNumber, income } = steps;

    this.setState({ firstName, idNumber, income });
  }

  render() {
    const { firstName, idNumber, income } = this.state;
    return (

      <div style={{ width: '100%' }}>
        <h3>Summary</h3>
        <table>
          <tbody>
            <tr>
              <td>Name</td>
              <td>{firstName.value}</td>
            </tr>
            <tr>
              <td>ID</td>
              <td>{idNumber.value}</td>
            </tr>
            <tr>
              <td>Income</td>
              <td>{income.value}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

Review.propTypes = {
  steps: PropTypes.object
};

Review.defaultProps = {
  steps: undefined
};



/*  Move the compuscan table here .. might turn it into a component to allow editing

{ compuscan ?
  <div> </div>
  :
  <center>
  <h6>Your Information</h6>
    <table>
      <tbody>
        <tr>
          <td style={{ width: '50%' }}>Name: </td>
          <td>{firstName} {lastName}</td>
        </tr>
        <tr>
          <td>ID: </td>
          <td>{idNumber}</td>
        </tr>
        <tr>
          <td>Cell: </td>
          <td>{cellNumber}</td>
        </tr>
        <tr>
          <td>Married: </td>
          <td>{married ? 'Married' : 'Not Married'}</td>
        </tr>
        <tr>
          <td>Age: </td>
          <td>{myAge}</td>
        </tr>
        <tr>
          <td>Dependents: </td>
          <td>{dependants}</td>
        </tr>
        <tr>
          <td>Income: </td>
          <td>{salary}</td>
        </tr>
        <tr>
          <td>Expenses: </td>
          <td>{expenses}</td>
        </tr>
      </tbody>
    </table>
    <br />
  </center>

}

*/


export { Affordability, ChatButton, CompuScan, Employer, Occupation, previousVisitor, SaveLeadInfo }
