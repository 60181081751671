import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
//import SignaturePad from 'react-signature-pad-wrapper';
import { Alert, Button, message, Card, Checkbox, Spin} from 'antd';
import 'antd/lib/button/style/css';
import 'antd/lib/message/style/css';
import moment from 'moment';
import { meerkatHeader, meerkatFooter, dobSignature } from '../base64';
import { API_URL } from '../config';
import fileDownload from 'js-file-download'
import SignatureCanvas from 'react-signature-canvas';

const statusMessage = message;
statusMessage.config({ top: '80%', right: '20%' });

export class POA extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disableSave: true,
      location: this.props.from == 'moku' ? this.props.location : this.props.steps.location,
      clientId: this.props.clientId || '',
      firstNames: this.props.from == 'moku' ? this.props.steps && this.props.steps.firstName.value : this.props.steps.firstNames ,
      surname: this.props.from == 'moku' ?  this.props.steps && this.props.steps.lastName.value : this.props.steps.surname,
      idNumber: this.props.from == 'moku' ?  this.props.steps && this.props.steps.idNumber.value : this.props.steps.idNumber,
      showSpinner: false,
      alertMessage: 'Please wait ...',
      alertTitle: 'Creating pdf file',
      fromDataURL: '',
      notDone: true,
      checked:true
    };
    this.clicked = false;
  }

  cancelButton = () => {
    if (!this.clicked) {
      this.clicked = true;
      this.hideModal();
      console.log('cancel clicked');
      setTimeout(() => {
        this.props.triggerNextStep({ value: 'no', trigger: 'youSureYesNo' });
      }, 100);
    }
  };


  saveButton = () => {
    console.log('save Button was clicked, signaturepad is  ', this.signaturePad + ' and clicked is ', this.clicked)

    if (this.signaturePad && !this.clicked) {
      console.log('save clicked')
      if (!this.signaturePad.isEmpty()) {
        this.setState({ disableSave: true });
        if (!this.clicked) {
          this.clicked = true;
          // save signature
          let signature = this.signaturePad.getTrimmedCanvas().toDataURL('image/png');
          console.log('signature is  ', signature)

          this.setState({ showSpinner: true, alertTitle: 'Saving pdf file' });

          request(this.state.clientId, this.state.firstNames, this.state.surname, this.state.idNumber, this.state.location.city, this.state.location.country_name, signature, this.props.from, this.state.checked)
            .then ((response) => {
              console.log('create poa pdf  returned ', response);
              this.setState({ showSpinner: false });
            })
          if (this.props.from == 'moku') {
            setTimeout(() => {
              this.props.triggerNextStep({
                value: signature,
                trigger: 'clSigned'
              });
            }, 100);
          }

          this.setState({ notDone : false})

          console.log('save clicked & signature ');

        }
      } else {
        statusMessage.error('Please sign in the box', 5);
      }
    }
  };


  saveSig = () => {
    this.setState({ fromDataURL: this.signaturePad.getTrimmedCanvas().toDataURL('image/png') });

    console.log('enable save ', this.state.disableSave)
    if (this.state.disableSave) {
      console.log('enable save')
      this.setState({ disableSave: false });
    }
  };

  onCheckChange = (e) => {
    console.log('checked ', e.target.checked)
    this.setState({
      checked: e.target.checked,
    });
  }

  poaBody(firstName, lastName) {

    return (
      <div>
        <div style={{ marginTop: 14, marginBottom: 14, textAlign: 'left', fontSize: 10, marginLeft: '10%' }}>

          <div style={{ border: '1px solid #229490', borderRadius: '10px', backgroundColor: '#F2F6FC', PADDING: 3, width: 285, height: 150 }}>
            <SignatureCanvas penColor='black'
              canvasProps={{ width: 250, height: 135, className: 'sigCanvas' }} ref={(ref) => { this.signaturePad = ref }} onEnd={this.saveSig} />
          </div>
          Signature, (
          {firstName}, {lastName})
          <br /><br />
          <Button
            id="clearButton"
            icon="delete"
            shape="round"
            onClick={() => this.signaturePad.clear()}
          >
            Clear{' '}
          </Button>
          <Button
            id="saveButton"
            type="primary"
            icon="check"
            shape="round"
            style={{ marginLeft: 7 }}
            disabled={this.state.disableSave}
            onClick={() => this.saveButton()}
          >
            Sign and Save{' '}
          </Button>
          <br/>
          <Checkbox style={{marginTop:14, marginLeft:7}} checked={this.state.checked} onChange={this.onCheckChange}>
            Download a copy of the POA
        </Checkbox>

        </div>

        <div style={{ textAlign: 'left' }} dangerouslySetInnerHTML={meerkatFooter} />
      </div >
    )
  }

    poaText (firstName, lastName, idNumber, city, country_name) {
      return (
        <Fragment>
          <div style={{ marginLeft: 25 }} dangerouslySetInnerHTML={meerkatHeader} />
          <div
            style={{
              marginLeft: '1%',
              marginRight: '1%',
              marginTop: 7,
              fontWeight: 400,
              fontSize:10
            }}
          >
            <p>
              <b>Power of Attorney</b>
            </p>
            I,{' '}
            <span style={{ textDecoration: 'underline', fontWeight: 'bold' }}>
              {' '}
              {firstName} {lastName}
            </span>{' '}
            of identity number,{' '}
            <span style={{ textDecoration: 'underline', fontWeight: 'bold' }}>
              {' '}
              {idNumber}{' '}
            </span>{' '}
            hereby appoint <b>MyMeerkat (PTY) Ltd </b> of{' '}
            <b>203, Pine Park, 1 Logan Way, Pinelands, 7504</b>, Registration number{' '}
            <b>2015/102401,07</b> and <b>authorised financial services provider </b>{' '}
            <b>(FSP 46535)</b> and its representatives, duly authorised as my
            financial advisor. Such appointment will be limited to the product
            categories for which MyMeerat (Pty) Ltd is licensed with the Financial
            Sector Conduct Authority for, including but not limited to the following
            functions:
            <br />
            <br />
            <ul>
              <li>
                <i>
                  To obtain any records from the applicant, his/her creditors and /
                  or credit bureau to determine debt and linked insurance.
                </i>
              </li>
              <li>
                <i>
                  Setting up of debit order, Naedo, EFT and PERSAL collections for
                  insurance premiums.
                </i>
              </li>
              <li>
                <i>
                  Cancellation, replacement or inclusion of credit life insurance in
                  terms of Sections 106(4) and 106(6) of the National Credit Act as
                  Financial Service Provider and adviser.
                </i>
              </li>
              <li>
                <i>Raising of disputes with relevant industry bodies.</i>
              </li>
              <li>
                <i>Lodging of any complaints with regulatory authorities.</i>
              </li>
            </ul>
            Signed at­­­­­­­­{' '}
            <span style={{ textDecoration: 'underline', fontWeight: 'bold' }}>
              {' '}
              {city}, {country_name}{' '}
            </span>{' '}
            on this date
            <span style={{ textDecoration: 'underline', fontWeight: 'bold' }}>
              {' '}
              {moment().format('Do MMMM YYYY')}{' '}
            </span>
          </div>
        </Fragment>
      );
    };


  render() {
    //04255015089
    const { isMobile } = this.props
    console.log('in poa, props are ', this.props)
    if (this.state.notDone) {
      return (
        <Card id="printMe" style={{ border: '1px solid rgb(34, 148, 144)', borderRadius: 15, textAlign: 'left', width: '98%' , overflow: 'auto', margin:  "-5% 1% 1%"  }}>
          <Spin spinning={this.state.showSpinner} size="large" style={{ float: 'right', top: '79vh' }} delay={2500}>
            <Alert message={this.state.alertTitle} description={this.state.alertMessage} type='info' banner style={{ fontWeight: 'bold', display: this.state.showSpinner ? 'block' : 'none' }} />
          </Spin>

          {this.poaText(this.state.firstNames, this.state.surname, this.state.idNumber, this.state.location.city, this.state.location.country_name)}
          {this.poaBody(this.state.firstNames, this.state.surname, this.props.enableSave, this.props.disableSave, this.props.saveButton)}

        </Card>
      );
    }
    else return (
      <Card style={{ marginTop:21, fontWeight: 'bold',fontSize: 16, border: '1px solid rgb(34, 148, 144)', borderRadius: 15, textAlign: 'center', width: '98%' , overflow: 'auto', margin:  "-5% 1% 1%"  }} >
      "Thank You"
      </Card>
    );
  }
  }

const request = async (clientId, firstNames, surname, idNumber, city, country_name, signature, calledFrom, checked) => {
  const accessToken = localStorage.getItem('token');
  const poaSteps = {
    firstNames: firstNames,
    surname: surname,
    idNumber: idNumber,
    city: city,
    country_name: country_name,
    signature: signature,
    date: moment().format('Do MMMM YYYY'),
    accountName: '',
    bank: '',
    bankAccount: ''
  }
  if (!clientId) {
    //We need to create a credit life application if we don't have one
    // put this here to have code run ok
    clientId = '5b1131f603105c8cee41c03d'
  }

  const response = await fetch(`${API_URL}client/${clientId}/pdf/poa`, { method: 'post', body: JSON.stringify(poaSteps), headers: { 'Content-Type':'application/json', 'Authorization': accessToken ? `JWT ${accessToken}` : null } })
  // save file if checkbox checked
  if (calledFrom != 'moku' && checked) {
    const data = await response.blob()
    const file = await fileDownload(data, `POA-${idNumber}.pdf`)
    //const file = await fileDownload(data, `POA-${idNumber}.htm`)
  }
}
