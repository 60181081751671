
const random = (array) => {
  if (array && array.length > 0) {
    let luckyOne = Math.floor(Math.random() * array.length)
    return array[luckyOne]
  }
  return 'None'
}

const randomCellNumber = () => {
  return random(cellNumbers)
}

const randomStreet = () => {
  let address = random(streets)
  return (address.street + ' ' + address.city + ' ' + address.zip).toUpperCase()
}

const randomCompany = () => {
  return random(companies).toUpperCase()
}


const randomAccount = () => {
  return random(accounts).toUpperCase()
}

const companies = [
  'RCS GROUP',
  'CHILL BEVERAGES INTERNATIONAL',
  'SOUTH AFRICAN POLICE SERVICE SERVICES',
  'MOGALAKWENA PLATINUM MINE',
  'JOHN ALBERT TRUST',
  'Wimpy',
  'ANDRU MINING',
  'CAPE MEDICAL PLAN',
  'DECTI DOCUMENTS SERVICE',
  'DIHLABENG HOSPITAL',
  'LIMPOPO SOCIAL DEVELOPMENT',
  'SELF EMPLOYED',
  'EDEN SECIURITY',
  'KATO RESTURANT',
  'IMVULA SECURITY',
  'Servest',
  'CHRIS HANI NURSING COLLEGE',
  'Department of Health',
  'DEPARTMENT OF HEALTH AND SOCIAL SERVICES',
  'DEPARTMENT OF HOME AFFAIRS',
  'SOUTH AFRICAN NATIONAL DEFENCE FORCE',
  'LABOUR',
  'SOS Security',
  'INDICO RISK SERVICES',
  'self employed',
  'EDENDALE INDEPENDENT SCHOOL',
  'SOUTH AFRICAN POLICE SERVICE',
  'DEPARTMENT OF SOCIAL SERVICES',
  'SUPABETS',
  'HESTONY TRANSPORT',
  'XSTRATA ALLOYS LION FER',
  'LODGE SECURITY',
  'ST MARYS MISSION HOS',
  'department of education',
  'Nordbak',
  'DURRHEIM',
  'BAKERY',
  'CORTECH ENGINEERING',
  'CAPDEN',
  'Whikner Transaport',
  'JOY GLOBAL',
  'ATLAS FINANCE',
  'GQ TISSUE PRODUCTS',
  'REIDWOOD',
  'Tiptop print ',
  'CORE CATERING SUPPLIERS',
  'EPX COURIER SERVICES',
  'CAPITEC',
  'HITEACHI',
  'NHLOSO DEVELOPMENT CONSULTANTS',
  'ENSCO MINING',
  'SIZABANTU CAFFER',
  'LOFFIELAND CRECHE',
  'Sharwood Shipment Centre',
  'R WAVES AIRCONDITIONING',
  'PREMIER FOODS PTY LTD',
  'musa wenkosi christian academy',
  'SAPS',
  'Mbumbulu Policestation ',
  'TRANSNET ENGINEERING',
  'DEPARTMENT OF  EDUCATION  CULTURE',
  'DISCHEM',
  'INCREDIBLE CONNECTION',
  'C J RANCE',
  'JJ INSTALLATIONS',
  'ZARA RETAIL',
  'Ethekwini municipality',
  'PHOENIX',
  'PINNACLE',
  'RIGHT TO CARE HSS',
  'SUN INTERNATIONAL',
  'VEA ROAD MAINTANANCE',
  'QUNTUM SAILS',
  'PEP A DIVISION OF PEPKOR RETAIL',
  'BIDVEST PROTEA COIN  PTY  LTD',
  'SPORTSCENE',
  'FOOTGEAR',
  'FOSCHINI',
  'NESS CONSULTING',
  'HARDING GROUP',
  'VECTOR LOGISTICS',
  'MICROMATICA171CC T A MOKKI',
  'FUTURE CELL',
  'POWERS N PHUTI',
  'PE SECURITY GUARDS C',
  'TRANSACTION PAYMENT',
  'SHOPRITE CHECKERS',
  'eskom',
  'RHEANE CLOTHING',
  'MELOMED HOSPITAL',
  'ACKERMANS A DIVISION OF PEPKOR',
  'THE GAUGE BOYZ',
  'CAPE TOWN METROPOLITAN MUNICIPALITY']

const cellNumbers = [
  '0604549334',
  '27721728818',
  '27732977801',
  '0742742166',
  '0606544277',
  '0843626166',
  '0733812034',
  '0839999243',
  '0727828117',
  '0626259672',
  '0727872422',
  '0717873824',
  '0727878879',
  '0817151536',
  '0716759110',
  '27712933147',
  '27630076393',
  '0721787024',
  '27838900071',
  '0818460474',
  '0767007672',
  '0784077357',
  '27720221351',
  '0641834586',
  '0727976606',
  '27721579483',
  '0716668223',
  '0790691436',
  '0738099533',
  '27828180339',
  '0711919553',
  '0826588666',
  '0766126420',
  '0815866369',
  '0822296252',
  '0795812605',
  '0782284835',
  '0762127167',
  '27794657734',
  '27765097996',
  '0644763232',
  '0716337561',
  '0766943094',
  '0717634568',
  '0826606882',
  '0730147021',
  '0795803139',
  '0715904432',
  '0731556867',
  '0738385555',
  '27810950288',
  '0607360788',
  '0714949323',
  '0736288791',
  '0842065505',
  '0825724833',
  '0810235095',
  '0735071491',
  '0813218437',
  '27611530435',
  '27815555796',
  '27737793309',
  '0727798888',
  '0798986050',
  '0736930788',
  '27796851544',
  '0733580961',
  '27828437148',
  '0712857374',
  '27745699646',
  '0633188684',
  '0824059816',
  '0760990042',
  '0723688967',
  '0604087379',
  '0718854716',
  '0814726621',
  '27835873085',
  '0825543767',
  '0823424180',
  '27735330569',
  '0742701382',
  '0833981369',
  '27605808521',
  '0718233084',
  '27825357369',
  '0836135091',
  '0712909243',
  '27788689708',
  '0649157594',
  '27733342452',
  '0663285550',
  '0622691768',
  '0716267660',
  '0722254182',
  '0658891483',
  '0642713530',
  '0739085380',
  '0782335090',
  '0711274803',
  '0664797505',
  '0785854821',
  '0735807720',
  '27734562609',
  '0629175985',
  '0618871790',
  '0731466872',
  '0785082437',
  '0608881782',
  '0740454227',
  '0789805770',
  '0722034967',
  '27627153771',
  '0788558755',
  '0737429218',
  '0738906157',
  '0611755390',
  '0839782934'
]

const streets = [
  { street: '109 HOUSE 10263', city: 'Cape Town', zip: '7750' },
  { street: '438 HUTINGTON', city: 'Mpumalanga', zip: '1246' },
  { street: '46 JAKARANDA STREET', city: '', zip: '7600' },
  { street: '187 bencon str', city: '', zip: '0186' },
  { street: 'STAND 446 ZONE 1', city: '', zip: '0600' },
  { street: '8 NORMAN CLOSE', city: '', zip: '7941' },
  { street: 'PO. Box 263', city: 'KZN', zip: '4390' },
  { street: 'BLOCK B6', city: '', zip: '1129' },
  { street: '609 nkosi street', city: '', zip: '1102' },
  { street: '37 SNELL STREET', city: '', zip: '7570' },
  { street: 'RAMAPATE STREET', city: '', zip: '0122' },
  { street: '23 MBINDA -Street', city: '', zip: '6201' },
  { street: 'FLAT6 JORDANIA', city: 'Bethlehem', zip: '9701' },
  { street: '496 MAMATSHA', city: '', zip: '0727' },
  { street: 'LOTUS RIVER', city: '', zip: '7941' },
  { street: '7431 SHARPVILLE STREET', city: '', zip: '7785' },
  { street: '219 PHOMOLONG', city: 'Pretoria', zip: '0125' },
  { street: '3903', city: 'JOHANNESBURG', zip: '2189' },
  {
    street: '879A MOTSWALEDI',
    city: 'Gauteng',
    zip: '1861'
  },
  { street: '939 ZONE 2', city: '', zip: '5326' },
  { street: '27 GABON STREET', city: '', zip: '7100' },
  { street: '13 BRAHAM FARM', city: 'Nelspruit', zip: '1300' },
  {
    street: 'lots 881- ezitolo Area ',
    city: 'RiCHmond',
    zip: '3780'
  }
]



const accounts = [
  'ABSA Credit Cards',
  'ABSA Revolving Loans',
  'African Bank Limited',
  'Barnetts(Consumer Friend)',
  'Bayport Financial Services',
  'Bruma Finance',
  'Capfin Consumer Friend',
  'Capitec Bank Limited',
  'Consumer Friend/Easton Berry',
  'DMC Debt Management Pty Ltd',
  'Donna Claire',
  'Edgars',
  'Finchoice',
  'FNB Credit Cards',
  'FNB Edgars Personal Loans',
  'FNB Personal,Student and Easy Plan Loans',
  'FNB Smart Spend Loans (NOT Smart Acc)',
  'Foschini',
  'Foschini',
  'Game',
  'Get Bucks (Pty) Ltd',
  'Home Choice',
  'HTN Attorneys - Bruma Finance',
  'Jdt Financial Services',
  'Jet',
  'Le Morgan Direct Marketing (Pty) Ltd',
  'Lewis Stores',
  'MFC-Division Of Nedbank Ltd',
  'Mr Price Group (Galaxy)',
  'Mr Price Mobile (Pty) Ltd',
  'Nedbank Mastercard',
  'Nedbank Personal Loans',
  'Nedbank',
  'Norman Bisset and Associates',
  'RCS Stores',
  'RCS Stores',
  'Real People',
  'Russels(Consumer Friend)',
  'SA Home Loans Trust',
  'Standard Bank Closed Bus Cur Accs and Loans',
  'Tenacity Financial Services (Ackermans)',
  'Tenacity Financial Services',
  'Truworths (Consumer Friend)',
  'Wesbank Toyota Financial Services',
  'Wesbank',
  'Woolworths Credit Card',
]





export {  random, randomAccount, randomCellNumber, randomStreet }