import React , { Component } from 'react'
import client from './client'
import gql from 'graphql-tag'


const createChatBotActivity = gql`
  mutation CreateChatBotActivity($stepId: String, $value: [KeyValueInput]) {
    trackChatbotActivity(stepId: $stepId, value: $value)
  }
`

const LoggingWrapper = ({ component, triggerNextStep, ...props }) => {
  return React.cloneElement(component, { ...props, triggerNextStep: ({ value, trigger }) => { logStepValue(props.step, value); triggerNextStep({ value, trigger }) } })
}


const logStepValue = (step, value) => {
  console.log(step.id, value)
  client.mutate({ mutation: createChatBotActivity, variables: { stepId: step.id, value: [{ key: "value", value: value ? value : "" }] } })
}

export const addLogging = (steps) => {
  let newSteps = []
  //console.log('steps are ', steps)
  steps.forEach(step => {
    let newStep = { ...step }
    if (newStep.component) {
      newStep.component = <LoggingWrapper component={step.component} />
    }
    if (newStep.options) {
      newStep.options = []
      step.options.forEach((option, index) => {
        let newOption = { ...option }
        newOption.trigger = (props) => {
          logStepValue(step, props.value)
          if (option.trigger instanceof Function) {
            return option.trigger(props)
          } else {
            return option.trigger
          }
        }
        newStep.options.push(newOption)
      })
    } else if (step.trigger) {
      newStep.trigger = (props) => {
        logStepValue(step, props.value)
        if (step.trigger instanceof Function) {
          return step.trigger(props)
        } else {
          return step.trigger
        }
      }
    }
    newSteps.push(newStep)
  })
  return newSteps
}

/* Code moved to graphql
export const parseData = (jsonData) => {
  const parsedData = JSON.parse(jsonData)

  console.log(parsedData[0])

  //console.log(parsedData[0].CC_RESULTS.EnqCC_DMATCHES)
  const matches = parsedData[0].CC_RESULTS.EnqCC_DMATCHES.map(match => (
    {"firstName" : match.NAME, "lastName":match.SURNAME, "deceasedDate":match.DECEASED_DATE, "country":match.COUNTRY_CODE}
  ))


  //console.log(parsedData[0].CC_RESULTS.EnqCC_ADDRESS)
  const addresses = parsedData[0].CC_RESULTS.EnqCC_ADDRESS.map(address => (
    { "addressLine1": address.LINE_1, "addressLine2": address.LINE_2, "postalCode": address.POSTAL_CODE }
  ))

  //console.log(parsedData[0].CC_RESULTS.EnqCC_TELEPHONE)
  const phones = parsedData[0].CC_RESULTS.EnqCC_TELEPHONE.map(phone => (
    { "telephone": phone.TEL_NUMBER, "phoneType": phone.TEL_NUMBER_TYPE }
  ))

  //console.log(parsedData[0].CC_RESULTS.EnqCC_EMPLOYER)
  const employers = parsedData[0].CC_RESULTS.EnqCC_EMPLOYER.map(employer => (
     { "employer": employer.EMP_NAME, "salaryFrequency": employer.SALARY_FREQ }
  ))

  return {matches, addresses, phones, employers}

}
*/