import React, { Component } from 'react';
import gql from 'graphql-tag'
import { Mutation } from 'react-apollo';
import ChatForm from '../src/ChatForm';
import { Row, Col, Spin } from 'antd';
import { meerkatLogo } from '../src/images/index.js';
import "antd/dist/antd.css"
import { ThemeProvider } from 'styled-components';
import { getAllUrlParams } from './calculations';
import { Mandates } from './mandates/wrapper';

import './App.css'

const theme = {
  fontFamily: 'Ariel, Lato, Open Sans',
  headerBgColor: '#fff',
  headerFontColor: '#229490',
  headerFontSize: '30px',
  botBubbleColor: '#229490',
  botFontColor: '#fff',
  userBubbleColor: '#000',
  userFontColor: '#fff'
};

class StepWrapper extends Component {
  constructor(props) {
    super(props)
    this.state = {
      done: false,
      value: ''
    }
  }

  triggerNextStep = ({ value }) => {
    this.setState({ done: true, value })
  }

  render() {
    const { children } = this.props
    const { done, value } = this.state

    const element = React.Children.only(children)
    const nextStep = element.props.triggerNextStep

    if (!done) {
      return React.cloneElement(element, { triggerNextStep: this.triggerNextStep })
    } else {
      return nextStep({ value })
    }
  }
}

class App extends Component {
  constructor() {
    super();
    this.state = {
      clientId:'',
      width: window.innerWidth
    };
  }

  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);

    // if e-sign then show mandate .. otherwise Moku
    const eSign = getAllUrlParams().esign

    if (eSign) {
      const clientId = getAllUrlParams().id
      //const clientId = "5b1131f603105c8cee41c03d"
    console.log('uuid stored')
      if (clientId) {
        this.setState({ clientId })
      }
    }
  }

  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };



  render() {

    const { width } = this.state;
    const isMobile = width < 900;

    // if e-sign then show mandate .. otherwise Moku
    const esign = getAllUrlParams().esign
    console.log('esign parameter is', esign)

    if (this.state.clientId != '') {
      console.log('do eSign for id ', this.state.clientId)
      //test id = "5b1131ec03105c8cee41c028"
      //const jwt = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VySWQiOiI1YTU3MWE2OGI1Mjc5ZjAwMjc4N2RiMjQiLCJ1dWlkIjoiZWM5MTRjMzEtNjhhMS00M2RiLTkzMjItNjM4YjE2Y2ZiNzYwIiwiZW1wbG95ZWVJZCI6IjVhNTc5NDk5MDM5YzY5YmJkYTA3YWRhOCIsInJvbGVzIjpbIk1BTkFHRVIiLCJHUkFQSElRTCJdfQ.Fc4JBtP2wIzlNC4BudgwRn3ZjP9d2NorLTABBDRqakg"
      const jwt = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VySWQiOiI1YTU3MWE2OGI1Mjc5ZjAwMjc4N2RiMjQiLCJ1dWlkIjoiNjZkYjA2ZjQtMTExNi00MWY4LTg2YmQtOTQwZmRmZDFlMDNlIiwiZW1wbG95ZWVJZCI6IjVhNTc5NDk5MDM5YzY5YmJkYTA3YWRhOCIsInJvbGVzIjpbIk1BTkFHRVIiLCJHUkFQSElRTCJdfQ.IvptM4HP-VSosxlmj1sk-guoqL0n8Fcgi6Du4X05MIE"
      localStorage.setItem('token', jwt)
      return <Mandates esign={esign.toLowerCase()} clientId={this.state.clientId} isMobile={isMobile} />

    }
    else {
      if (isMobile) {
        return (
          <ThemeProvider theme={theme}>
            <ChatForm />
          </ThemeProvider>
        );
      } else {
        return (
          <div >
            <section id="logo" style={{ marginTop: -21, marginBottom: 7 }}>
              <a href="https://meerkat.co.za" title="Cape Town&#039;s Best Debt Counsellors - Meerkat, we&#039;re looking out for you!"><img src={meerkatLogo} alt="Cape Town&#039;s Best Debt Counsellors - Meerkat, we&#039;re looking out for you!" /></a>
            </section>


            <div className="kw-wrapper">
              <div className="container">
                <Row>
                  <Col span={14} style={{ paddingRight: 7 }}>

                    <div className="kw-title" id="content">
                      <h1>Simple, easy and intuitive</h1>
                      <p>Moku is here to help start you on your journey to financial freedom.</p>
                    </div>

                    <div className="kw-chat-wrap clearfix">
                      <div className="kw-chat-text">
                        <h6>Let's have a chat.</h6>
                        <p>
                          I will help you through the whole process of becoming debt free. Let's start chatting by selecting the "Get Started" button on the right in the phone.
                            </p>
                      </div>
                      <div className="kw-bear-img" style={{ marginLeft: -60 }} >
                        <img src="assets/images/bear.png" alt="bear" />
                      </div>
                    </div>

                    <div className="kw-bottom-text"  >
                      <h2>Who is Meerkat?</h2>
                      <p>Did you know that over 9 million South Africans are in arrears with their debt? Many are unsure of where to get help and can be uncomfortable chatting to someone about their finances. That is why Meerkat has developed Moku; a fun, engaging character who can determine if you are over-indebted or if your credit profile is not looking so great.</p>
                    </div>
                  </Col>
                  <Col span={10}>
                    <div className="kw-device-img" >
                      <div id='root' className="bot_content fcb_toggle_bot">
                        <ThemeProvider theme={theme}>
                          <ChatForm />
                        </ThemeProvider>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>

            <footer id="footer">

              <div class="footer_info">


                <div class="copyright">© Copyright MyMeerkat (PTY) Ltd. All Rights Reserved. Meerkat is an authorized financial services provider (FSP 46535).
            </div>

              </div>

            </footer>


          </div>

        );
      }
    }
  }
}

export default App;