import moment from 'moment';

const maximumEarningsThreshold = 14872
const uifRate = 0.01


export const getAllUrlParams = (url) => {

  // get query string from url (optional) or window
  var queryString = url ? url.split('?')[1] : window.location.search.slice(1);

  // we'll store the parameters here
  var obj = {};

  // if query string exists
  if (queryString) {

    // stuff after # is not part of query string, so get rid of it
    queryString = queryString.split('#')[0];

    // split our query string into its component parts
    var arr = queryString.split('&');

    for (var i=0; i<arr.length; i++) {
      // separate the keys and the values
      var a = arr[i].split('=');

      // in case params look like: list[]=thing1&list[]=thing2
      var paramNum = undefined;
      var paramName = a[0].replace(/\[\d*\]/, function(v) {
        paramNum = v.slice(1,-1);
        return '';
      });

      // set parameter value (use 'true' if empty)
      var paramValue = typeof(a[1])==='undefined' ? true : a[1];

      // (optional) keep case consistent
      paramName = paramName.toLowerCase();
      paramValue = paramValue.toLowerCase();

      // if parameter name already exists
      if (obj[paramName]) {
        // convert value to array (if still string)
        if (typeof obj[paramName] === 'string') {
          obj[paramName] = [obj[paramName]];
        }
        // if no array index number specified...
        if (typeof paramNum === 'undefined') {
          // put the value on the end of the array
          obj[paramName].push(paramValue);
        }
        // if array index number specified...
        else {
          // put the value at that index number
          obj[paramName][paramNum] = paramValue;
        }
      }
      // if param name doesn't exist yet, set it
      else {
        obj[paramName] = paramValue;
      }
    }
  }
console.log('getAllUrlParams ', obj)
  return obj;
}

export const age = idNumber => {
  console.log('ID is ' + idNumber);
  if (idNumber) {
    const DOBFromIdNumber = moment(`19${idNumber.slice(0, 6)}`, 'YYYY MM DD');
    const currentAgeFromDOB = moment().diff(DOBFromIdNumber, 'years');
    console.log('Age is ' + currentAgeFromDOB);
    return currentAgeFromDOB;
  }
  return undefined;
};

const taxTable = [
  { start: 75750, end: 189880, rate: 0.18, previousTax: 0 },
  { start: 189881, end: 296540, rate: 0.26, previousTax: 34178 },
  { start: 296541, end: 410460, rate: 0.31, previousTax: 61910 },
  { start: 410461, end: 555600, rate: 0.36, previousTax: 97225 },
  { start: 555601, end: 708310, rate: 0.39, previousTax: 149475 },
  { start: 708311, end: 1500000, rate: 0.41, previousTax: 209032 },
  { start: 1500001, end: 100000000, rate: 0.45, previousTax: 533625 }
]

const netDtiNorms = [
  {
    minumum: 0,
    maximum: 792,
    fixed_factor: 0,
    fixed_factor_percentage: 100
  },
  {
    minumum: 792.01,
    maximum: 6188,
    fixed_factor: 800,
    fixed_factor_percentage: 6.75
  },
  {
    minumum: 6188.01,
    maximum: 20887.7,
    fixed_factor: 1167.88,
    fixed_factor_percentage: 9
  },
  {
    minumum: 20887.8,
    maximum: 37237,
    fixed_factor: 2855.38,
    fixed_factor_percentage: 8.2
  },
  {
    minumum: 37237.01,
    maximum: 100000000,
    fixed_factor: 4905.38,
    fixed_factor_percentage: 6.75
  }
]


const uif = (salary, pensionFunding) => {

  if (salary > maximumEarningsThreshold) {
    return maximumEarningsThreshold * uifRate
  }
  return salary * uifRate
  }

const paye = (salary) => {
  var paye = 0
  taxTable.forEach(
    taxBand => {
      if (parseFloat(salary) >= parseFloat(taxBand.start) && parseFloat(salary) <= parseFloat(taxBand.end)) {
        paye = (salary * taxBand.rate) + taxBand.previousTax
      }
    })
  paye = paye / 12
  console.log('paye is ' + paye)
  return paye
}

//Gross Monthly Salary – UIF – PAYE – Pension% = Net Monthly Salary
// David says to treat salary net
export const netMonthlySalary = (salary, pension = 0) => {
  var annual = salary * 12
  var net = salary - uif(annual) - paye(annual) - (salary * pension)
  console.log('salary and net ' + salary + ' ' + net )

  return net
}

/*The total household expenses is the greater of:-
      1.	DTI Minimum Expense norms, and,
      2.	Predicted Expenses
*/
const predicated = (salary, dependants, age, married) => {
  return 582.95 + (0.72 * salary) + (70.3 * dependants) - (18.72 * age) - (431.32 * ( married ? 1 : 0))
}


export const totalHouseholdExpenses = (salary, expenses, dependants, age, married) => {
  var dti = 0
  var salaryNumber = Number(salary)

  netDtiNorms.forEach(
    netDti => {
      let start = Number(netDti.minumum)
      let end = Number(netDti.maximum)
      if ( salaryNumber >= start && salaryNumber <= end) {
        dti = ((salary * netDti.fixed_factor_percentage) / 100) + netDti.fixed_factor
        if (dti < netDti.fixed_factor) dti = netDti.fixed_factor
      }
    })
  var pe = predicated(salary, dependants, age, married)

  var estimatedexpenses = Math.max(dti, pe, expenses)
  console.log('dti, pe, expenses, total HouseHold expenses estimated ' + dti + ' ' + pe + ' ' + expenses + ' ' + estimatedexpenses)
  return estimatedexpenses
}

/*	Compuscan Full Bureau Enquiry
This data set is a requirement for the Compuscore and Affordability Services.  Once the data has been acquired it is stored in the database.

6.1	Inputs
Surname
First Name
ID Number

6.2	Model API Reference
The data is input into an external Compuscan API.

6.3	Outputs
Bureau Expenses
Compuscore
*/

const Potential_Benefit = (housing_debt, vehicle_debt, normal_debt) => {
  console.log('potential benefit ' + housing_debt + ' ' + vehicle_debt + ' ' + normal_debt)
  let potential_benefit = (housing_debt * 0.2) + (vehicle_debt * 0.3) + (normal_debt * 0.5)
  potential_benefit = Math.ceil(potential_benefit / 50.0) * 50
  if (potential_benefit == null || potential_benefit == 0 ) potential_benefit = 100
  return potential_benefit
}

/* If Monthly Affordability is positive, then it is a Pass.  If Monthly Affordability is negative, then it is a Fail. */
const affordability = (salary, expenses, dependants, age, married, bureauExpenses ) => {
  // Return true or false
  console.log('affordability check values are: Salary=  ' + salary + ' bureauExpenses= ' +   bureauExpenses)
  return (Math.floor((salary - totalHouseholdExpenses(salary, expenses, dependants, age, married) - bureauExpenses)/100) * 100) > 0
}

/* If Monthly Affordability is positive, then it is a Pass.  If Monthly Affordability is negative, then it is a Fail. */
const lowRisk = (compuScore ) => {
  // Return true or false
  return  compuScore > 620
}

const bureauExpenseCategorisation = [
{type:'C',	compscanCategory: 'Credit Card', meerkatGroup:	'Debt'},
{type:'D',	compscanCategory:	'Debt Recovery', meerkatGroup:	'Debt'},
{type:'E',	compscanCategory:	'Single credit Facility', meerkatGroup:	'Debt'},
{type:'G',	compscanCategory:	'Garage Card',  meerkatGroup:	'Debt'},
{type:'I',	compscanCategory:	'Instalment', meerkatGroup:	'Debt'},
{type:'M',	compscanCategory:	'One Month Unsecured Personal Loan', meerkatGroup:	'Debt'},
{type:'N',	compscanCategory:	'Secured / Policy Backed Loan', meerkatGroup:	'Debt'},
{type:'O',	compscanCategory:	'Open / Limitless Account', meerkatGroup:	'Debt'},
{type:'P',	compscanCategory:	'Personal Loans', meerkatGroup:	'Debt'},
{type:'R',	compscanCategory:	'Revolving - Store Cards', meerkatGroup:	'Debt'},
{type:'T',	compscanCategory:	'Student Loans', meerkatGroup:	'Debt'},
{type:'V',	compscanCategory:	'Overdrafts', meerkatGroup:	'Debt'},
{type:'Z',	compscanCategory:	'Revolving - Non Store Card', meerkatGroup:	'Debt'},
{type:'B',	compscanCategory:	'Building Loans	Housing', meerkatGroup: 'Debt'},
{type:'H',	compscanCategory:	'Home Loans	Housing', meerkatGroup: 'Debt'},
{type:'L',	compscanCategory:	'Life Insurance	Life', meerkatGroup: 'Insurance'},
{type:'F',	compscanCategory:	'Open Services	Living', meerkatGroup: 'Expenses'},
{type:'U',	compscanCategory:	'Utility	Living', meerkatGroup: 'Expenses'},
{type:'W',	compscanCategory:	'Rentals: Assets	Living', meerkatGroup: 'Expenses'},
{type:'X',	compscanCategory:	'Rentals: Property	Living', meerkatGroup: 'Expenses'},
{type:'S',	compscanCategory:	'Short Term Insurance	Short Term', meerkatGroup: 'Insurance'},
{type:'Y',	compscanCategory:	'Vehicle and Asset Finance	Vehicle', meerkatGroup: 'Debt'}
]


export function removeDuplicates(arr, prop) {
  console.log('remove dupllicates ' , arr + ' prop ' + prop )
  let obj = {};
  return Object.keys(arr.reduce((prev, next) => {
    if(!obj[next[prop]]) obj[next[prop]] = next;
    return obj;
  }, obj)).map((i) => obj[i]);
}

export function shuffle(array, prop) {

  array.map(arr =>console.log('array passed ',  arr + ' prop ' + prop) )

  //  remove duplicates
  let newArray = array

  newArray.map(arr =>console.log('array after duplicates ',  arr + ' prop ' + prop) )

  var currentIndex = newArray.length, temporaryValue, randomIndex

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1

    // And swap it with the current element.
    temporaryValue = newArray[currentIndex]
    newArray[currentIndex] = newArray[randomIndex]
    newArray[randomIndex] = temporaryValue
  }
  return newArray
}



export {affordability, lowRisk, Potential_Benefit }