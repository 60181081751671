import I18n from 'i18n-js';

import af from './locales/af';
import en from './locales/en';
import so from './locales/so';
import ve from './locales/ve';
import xh from './locales/xh';
import zu from './locales/zu';

I18n.fallbacks = true

I18n.defaultLocale = "en-US";
I18n.locale = "en-US";

I18n.translations = {
  en: en,
  af: af,
  so: so,
  ve: ve,
  xh: xh,
  zu: zu
};

export const setLocale = (locale) => {
  I18n.locale = locale
}

export default I18n