import React, { Component } from 'react';
import ChatBot from 'react-simple-chatbot';
import { botLogo } from '../src/images/index.js';
import { getAllSteps } from './steps'
import { Moku} from './customComponents';
import I18n from './strings/i18n'
class ChatForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      language: "",
      location:""
    }
  }

  startChatBot = ({ value }) => {
    this.setState(value)
  }

  render() {

    return (
      <div>
        <Moku triggerNextStep={this.startChatBot} />

        {this.state.language != "" &&
          <ChatBot
            botAvatar={botLogo}
            hideHeader
            floating={false}
            hideUserAvatar
          enableMobileAutoFocus={true}
            userDelay="200"
            botDelay="400"
            width="100%"
            height="100%"
            style={{
              textAlign: 'center',
              backgroundColor: "white",
              width: "100%",
              height: "100%",
              position: "relative"
            }}
            inputStyle={{
              color: '#229490',
              height: '48px',
              marginLeft: '3px',
              paddingLeft: '10px',
              outline: 'none',
              borderRadius: '10px',
              border: '2px solid #229490',
              width: '99%'
            }}
            bubbleOptionStyle={{
              background: 'white',
              display: 'flex',
              position: 'relative',
              minHeight: '25px',
              minWidth: '78px',
              margin: '3px',
              whiteSpace: 'normal',
              color: '#229490',
              backgroundColor: '#FFFFFF',
              border: '2px #229490 solid',
              borderRadius: '15px',
              cursor: 'pointer',
              textAlign: 'center',
              justifyContent: 'center',
              fontSize: '1rem',
              lineHeight: 1,
              textDecoration: "none"
            }}
            bubbleStyle={{
              textAlign: 'left',
              maxWidth: '80%',
              borderRadius: "25px 25px 25px 25px",
              marginTop: "-1px",
              paddingRight: "14px",
              paddingLeft: "14px"
            }}
            contentStyle={{
              position: "relative",
              marginBottom:"14px"
            }}
            avatarStyle={{
              height: "40px",
              width: '46px',
              margin: 0,
              padding: 0
            }}
            handleEnd={() => localStorage.removeItem('token')}
            submitButtonStyle={{ textAlign: 'center', maxWidth: '70%' }}
            placeholder="Please type here"
            steps={getAllSteps({location:this.state.location})}
          />
        }
      </div>
    );
  }
}

export default ChatForm;
