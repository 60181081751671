const zu = {

  loan: {
  "Loan":"Kuhle, ake sibone ukuthi yini esingakwenzela yona emnyangweni wezemalimboleko!",
  "Loan2":"Uyafaneleka ukuthi uthole imalimboleko ukuya ngalezi zici ezimbili - ubungozi Nokukwazi ukukhokha.",
  "Loan3":"Ubungozi bukalwa ngezinga lesikweletu sakho. Sibheka izikweletu onazo,ukweqisa, imali engakhokhiwe noma izahlulelo onazo. Izinga eliphansi libi. Izinga eliphezulu lihle.",
  "LoanAffordability":"Ukukwazi ukukhokha umali yomhholo esalayo ngemva kwezindleko zakho. Bonke anikezela ngezikweletu bandlela yabo ababheka ukufaneleka kwabantu.Imiphumela yakho yiyo ebonisayo. Asibheke ukukwazi ukukhokha",
  "LoanPassFail":"Izinga lakho lesikweletu libonisa ubungozi obuncane kodwa isimo sakho sezimali sokukwazi ukukhokhela isikweletu onaso nesikweletu esisha siphansi. Ungase ungavunyelwa ukuba uthole isikweletu.",
  "LoanFailFail":"Izinga lakho lesikweletu liphansi futhi isimo sakho sezimali asikuvumeli ukuthi ukwazi ukukhokha isikweletu onaso noma isikweletu esisha. Ngiyaxolisa , kodwa ungase unganikezwa isikweletu.",
  "LoanFailPass":"Isimiso sakho sezimali siyavuma ukuthi ukhokhele isikweletu onaso nesikweletu esisha, kodwa izinga lakho lesikweletu libonisa unobungozi obukhulu. Ungase unganikezwa isikweletu noma ukhokhiswe amanani aphezulu entela.",
  "LoanPassPass": "Unezinga elihle lesikweletu futhi isimo sakho sezimali siyavuma ukuthi ukhokhele isikweletu onaso nesikweletu esisha. Siyakubongela! Ungase unikezwe isikweletu esengeziwe.",
  },
  dc: {
  otherServicesPrompt: "Ungathanda ukubheka amanye asevisi ethu?",
  "DC":"Uyakufanelekela ukuthola usizo ngezikweletu uma usebebza isikhathi esigcwele futhi unezikweletu ezingaphezu kwamandla akho. ",
  "DC2":"Umthetho uwukuthi unezikweletu ezingaphezu kwamandla akho uma imali yomhholo wakho engaphezi kwengxeye ngemva kwentela iya ekukhokheleni izikweletu. ",
  "saveYou1":"Kubonakala sengathi sikongele imali efikela ku",
  "saveYou2":"inyanga ngayinye. Ingabe omunye walabo engisebenza nabo angakufonela?",
  "DCFailPass":"Ngokuya kwalokho okwazi ukukukhokha, kubonakala sengathi ungakwzi ukukhokha izivumelwano zezikweletu zakho. Uma unobunzima inyanga ngayinye, ungase ufune ukubona ukuthi ingabe ungakwazi ukunciphisa imali oyikhokha njalo ngonyaka. ",
  "DCPassPass":"Ngokuya kwalokho okwazi ukukukhokha, kubonakala sengathi ungakwazi ukukhokha izivumelwano zezikweletu zakho inyanga ngayinye. Uma unobunzima inyanga ngayinye, ungase ufune ukubona ukuthi ingabe ungakwazi ukunciphisa imali oyikhokha njalo ngonyaka. ",
  "budgetTool":"Uma usakhathazekile ngesimo sakho sezimali, kungani ungayi ku www.meerkat.co.za ukuhlola uthuluzi lethu lokuhlela imali.",
  },
  cc: {
    "CreditCheck": "Ukwaziswa kwakho kwezikweletu yikho okutshela labo ababolekisayo ukuthi uyakufanelekela ukunikezwa isikweletu osibhalisele. ",
    "CreditCheck2": "Ukwaziswa kwakho kusekelwe esikweletini onaso manje, ukweqisa  noma izahlulelo onazo. Izinga eliphansi libi kanti izinga elephezulu lihle. ",
    "CreditCheckPassFail": "Ukwaziswa kwakho kuhle 😎 Siyakubongela! Ithi ngibheke ukukwazi kwakho ukukhokhela isikweletu?",
    "FailFail": "Ukwaziswa kwakho akukuhle 🙁 Ungase ube nenkinga yokukhokhela isikweletu.",
    "CreditCheckPassPass": " Ngokuya kwalokho okwazi ukukukhokha, kubonakala sengathi ungakwazi ukukhokha izivumelwano zezikweletu zakho inyanga ngayinye. Uma unobunzima inyanga ngayinye, ungase ufune ukubona ukuthi ingabe ungakwazi ukunciphisa imali oyikhokha njalo ngonyaka. ",
    "saveYou1": "Ngingakhona ukubuyisela imali engango R",
    "saveYou2": "Ungajabula na ma sikushayela ucingo?"
  },
  debtC: {
    "DebtConsolidation":"I-debt Consolidation kulapho uboleka imali ukuze ukhokhele izikweletu zakho.  Bese uba nesikweletu esisodwa okumelwe usikhokhele inyanga ngayinye. Kumelwe ukufaneleke ukuboleka imali. Nokho, i-debt consolidation ayikusizi ukuba inciphise izikweletu zakho.",
    "DebtC2":"I-debt counselling ifana ne debt consolidation kodwa awuboleki imali. Kunalokho imali oyikhokha nyanga zonke iyancishiswa ibe isikweletu esisodwa futhi ngokuvamille intela iyancishiswa. Ngokungafani ne- debt consolidation lapho amanani entela angase abe phezulu",
  },
  cl: {
    "cl":"Great! Most of your loans and accounts have credit life insurance included as part of your premium. We might be able to reduce this to a more affordable amount.",
    "yourDebt": `according to our records you have "X accounts with Rx of DEBT.`,
    "cl2": `Normally we can save our customers on replacements. Putting more money back in your pocket.`,
    "clHelp": `Would you like us to have a look at this for you?`,
    "clNeedSig": "Thanks! I just need your permission for us to contact your credit providers on your behalf to confirm the actual amount of insurance you are paying.",
    "SmokerYes": "Yes, I smoke",
    "SmokerNo":"No, I don't"
  },
  intro: 'Sawubona 👋 ngingu Moku, umsizi wakho werobhothi. Ngilana nje ukukusiza upheme ezikweletini.',
  "intro_2":"Uma unginikeza ukwaziswa okuthile ngingakwazi ukubona ukuthi isimo sakho sezikweletu sibi noma sikahle, ukuthi uyakufanelekela ukuthola imbalimboleko noma ukuthi ingazuza enqubweni yethu yokuhlolelwa izikweletu.",
  "start": "Ungathanda ukuqhubeka?",
  "GetStarted":"Qalisa",
  "first":"Ubani igama lakho?",
  "firstName":"'ngicela ubhale igama lakho",
  "last":"Sithini isibongo sakho?",
  "Nice":"Ngijabulile ukukwazi",
  "AreUaCitizen":"! Ngaphambi kokuba siqale , ngingathanda ukuqinisekisa ukuthi ingabe unomazisi waseNingizimu Afrika?",
  "soSorry":"Ngiyaxolisa",
  "notSA":"okwamanje sisiza kuphela abantu abayizakhamuzi zaseNingizimu Afrika",
  "smile":"Lokho akusho ukuthi ngeke ngingakujabulisi. Ufuna ukubona into enhle?",
  "menu":"Ungathanda ngikwenzeleni namuhla",
  "letMeSee":"Ake ngibone ukuthi ngingakwenzelani",
  "needPermission":"Ngidinga imvume yakho ukuze ngibuthe ukwaziswa okudingekayo, ngakho ngicela wamukele Imigomo Nembibandela yethu. Ungakwazi ukuyifunda ngokuchofoza kuyo ?",
  "anywayCanWeCall":"Ok, kulungile. Ingabe ufuna ngithole umsizi ongumuntu wangempela akushayele ucingo?",
  "anywayCall":"Ok. Ngicela uqinisekise inombolo yakho yocingo?",
  "thanksanyway":"Ngiyabonga! Ngithumele imininingwane yakho emnyangweni wezingcingo. Omunye walabo engisebenza nabo uzokufonela maduzane!",
  "pity":"Kuyadabukisa",
  "getting":"Bengimane nje ngizama ukukwazi. Vakashela u- www.meerkat.co.udinga ukwaziswa okwengeziwe.",
  "ThankYou":"Ngiyabonga",
  "confirm":"Ingabe ungaba nenkinga nokuthi ungiqinisekile inombolo yakho yocingo?",
  "invalidCell":"Inombolo yakho yocingo ayisebenzi",
  "enterCell":"ngicela ubhale inombolo yakho yocingo",
  "working":"Kuhle 👍. Lokhu kungase kuzwakale kuyindaba yomuntu siqu, kodwa ngidinga ukukubuza ukuze ngiqiniseke ukuthi ngingakwazi ukukusiza. Uyasebenza?",
  "otherIncome":"Ikhona enye indlela othola ngayo umhholo njalo?",
  "sorry":"Oohh, siyaxolisa. Sisiza kuphela abantu abathola umhholo njalo. Sicela uvakashele u www.meerkat.co.za ukuze uthole ukwaziswa okwengeziwe ngezikweletu.",
  "incomeDetails":"Ok, kuhle. Uthola imali engakanani ekhaya lakho inyanga ngayinye?",
  "income2":"Uma ukhona, umhholo wakho newomlingani wakho?",
  "number":"Ngicela ufake inombolo",
  "justNumber":"Ngicela umane ufake nje inombolo",
  "idPrompt":"Ngicela ufake inombilo kamazisi?",
  "idNumberPrompt":"Ok, ngicela ufake inombolo kamazisi",
  "marriedPrompt":"Ngingathanda ukubuza ukuthi ingabe ushadile noma ukhona umuntu ohlala naye? Lokho kwaziswa kuzongisiza ngikusize kahle.",
  "dependentsPrompt":"Uhlala nabantu abangaki ekhayala lakho noma (kuhlanganise umlingani wakho nezihlobo) abanakekelwa nguwe?",
  "expensesPrompt":"Ngicela ungazise izindleko zakho zanyanga zonke, ngokwesilinganiso?",
  "emailPrompt":"Ngaphambi kokuba senze ukuhlola kwethu, ungathanda ukuthola ikhophi yombiko wezikweletu zakho?",
  "getEmail":"Lithini ikheli lakho le-email ?",
  "noEmail":"Ngicela ufake i-email yakho",
  "invalidEmail":"Ikheli le-email alisebenzi",
  "challenge1":"Kudingeka sihlole ukwaziswa kwakho ngaphambi kokuba sikhiphe lokhu kwaziswa . Sicela uphendule imibuzo elandelayo.",
  "passedValidaton":"Kuhle, uphumelele kahle! Sengithumela umbiko manje.",
  "SendMsg":"sengiwuthumele umbiko, nawu umbiko obukeziwe",
  "verificationFailed":"Ngiyaxolisa, awuphumelelanga ekuhlolweni, ngakho ngeke ngikwazi ukukuthumela nge-email umbiko kodwa  ngisengakwenza ukuhlola.",
  "saveBureau1":"Okunye okungasiza , inqubo yethu yokusizwa ngezikweletu. Ngingakwazi ukukusiza ukuba ugcine imali engaba ngu ",
  "saveBureau2":"inyanga ngayinye, njengoba ngimusha kulolu hlelo lokuxoxisana omunye wabasebenzi angakufonela?",
  "youSure1":"Ngempela? Awufuni ukugcina imali ",
  "youSure2":"  inyanga ngayinye?`",
  "so":"Ngakho,",
  "callYou":"ingabe omunye wabantu engisebenza nabo angakufonela?",
  "checkNumber":"Ngingakufonela ku",
  "newNumber":"Ithini inombolo yakho kamakhalekhukhwini?",
  "timeOfDay":"Sikufonele ngasiphi isikhathi sosuku?",
  "thanks":"Kuhle! Omunye walabo engisebenza nabo uzothintana nawe.",
  //"working":"uyasebenza?'",
  "sorry1":"Ngiyaxolisa  ",
  "sorry2":"Kodwa okwamanje sisiza kuphela labo abasebenzayo",
  "bandMessage":"Mina nabafowethu sineqembu lomculo. Izinsuku zokucula zizovezwa maduzane 😉. Iba nosuku oluhle!",
  "okThen":"Kulungile",
  "eMeet":"Kube kuhle ukwazana nawe nge inthanethi",
  "saveEnd":"😀 Kube kuhle ukwazana nawe nge inthanethi!",
  "errorEnd":"🙈 Ngiyaxolisa kodwa kubonakala sengathi izingxenye ezithile ezingaphakathi kimi ziyalungiswa.",
  "callAnyway":"Ungathanda ukuthi omunye wabasebenza nami akufonele ukuze ukuqhubekela phambili nalengxoxo?",
  "saveLeadError":"🙈  Ngiyaxolisa kodwa kubonakala sengathi izingxenye ezithile ezingaphakathi kimi ziyalungiswa. Ngicela uphinde ubuye. ",
  "comeAgain":"Uma ufuna ukuphinde uxoxe,ungangithola lana,noma , ungangifonela ku-021 003 4535",
  "byeBye":"Sala kahle",
  "whatHelpPrompt":"Ngakho, ungathanda ukusizwa ngani?",
  "yesPlease": "Yebo ngyacela",
  "noThanks": "Cha ngyabonga",
  "keepChattingYes": "Ngingajabulela ukhuluma nawe futhi",
  "keepChattingNo": "Ngicela ukushayelwa ucingo",
  "invalidID": "Incwadi yesazisi ",
  "Yes":"yebo",
  "No":"Cha",
  "Married":"Ngishadile",
  "Single":"Angishadile",
  "LiveTogether":"Sihlala ndawonye",
  "Divorced":"Isehlukaniso",
  "Separated":"Ukuhlala ngokwahlukana",
  "Now":"Manje",
  "Afternoon":"Emini",
  "Morning":"Ekuseni",
  "Evening":"Ntambama",
  "Thanks":"Ngiyabonga",
  "occupationPmt": "What is your occupation?",
  "occupation": "Please enter your occuptation",
  "employerPmt":"Where are you employed (name of employer)?",
  "employer": "Please enter your employer?",
  'menuLoan': "Isikweletu",
  'menuDC': 'Ukululekwa kwezikweletu',
  'menuCC': 'Ukubuka Kwezikweletu',
  'menuDConsolidate': 'Ukuhlanganiswa Kwesikweletu',
  'menuCLI':'I-Cheaper Insurance on Loans',
  'placeHolder':'Sicela uthayiphe lapha',
  'accept': 'Yamukela',
  'cancel':'Khansela',
  'selfEmployed': 'Self Employed', 'partTime': 'Part-Time', 'fullTime': 'Fully Employed',
  'employerTypePmt':'How are you employed?'

}

export default zu