import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
//import SignaturePad from 'react-signature-pad-wrapper';
import { Alert, Button, message, Card, Checkbox, Spin} from 'antd';
import 'antd/lib/button/style/css';
import 'antd/lib/message/style/css';
import moment from 'moment';
import { meerkatHeader, meerkatFooter, dobSignature } from '../base64';
import { API_URL } from '../config';
import fileDownload from 'js-file-download'
import SignatureCanvas from 'react-signature-canvas';

const statusMessage = message;
statusMessage.config({ top: '80%', right: '20%' });

export class TERMS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disableSave: true,
      location: this.props.from == 'moku' ? this.props.location : this.props.steps.location,
      clientId: this.props.clientId || '',
      firstNames: this.props.from == 'moku' ? this.props.steps && this.props.steps.firstName.value : this.props.steps.firstNames ,
      surname: this.props.from == 'moku' ?  this.props.steps && this.props.steps.lastName.value : this.props.steps.surname,
      idNumber: this.props.from == 'moku' ?  this.props.steps && this.props.steps.idNumber.value : this.props.steps.idNumber,
      showSpinner: false,
      alertMessage: 'Please wait ...',
      alertTitle: 'Creating pdf file',
      fromDataURL: '',
      notDone: true,
      checked:false
    };
    this.clicked = false;
  }

  cancelButton = () => {
    if (!this.clicked) {
      this.clicked = true;
      this.hideModal();
      console.log('cancel clicked');
      setTimeout(() => {
        this.props.triggerNextStep({ value: 'no', trigger: 'youSureYesNo' });
      }, 100);
    }
  };


  saveButton = () => {
    console.log('save Button was clicked, signaturepad is  ', this.signaturePad + ' and clicked is ', this.clicked)

    if (this.signaturePad && !this.clicked) {
      console.log('save clicked')
      if (!this.signaturePad.isEmpty()) {
        this.setState({ disableSave: true });
        if (!this.clicked) {
          this.clicked = true;
          // save signature
          let signature = this.signaturePad.getTrimmedCanvas().toDataURL('image/png');
          console.log('signature is  ', signature)

          this.setState({ showSpinner: true, alertTitle: 'Saving pdf file' });

          request(this.state.clientId, this.state.firstNames, this.state.surname, this.state.idNumber, this.state.location.city, this.state.location.country_name, signature, this.props.from, this.state.checked)
            .then ((response) => {
              console.log('create T&C pdf  returned ', response);
              this.setState({ showSpinner: false });

          if (this.props.from == 'moku') {
            setTimeout(() => {
              this.props.triggerNextStep({
                value: signature,
                trigger: 'clSigned'
              });
            }, 100);
          }
          this.setState({ notDone : false})
          console.log('save clicked & signature ');
        })
        }
      } else {
        statusMessage.error('Please sign in the box', 5);
      }
    }
  };


  saveSig = () => {
    this.setState({ fromDataURL: this.signaturePad.getTrimmedCanvas().toDataURL('image/png') });

    console.log('enable save ', this.state.disableSave)
    if (this.state.disableSave) {
      console.log('enable save')
      this.setState({ disableSave: false });
    }
  };

  onCheckChange = (e) => {
    console.log('checked ', e.target.checked)
    this.setState({
      checked: e.target.checked,
    });
  }

  poaBody(firstName, lastName) {

    return (
      <div>
        <div style={{ marginTop: 14, marginBottom: 14, textAlign: 'left', fontSize: 10, marginLeft: '10%' }}>

          <div style={{ border: '1px solid #229490', borderRadius: '10px', backgroundColor: '#F2F6FC', PADDING: 3, width: 285, height: 150 }}>
            <SignatureCanvas penColor='black'
              canvasProps={{ width: 250, height: 135, className: 'sigCanvas' }} ref={(ref) => { this.signaturePad = ref }} onEnd={this.saveSig} />
          </div>
          Signature, (
          {firstName}, {lastName})
          <br /><br />
          <Button
            id="clearButton"
            icon="delete"
            shape="round"
            onClick={() => this.signaturePad.clear()}
          >
            Clear{' '}
          </Button>
          <Button
            id="saveButton"
            type="primary"
            icon="check"
            shape="round"
            style={{ marginLeft: 7 }}
            disabled={this.state.disableSave}
            onClick={() => this.saveButton()}
          >
            Sign and Save{' '}
          </Button>
          <br/>
          <Checkbox style={{marginTop:14, marginLeft:7}} checked={this.state.checked} onChange={this.onCheckChange}>
            Download a copy of this document
          </Checkbox>

        </div>

        <div style={{ textAlign: 'left' }} dangerouslySetInnerHTML={meerkatFooter} />
      </div >
    )
  }

    poaText (firstName, lastName, idNumber, city, country_name) {
      return (
        <Fragment>
          <center>
            <div dangerouslySetInnerHTML={meerkatHeader} />
          </center>

          <div
            style={{
              marginLeft: '1%',
              marginRight: '1%',
              marginTop: 7,
              fontWeight: 400,
              fontSize:10
            }}
          >
          <div>
      <h2>Meerkat Terms and Conditions</h2>
    <br />
    <strong>Clauses in these Terms and Conditions that are of importance or that carry a level of risk for you are in bold. Please
      pay special attention to these clauses and make sure you understand them. If you don’t understand something please
      get us to explain it to you.
    </strong>
    <br />
    <br />

    <ol >
      <li>
        <strong>Definitions</strong>
        <ol>
          <li>
            <strong>“Acceptance Date”</strong> means the date on which you accepted the Agreement, by way of electronic medium, for
            example by clicking “I agree” on a web page or via your mobile phone or by a voice recorded call via our Call
            Centre;
          </li>
          <li>
            <strong>“Agreement”</strong> means the agreement concluded between you and Meerkat which agreement will be exclusively
            governed by these terms and conditions and the privacy policy and content submission terms;
          </li>
          <li>
            <strong>“Business Day”</strong> means Monday to Friday, but excludes Saturdays and a day which is an official public
            holiday in the Republic of South Africa;
          </li>
          <li>
            <strong>“Business Hours”</strong> means the hours between 08h00 and 17h00 on a Business Day;
          </li>
          <li>
            <strong>“ECT Act”</strong> means the Electronic Communications and Transactions Act, 2002;
          </li>
          <li>
            <strong>“Meerkat”</strong>,
            <strong>“MyMeerkat”</strong>,
            <strong>“we”</strong>,
            <strong>“us”</strong> and
            <strong>“our”</strong> means MyMeerkat (Proprietary) Limited;
          </li>
          <li>
            <strong>“Meerkat Marks”</strong> means any trademarks, logos, characters, brand names, trade names, domain names or other
            names or marks of Meerkat whether registered or not;
          </li>
          <li>
            <strong>“Intellectual Property Rights”</strong> means the copyright in any work in terms of the Copyright Act, No. 98
            of 1978, and includes without limitation the right to reproduce that work, the rights in respect of a trade mark
            conferred by the Trade Marks Act, No. 194 of 1993, the rights in respect of a design conferred by the Designs
            Act, No. 195 of 1993, and the rights in respect of a patent conferred by the Patents Act, No. 57 of 1978 including
            any applications for the aforegoing and any names, licenses, know how, trade secrets and data associated with
            the aforegoing;
          </li>
          <li>
            <strong>"Juristic Person"</strong> means a company or close corporation and includes a body corporate, partnership, association
            or trust;
          </li>
          <li>
            <strong>“Legal Notices Website”</strong> means http://www.meerkat.co.za/resources;
          </li>
          <li>
            <strong>“NCA”</strong> means the National Credit Act of 2005;
          </li>
          <li>
            <strong>“Third Party Service Provider”</strong> means a third party who provides services to Subscribers via the Meerkat
            website;
          </li>
          <li>
            <strong>“Service”</strong> means the financial advice and services provided by Meerkat;
          </li>
          <li>
            <strong>“Subscriber”</strong>,
            <strong>“you”</strong> or
            <strong>“Customer”</strong> means a user of any of our products or services;
          </li>
          <li>
            <strong>“Uncontrollable Event”</strong> means (including without limitation) any fire, flood, earthquake, elements of
            nature or acts of God, riots, civil disorders, rebellions or revolutions in any country or any other cause beyond
            the reasonable control of Meerkat including the termination or suspension of a service or product provided by
            a third party, that may result in a delay or a failure to provide any product or service; and
          </li>
          <li>
            <strong>“VAT”</strong> means Value Added Tax as provided for in the Value Added Tax Act, 1991.
          </li>
          <li>
            <strong>“Website”</strong> means www.meerkat.co.za, www.mymeerkat.com, www.mymeerkat.co.uk, www.mymeerkat.co.za and www.mkat.co:
          </li>
        </ol>
      </li>
      <li>
        <strong>Commencement, Duration, Termination and Cooling-off</strong>
        <ol>
          <li>The Agreement will commence on the Acceptance Date and endure indefinitely until it is cancelled as provided for
            in this Agreement.
          </li>
          <li>
            <strong>This Agreement incorporates by reference the Website Terms and Conditions and Privacy Policy which have been
              made available to you on this website and which you undertake to read and understand. If you do not understand
              anything, please ask us to explain it to you.</strong>
          </li>
          <li>Meerkat may terminate this Agreement immediately on notice to you for material breach of this Agreement.
          </li>
          <li> Notwithstanding the termination of the Agreement, in the event that you continue to use the products or services
            despite the termination of the Agreement, you will remain liable for and promptly pay on demand all amounts that
            would have been due to Meerkat as a result of the use of or access to the product or service and this Agreement
            shall be deemed to continue to apply until such time as all amounts due to Meerkat have been paid in full, and
            we will be entitled to terminate the agreement at any time.
          </li>
          <li>If the Agreement results from any direct approach to you by Meerkat or is an electronic transaction as contemplated
            in the ECT Act, you will be entitled to cancel the Agreement on written notice to Meerkat without reason or penalty
            within 5 (five) Business Days of
            <ol>
              <li>in the case of services only being provided in terms of the Agreement - the Acceptance Date; and </li>
              <li>in the case where goods are provided and constitute the subject of the Agreement, whether in conjunction with
                services or on its own – the date of delivery of such goods.</li>
            </ol>
          </li>
          <li>For purposes of clause 2.5.2, goods include any literature, music, photograph, motion picture, game, information,
            data software, code, calculators, models, business methodologies or other intangible product or any license to
            use such intangible product.
          </li>
        </ol>
      </li>
      <li>
        <strong>Conditions of access</strong>
        <ol>
          <li>Meerkat will make the service available to you on the Acceptance Date. </li>
          <li>Meerkat will, where relevant, issue a user name and password to you prior to the Acceptance Date in order to enable
            you to gain access to and/or use a service. In such instance, you will not be able to access and/or use a service
            without a user name and password.
          </li>
          <li>You agree that:
            <ol>
              <li>you will use your user name and password for your own personal use only; </li>
              <li>you will not disclose your user name and password to any other person for any reason whatsoever and that you
                will maintain the confidentiality thereof; </li>
              <li>in the event that your password is compromised, you will immediately notify Meerkat and change your password;
              </li>
              <li>
                <strong>you, as the holder of the user name and password, acknowledge you are solely responsible for all payments
                  in respect of a service charged to your Meerkat account, irrespective of whether the service has been utilized
                  or is being utilized by you or not and accordingly the entire amount outstanding on your Meerkat account
                  will be deemed to have arisen from (or relate to) your access to and/or use of a service;</strong>
              </li>
              <li>
                <strong>you agree to cause all persons who use any products or services under your account or with your authorization
                  to comply with the Agreement. All acts or omissions of all persons who use services under your account
                  or with your authorization will be treated for all purposes as your acts or omissions;</strong>
              </li>
              <li>Meerkat also offers a password reminder service. We will send your password to your registered email address
                or cell phone number should you have forgotten your password. Subscription to this service is voluntary.
                Password reminders will only be sent to your registered details and should such details change it is your
                responsibility to notify us. </li>
              <li>you will not, at any time, permit and/or initiate a simultaneous network log-in; and </li>
              <li>you will not attempt to circumvent Meerkat's user authentication processes or engage in attempts to access
                Meerkat's network where not expressly authorised to do so.</li>
            </ol>
          </li>
        </ol>
      </li>
      <li>
        <strong>Service Delivery, Service Availability</strong>
        <ol>
          <li>
            <strong>Meerkat will use reasonable endeavors to make its services available to its Subscribers, and to maintain the
              availability thereof for use by its Subscribers. However, we provide the services “as is” and “as available”
              and do not warrant or guarantee that the services will
              <u>at all times be free of errors or interruptions</u>, be always available, fit for any purpose,
              <u>not infringe any third party</u> rights, be secure and reliable.</strong>
          </li>
          <li>Meerkat will use its best endeavors to notify you in advance of any maintenance and repairs which may result in
            the unavailability of a service, but cannot always guarantee this. Furthermore, we cannot guarantee that the
            services will function properly on all mobile devices or that it will always be available due to network connectivity
            issues.
          </li>
          <li>
            <strong>Please note that we are an authorised Financial Services Provider in the Republic of South Africa. Our products
              and services are not designed for, and may not be suitable for any other country. We recommend that you ensure
              that use of the services is not in violation of the laws of any country by which you may be governed. Your
              use of our website for any purpose is entirely at your own risk.</strong>
          </li>
        </ol>
      </li>
      <li>
        <strong>Communication and Dispute Resolution</strong>
        <ol>
          <li>You agree that Meerkat may from time to time send you communications regarding (without being limited to) special
            offers or discounts which Meerkat may negotiate for and offer to its Subscribers, operational changes that may
            affect the services and/or new services or products launched. All communications will abide by our Privacy Policy
            and applicable law. You will always be entitled to notify us in writing that you do not wish to receive or continue
            to receive such communications and if you are a consumer as contemplated in the CPA, to pre-emptively block the
            receipt of such communications. </li>
          <li>Any dispute between the parties may be referred to arbitration and finally resolved in accordance with the rules
            of the Arbitration Foundation of Southern Africa. Such arbitration shall be held in Cape Town, and conducted
            in the English language before one arbitrator appointed in accordance with the said rules. Any award will be
            final and not subject to appeal. This agreement to arbitrate shall be enforceable in, and judgement upon any
            award may be entered in any court of any country having appropriate jurisdiction. A dispute shall be deemed to
            have arisen when either party notifies the other party in writing to that effect. </li>
          <li>
            The arbitrator shall have the power to give default judgement if any party fails to make submissions on due date and/or fails
            to appear at the arbitration.</li>
          <li>The provisions set out above shall not prevent either party from approaching any court of competent jurisdiction
            to obtain interim or other relief in cases of urgency.
          </li>
        </ol>
      </li>
      <li>
        <strong>Payment</strong>
        <ol>
          <li>Meerkat will receive remuneration of 3.25% (three point two five percent) of any monthly premium payable by you
            to OMART in exchange for services rendered.</li>
        </ol>
      </li>
      <li>
        <strong>Privacy and Credit Checks</strong>
        <ol>
          <li>Meerkat will deal with your personal information in accordance with the provisions of our Privacy Policy which
            is available on our http://www.meerkat.co.za/resources and in compliance with all relevant laws. </li>
          <li>Your application for a service or product may be subject to a credit referencing or risk assessment process. This
            means that Meerkat may request and receive your Confidential Information, Consumer Credit Information and Prescribed
            Information (as defined in the NCA) (“Assessment Information”) from registered credit bureaus in order to perform
            a financial means assessment. </li>
          <li>Meerkat is entitled to perform this financial means test each time you apply for a service or product and at any
            time during the existence of any service with us or to assess your credit worthiness at a later time.
          </li>
          <li>In this regard you consent to Meerkat requesting, receiving and reporting your Assessment Information from and
            to registered credit bureaus in accordance with the provisions and for the purposes of the NCA; and the sharing
            of such Information by registered credit bureaus and such other persons as contemplated in the NCA, for the prescribed
            purposes of the NCA. </li>
          <li>Should your application be as a result of a referral by a debt counsellor, you consent to Meerkat requesting, receiving
            and reporting your Assessment Information from and to registered debt counsellors in accordance with our Privacy
            Policy. </li>
        </ol>
      </li>
      <li>
        <strong>Intellectual Property Rights</strong>
        <ol>
          <li>You agree to comply with all laws applicable to any Intellectual Property Rights in respect of any data, files
            and/or information accessed, retrieved or stored by you through your use of any of our services and/products.
          </li>
          <li> You are prohibited from using any Meerkat Marks without the prior written approval of Meerkat. </li>
        </ol>
      </li>
      <li>
        <strong>Intermediary Appointment</strong>
        <ol>
          <li>I confirm the engagement of MyMeerkat (Pty) Ltd., and its representatives, duly authorised, as my Financial Advisor.
          </li>
          <li>I understand and acknowledge:
            <ol>
              <li> that advice received may entitle MyMeerkat (Pty) Ltd. to any future review fees and/or commissions that may
                be payable under my various investments and/or policies.
              </li>
            </ol>
          </li>
          <li>that such appointment will be limited to:
            <ol>
              <li>Product categories for which MyMeerkat (Pty) Ltd. is licensed with the Financial Services Board, and </li>
              <li>Companies with whom MyMeerkat (Pty) Ltd. has concluded Intermediary Agreements.</li>
            </ol>
          </li>
        </ol>
      </li>
      <li>
        <strong>FAIS Disclosures</strong>
        <ol>
          <li>The business name is MyMeerkat (Pty) Ltd. </li>
          <li>The company registration number: 2015/102401/07.</li>
          <li>MyMeerkat (Pty) Ltd. is an authorised financial services provider, licensed by the FSB (Financial Services Board)
            under the following categories
            <ul>
              <li>Long-Term Insurance: Category A</li>
              <li>Short-Term Insurance: Personal Lines</li>
              <li>Long-Term Insurance: Category B</li>
              <li>Long-Term Insurance: Category C</li>
              <li>Retail Pension Benefits</li>
              <li>Short-Term Insurance: Commercial Lines</li>
              <li>Pension Fund Benefits (excluding Retail Pension Benefits)</li>
              <li>Participatory Interests in one or more Collective Investment Schemes</li>
              <li>Deposits as defined in the Banks Act – exceeding 12 months</li>
              <li>Deposits as defined in the Banks Act – 12 months or less</li>
            </ul>
          </li>
          <li>FSP Number: 46535.</li>
          <li>Registered Address: 203 Pine Park, 1 Logan Way, Pinelands, Cape Town, 7450. </li>
          <li>Physical and Postal Address: 203 Pine Park, 1 Logan Way, Pinelands, Cape Town, 7450.</li>
          <li>Website: https://www.meerkat.co.za </li>
          <li>Tel. +27 (021) 003 4535. </li>
          <li>If you are not satisfied with any aspects of your policy or services provided by Meerkat on behalf of OMART, please
            contact us on: complaints@meerkat.co.za</li>
          <li>The Meerkat Compliance Officer is Anzel Jewaskiewitz of Masthead (Pty) Ltd, Telephone 021 555 4121, ajewaskiewitz@masthead.co.za.
            The Compliance Officer deals with issues relating to Meerkat’s compliance with the Financial Advisory and Intermediary
            Services Act, 37 of 2002 (FAIS Act). </li>
          <li>
            If you feel that Meerkat has contravened the provisions of the FAIS Act, and/or has rendered financial services in a manner
            that may affect you adversely, please contact us. Alternately, you may contact the FAIS Ombudsman: 012 470 9080
            / info@faisombud.co.za / www.faisombud.co.za. </li>
          <li>If you have a complaint linked to the insurance contract, you may contact the Ombudsman for Long-term Insurance.
            021 657 5000 / info@ombud.co.za / www.ombud.co.za. The Ombudsman is available for advice on complaints in respect
            of claims or any other matter which have not been satisfactorily resolved by Meerkat. </li>
          <li>Product Supplier: Old Mutual Alternative Risk Transfer Limited, a registered long-term insurer and Subscriber of
            the Old Mutual Group. </li>
          <li>Our consultants who do not meet the full Fit and Proper Requirements as defined by FAIS render services under management
            supervision. </li>
          <li>Meerkat has professional indemnity insurance. </li>
          <li>Meerkat has and maintains an Anti-Money Laundering Policy in accordance with the FAIS Act. </li>
          <li>Meerkat retains recordings of all interactions including telephone discussions. These will be made available to
            the client on request.</li>
          <li>No member of the Meerkat team holds more than 10% of the shares issued by any product provider. </li>
          <li>Meerkat received more than 30% of the last year’s commission and remuneration from Old Mutual Alternative Risk
            Transfer Limited. </li>
          <li>Please note that in accordance with legislation we keep an updated disclosure register. This register informs you,
            our client, of all financial and ownership interests that we may become entitled to and lists the business relationships
            that I have with the product suppliers. This document ensures transparency in our dealings with our customers
            and is available for inspection.</li>
        </ol>
      </li>
      <li>
        <strong>Electronic transactions, communication and records</strong>
        <ol>
          <li>You accept the risk of choosing electronic communication as the channel to be utilised by the parties. You are
            responsible to ensure that Meerkat has received the information sent electronically. You authorise Meerkat to
            act on the information that you send electronically. You thereby agree that all electronic agreements, notices,
            disclosures, and other communications sent by Meerkat t satisfy any legal requirement that such communications
            should be in writing. </li>
          <li>You acknowledge and consent that Meerkat, in its discretion, may retain and store your electronic communications
            as may be lawfully required. You agree that the electronic records stored by Meerkat will constitute conclusive
            proof (it may not be challenged) of the content of the records.
          </li>
          <li>Any e-mail, SMS or electronic notification sent to you will be deemed to have been received by you upon dispatch
            by Meerkat. </li>
          <li>We take all reasonable steps to protect your personal information and maintain confidentiality, including the use
            of encryption technology. However, we cannot guarantee the security of any information you transmit to us online
            and you do so at your own risk. Meerkat will not be liable for any loss or damage that you may suffer as a result
            of interception by unauthorised parties and any unlawful and unauthorised activities.
          </li>
          <li>In terms of the Electronic Communications and Transactions Act. Please note the following:
            <ol>
              <li>Full name and legal status: MyMeerkat (PTY) Ltd., a private company incorporated in accordance with the laws
                of the Republic of South Africa;</li>
              <li>Registration number: 2015/102401/07; </li>
              <li>Physical address: 203 Pine Park, 1 Logan Way, Pinelands, Cape Town, 7450; </li>
              <li>Telephone number: (021) 003 4535; </li>
              <li>Website address: www.meerkat.co.za;
              </li>
              <li>E-mail address: info@meerkat.co.za.
            </li>
        </ol>
        </li>
        <li>
          <strong>Your warranties</strong>
          <ol>
            <li>You warrant your identity (i.e. you are who you say you are) and that you can prove your identity should we require
              you to do so. </li>
            <li>You warrant that all information you provide at any time to Meerkat using the Meerkat website, in writing, or
              to Meerkat staff, will in all respects be current, complete, and accurate. It is in your best interest to keep
              the information up-to-date and accurate. </li>
            <li>Meerkat reserves the right to refuse to provide services to you, and cancel any agreements with you, if you do
              not provide information, or provide information that is incorrect, or if we are unable to verify or authenticate
              any information you provide to us.
            </li>
          </ol>
        </li>
        <li>
          <strong>Unsolicited Information</strong>
          <p>By submitting any unsolicited information and materials, including comments, ideas, questions, designs, and other
            similar communications (collectively, “Unsolicited Information”), you agree to be bound by the following terms
            and conditions. If you do not agree with these terms and conditions, you should not provide any Unsolicited Information
            through the Site. All Unsolicited Information will be considered NON-CONFIDENTIAL and NON-PROPRIETARY. We may
            use such communication or material for any purpose whatsoever, including, but not limited to, reproduction, disclosure,
            transmission, publication, broadcast, and further posting. Further, we are free to use any ideas, concepts, know-how,
            or techniques contained in any communication or material you send to the site for any purpose whatsoever, including,
            but not limited to, developing, manufacturing, and marketing products. By submitting any Unsolicited Information,
            you are granting us a perpetual, royalty-free and irrevocable right and license to use, reproduce, modify, adapt,
            publish, translate, distribute, transmit, publicly display, publicly perform, sublicense, create derivative works
            from, transfer and sell such Unsolicited Information and to use your name and other identifying information in
            connection with such Unsolicited Information.</p>
        </li>
        <li>
          <strong>Breach</strong>
          <ol>
            <li>Subject to any other provisions set out in these terms and conditions, should you be in breach of any provision
              of this Agreement, then Meerkat shall be entitled, without prejudice to any other rights that it may have and
              to the extent required or permitted, as the case may be, by law, to forthwith:
              <ol>
                <li>afford you a reasonable opportunity to remedy the breach, taking into account the nature of the breach in
                  question; or </li>
                <li>suspend your access to the Service; or </li>
                <li> cancel all agreements concluded between us.</li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <strong>Indemnity
            <ol>
              <li>You hereby unconditionally and irrevocably indemnify Meerkat and agree to indemnify and hold Meerkat harmless
                against all loss, damages, claims, liability and/or costs, of whatsoever nature, howsoever and whensoever
                arising, suffered or incurred by Meerkat as a result of any claim instituted against Meerkat by a third party
                (other than you) as a result of (without limitation):
                <ol>
                  <li> your use of our Services other than as allowed or prescribed in the Agreement;</li>
                  <li>any other cause whatsoever relating to the Agreement or the provision of Services to you where you have
                    acted wrongfully or failed to act when you had a duty to so act.</li>
                </ol>
              </li>
            </ol>
          </strong>
        </li>
        <li>
          <strong>No representations, warranties or guarantees and limitation of liability
            <ol>
              <li>Save to the extent otherwise provided for in this Agreement or where you are entitled to rely on or receive,
                by operation of law, any representations, warranties or guarantees, we do not make or provide any express
                or implied representations, warranties or guarantees regarding the availability, accuracy, reliability, timeliness,
                quality or security of any product or service. </li>
              <li>While we will take care to provide accurate information on the website, the information displayed on the website
                is provided without any express or implied warranty (guarantee that the information is correct) of any kind
                whatsoever.
              </li>
              <li>All information, including products and services or any terms or conditions pertaining to them, is subject
                to change at any time. All calculations made on the website should be confirmed before relying on them. Meerkat
                is not responsible for any defects in, malfunctions of, or inaccuracies in the performance of any models
                used in the calculations.
              </li>
              <li>The advice is based on data provided by you and received by us. Your needs may differ from what is described,
                depending on details we did not capture (including, but not limited to, your employer benefits, your linked
                investment service provider products and your health history). </li>
              <li>Some of our information is derived from external sources. We are not responsible for the incorrect information
                received from these external sources. Although we use reasonable efforts to ensure that the advice is accurate,
                no representation or warranty, express or implied, is given with respect to their accuracy or with respect
                to the accuracy of any other content. </li>
              <li>Without limiting the generality of the provisions of clause 16.2, Meerkat shall not be liable for and you will
                have no claim of whatsoever nature against Meerkat as a result of
                <ol>
                  <li> the loss of or access to any usernames and passwords which you are required to safeguard and not allow
                    unauthorized access on the understanding that we will be entitled to assume that you are the person so
                    using or gaining access to any service or account where your username and password is used; </li>
                  <li>any unavailability of, or interruption in the Service due to an Uncontrolled Event; </li>
                  <li> any damage, loss, cost or claim which you may suffer or incur arising from any suspension or termination
                    of the service/s for any reason contemplated in the Agreement. </li>
                </ol>
              </li>
              <li>In addition to and without prejudice to any other limitations of liability provided for in the Agreement and
                to the fullest extent permitted by applicable law, Meerkat shall not be liable to you for any direct damages
                howsoever arising and neither party shall be liable to the other for any special, indirect, incidental, consequential
                or punitive damages arising out of or relating to this Agreement, whether resulting from negligence, breach
                or any other cause. To the extent that a competent court or tribunal or other competent dispute resolution
                body or authority finally determines, notwithstanding the exclusion contained in this clause, that Meerkat
                is liable to you for any damages, Meerkat’s liability to you for any damages howsoever arising shall be limited
                to the amounts paid by you under this Agreement in consideration for a service or product during the immediately
                preceding 12 (twelve) month period in respect of the service or product which gave rise to the liability
                in question.
              </li>
            </ol>
          </strong>
        </li>
        <li>
          <strong>Cession and Delegation
            <p>You may not sell, cede, assign, delegate or in any other way alienate or dispose of any or all of your rights
              and obligations under and in terms of this Agreement without the prior written approval of Meerkat. Meerkat
              shall be entitled to sell, cede, assign, delegate, alienate, dispose or transfer any or all of its rights and
              obligations under and in terms of this Agreement to any of its affiliates or to any third party without your
              consent and without notice to you provided that you are not unduly prejudiced as a result. “Affiliates” for
              this purpose includes Meerkat’s holding company, the holding company(ies) of Meerkat’s holding company (collectively
              “its holding companies”), its subsidiaries, subsidiaries of its holding companies and any other companies which
              are directly or indirectly controlled by Meerkat or are under common control with Meerkat.
            </p>
          </strong>
        </li>

        <li>
          <strong>Jurisdiction</strong>
          <p>You hereby consent to the jurisdiction of the Magistrate's Court in the Republic of South Africa in respect of
            any proceedings that may be initiated by Meerkat arising out of this Agreement, provided that Meerkat shall be
            entitled, in its reasonable discretion, to institute such proceedings in the High Court of South Africa and,
            in such event, you consent to the jurisdiction of such court. The jurisdiction of the Small Claims Court is specifically
            excluded, as the parties agreed to follow the arbitration process set out in clause 5 above.</p>
        </li>

        <li>
          <strong>Amendment of this agreement</strong>
          <p>Meerkat reserves the right to amend this agreement from time to time. Any new version of the Agreement will be
            displayed on our Website together with the date on which it will become effective, which will never be less than
            30 (thirty) days after the date on which it is first published. It is your obligation to visit our web site on
            a regular basis in order to determine whether any amendments have been made.</p>
        </li>
        <li>
          <strong>General</strong>
          <ol>
            <li>The parties acknowledge and agree that this Agreement constitutes the whole of the agreement between them and
              that no other agreements, guarantees, undertakings or representations, either verbal or in writing, relating
              to the subject matter of this Agreement not incorporated in this Agreement shall be binding on the parties.
              No variation or addition of this Agreement or the Application Form will be binding on any of the parties unless
              recorded in writing and signed by both parties. </li>
            <li>Meerkat is in terms of section 43 of the ECT Act required to make its contact details, its domicilia citandi
              et executandi and certain other information available to its Subscribers who enter into electronic transactions
              with Meerkat. This information is available on our website. </li>
            <li>You agree that any notices we send to you in terms of any agreement concluded between us may be sent via e-mail
              unless otherwise prescribed by law.
            </li>
            <li>No indulgence, leniency or extension of time which Meerkat may grant or show to you shall in any way prejudice
              Meerkat or preclude Meerkat from exercising any of its rights in the future. </li>
            <li>
              <strong>You warrant that as at the date of signature all the details furnished by you to Meerkat are true and correct
                and that you will notify Meerkat in the event of any change to such details.</strong>
            </li>
            <li>All our terms and conditions can be accessed, stored, and reproduced electronically by you. </li>
            <li>The physical address where Meerkat will receive legal service of documents/ domicilium citandi et executandi
              is the following: 203 Pine Park 1 Logan Way Pinelands Cape Town 7450 South Africa</li>
          </ol>
        </li>
                  </ol>
                </li>
              </ol>
    <p>
      <strong> Date of last update: 15 September 2017</strong>
    </p>
    <br />
    <br />
            Signed at­­­­­­­­{' '}
            <span style={{ textDecoration: 'underline', fontWeight: 'bold' }}>
              {' '}
              {city}, {country_name}{' '}
            </span>{' '}
            on this date
            <span style={{ textDecoration: 'underline', fontWeight: 'bold' }}>
              {' '}
              {moment().format('Do MMMM YYYY')}{' '}
            </span>
            </div>
            </div>
        </Fragment>
      );
    };


  render() {
    //04255015089
    if (this.state.notDone) {
      return (
        <Card style={{ border: '1px solid rgb(34, 148, 144)', borderRadius: '15px', textAlign: 'left', width: '98%' , overflow: 'auto', margin:  "-1% 1% 1%"  }}>
          <Spin spinning={this.state.showSpinner} size="large" style={{ float: 'right', top: '79vh' }} delay={2500}>
            <Alert message={this.state.alertTitle} description={this.state.alertMessage} type='info' banner style={{ fontWeight: 'bold', display: this.state.showSpinner ? 'block' : 'none' }} />
          </Spin>

          {this.poaText(this.state.firstNames, this.state.surname, this.state.idNumber, this.state.location.city, this.state.location.country_name)}
          {this.poaBody(this.state.firstNames, this.state.surname, this.props.enableSave, this.props.disableSave, this.props.saveButton)}

        </Card>
      );
    }
    else return (
      <center><p>"Thank You"</p></center>
    );
  }
  }

const request = async (clientId, firstNames, surname, idNumber, city, country_name, signature, calledFrom, checked) => {
  const accessToken = localStorage.getItem('token');
  const poaSteps = {
    firstNames: firstNames,
    surname: surname,
    idNumber: idNumber,
    city: city,
    country_name: country_name,
    signature: signature,
    date: moment().format('Do MMMM YYYY'),
    accountName: '',
    bank: '',
    bankAccount: ''
  }
  if (!clientId) {
    //We need to create a credit life application if we don't have one
    // put this here to have code run ok
    clientId = '5b1131f603105c8cee41c03d'
  }

  const response = await fetch(`${API_URL}client/${clientId}/pdf/tc`, { method: 'post', body: JSON.stringify(poaSteps), headers: { 'Content-Type':'application/json', 'Authorization': accessToken ? `JWT ${accessToken}` : null } })
  // save file if checkbox checked
  if (calledFrom != 'moku' && checked) {
    const data = await response.blob()
    const file = await fileDownload(data, `t&c-${idNumber}.pdf`)
    //const file = await fileDownload(data, `t&c-${idNumber}.htm`)
  }
}
