
const english = {
  __html:
    `Clauses in these Terms and Conditions that are of importance or that
  carry a level of risk for you are in bold. Please pay special
  attention to these clauses and make sure you understand them. If you
don’t understand something please get us to explain it to you.<br />
  <h3> Definitions</h3>
  “<b>Acceptance Date</b>” means the date on which you accepted the
  Agreement, by way of electronic medium, for example by clicking “I
  agree” on a web page or via your mobile phone or by a voice recorded
call via our Call Centre; <br />
  “<b>Agreement</b>” means the agreement concluded between you and
  Meerkat which agreement will be exclusively governed by these terms
  and conditions and the privacy policy and content submission terms;
<br />
  “<b>Business Day</b>” means Monday to Friday, but excludes Saturdays
  and a day which is an official public holiday in the Republic of
  South Africa; “Business Hours” means the hours between 08h00 and
17h00 on a Business Day; <br />
  “<b>ECT Act</b>” means the Electronic Communications and
Transactions Act, 2002; <br />
  “<b>Meerkat</b>”, “<b>MyMeerkat</b>” “we”, “us” and “our” means
MyMeerkat (Proprietary) Limited; <br />
  “<b>Meerkat Marks</b>” means any trademarks, logos, characters,
  brand names, trade names, domain names or other names or marks of
Meerkat whether registered or not; <br />
  “<b>Intellectual Property Rights</b>” means the copyright in any
  work in terms of the Copyright Act, No. 98 of 1978, and includes
  without limitation the right to reproduce that work, the rights in
  respect of a trade mark conferred by the Trade Marks Act, No. 194 of
  1993, the rights in respect of a design conferred by the Designs
  Act, No. 195 of 1993, and the rights in respect of a patent
  conferred by the Patents Act, No. 57 of 1978 including any
  applications for the aforegoing and any names, licenses, know how,
trade secrets and data associated with the aforegoing; <br />
  "<b>Juristic Person</b>" means a company or close corporation and
  includes a body corporate, partnership, association or trust;
<br />
  “<b>Legal Notices Website</b>” means
  https://www.meerkat.co.za/resources; “NCA” means the National Credit
Act of 2005; <br />
  “<b>Third Party Service Provider</b>” means a third party who
provides services to Subscribers via the Meerkat website; <br />
  “<b>Service</b>” means the financial advice and services provided by
Meerkat; <br />
  “<b>Subscriber</b>”, “you” or “Customer” means a user of any of our
products or services; <br />
  “<b>Uncontrollable Event</b>” means (including without limitation)
  any fire, flood, earthquake, elements of nature or acts of God,
  riots, civil disorders, rebellions or revolutions in any country or
  any other cause beyond the reasonable control of Meerkat including
  the termination or suspension of a service or product provided by a
  third party, that may result in a delay or a failure to provide any
product or service; and<br />
  “<b>VAT</b>” means Value Added Tax as provided for in the Value
Added Tax Act, 1991. <br />
  “<b>Website</b>” means https://www.meerkat.co.za,
  https://www.mymeerkat.com, https://www.mymeerkat.co.uk,
https://www.mymeerkat.co.za and https://www.mkat.co:<br />
  <h3>Commencement, Duration, Termination and Cooling-off</h3>
  The Agreement will commence on the Acceptance Date and endure
  indefinitely until it is cancelled as provided for in this
  Agreement. This Agreement incorporates by reference the Website
  Terms and Conditions and Privacy Policy which have been made
  available to you on this website and which you undertake to read and
  understand. If you do not understand anything, please ask us to
  explain it to you. Meerkat may terminate this Agreement immediately
  on notice to you for material breach of this Agreement.
  Notwithstanding the termination of the Agreement, in the event that
  you continue to use the products or services despite the termination
  of the Agreement, you will remain liable for and promptly pay on
  demand all amounts that would have been due to Meerkat as a result
  of the use of or access to the product or service and this Agreement
  shall be deemed to continue to apply until such time as all amounts
  due to Meerkat have been paid in full, and we will be entitled to
  terminate the agreement at any time. If the Agreement results from
  any direct approach to you by Meerkat or is an electronic
  transaction as contemplated in the ECT Act, you will be entitled to
  cancel the Agreement on written notice to Meerkat without reason or
  penalty within 5 (five) Business Days of – in the case of services
  only being provided in terms of the Agreement - the Acceptance Date;
  and in the case where goods are provided and constitute the subject
  of the Agreement, whether in conjunction with services or on its own
  – the date of delivery of such goods. For purposes of clause 2.5.2,
  goods include any literature, music, photograph, motion picture,
  game, information, data software, code, calculators, models,
  business methodologies or other intangible product or any license to
use such intangible product.<br />
  <h3> Conditions of access</h3>
  Meerkat will make the service available to you on the Acceptance
  Date. Meerkat will, where relevant, issue a user name and password
  to you prior to the Acceptance Date in order to enable you to gain
  access to and/or use a service. In such instance, you will not be
  able to access and/or use a service without a user name and
  password. You agree that: you will use your user name and password
  for your own personal use only; you will not disclose your user name
  and password to any other person for any reason whatsoever and that
  you will maintain the confidentiality thereof; in the event that
  your password is compromised, you will immediately notify Meerkat
  and change your password; you, as the holder of the user name and
  password, acknowledge you are solely responsible for all payments in
  respect of a service charged to your Meerkat account, irrespective
  of whether the service has been utilized or is being utilized by you
  or not and accordingly the entire amount outstanding on your Meerkat
  account will be deemed to have arisen from (or relate to) your
  access to and/or use of a service; you agree to cause all persons
  who use any products or services under your account or with your
  authorization to comply with the Agreement. All acts or omissions of
  all persons who use services under your account or with your
  authorization will be treated for all purposes as your acts or
  omissions; Meerkat also offers a password reminder service. We will
  send your password to your registered email address or cell phone
  number should you have forgotten your password. Subscription to this
  service is voluntary. Password reminders will only be sent to your
  registered details and should such details change it is your
  responsibility to notify us. you will not, at any time, permit
  and/or initiate a simultaneous network log-in; and you will not
  attempt to circumvent Meerkat's user authentication processes or
  engage in attempts to access Meerkat's network where not expressly
authorised to do so.<br />
  <h3> Service Delivery, Service Availability</h3>
  Meerkat will use reasonable endeavors to make its services available
  to its Subscribers, and to maintain the availability thereof for use
  by its Subscribers. However, we provide the services “as is” and “as
  available” and do not warrant or guarantee that the services will at
  all times be free of errors or interruptions, be always available,
  fit for any purpose, not infringe any third party rights, be secure
  and reliable. Meerkat will use its best endeavors to notify you in
  advance of any maintenance and repairs which may result in the
  unavailability of a service, but cannot always guarantee this.
  Furthermore, we cannot guarantee that the services will function
  properly on all mobile devices or that it will always be available
  due to network connectivity issues. Please note that we are an
  authorised Financial Services Provider in the Republic of South
  Africa. Our products and services are not designed for, and may not
  be suitable for any other country. We recommend that you ensure that
  use of the services is not in violation of the laws of any country
  by which you may be governed. Your use of our website for any
purpose is entirely at your own risk.<br />
  Communication and Dispute Resolution<br />
  You agree that Meerkat may from time to time send you communications
  regarding (without being limited to) special offers or discounts
  which Meerkat may negotiate for and offer to its Subscribers,
  operational changes that may affect the services and/or new services
  or products launched. All communications will abide by our Privacy
  Policy and applicable law. You will always be entitled to notify us
  in writing that you do not wish to receive or continue to receive
  such communications and if you are a consumer as contemplated in the
  CPA, to pre-emptively block the receipt of such communications. Any
  dispute between the parties may be referred to arbitration and
  finally resolved in accordance with the rules of the Arbitration
  Foundation of Southern Africa. Such arbitration shall be held in
  Cape Town, and conducted in the English language before one
  arbitrator appointed in accordance with the said rules. Any award
  will be final and not subject to appeal. This agreement to arbitrate
  shall be enforceable in, and judgement upon any award may be entered
  in any court of any country having appropriate jurisdiction. A
  dispute shall be deemed to have arisen when either party notifies
  the other party in writing to that effect. The arbitrator shall have
  the power to give default judgement if any party fails to make
  submissions on due date and/or fails to appear at the arbitration.
  The provisions set out above shall not prevent either party from
  approaching any court of competent jurisdiction to obtain interim or
other relief in cases of urgency.<br />
  <h3> Payment</h3>
  Meerkat will receive remuneration of 3.25% (three point two five
  percent) of any monthly premium payable by you to OMART in exchange
for services rendered.<br />
  <h3> Privacy and Credit Checks</h3>
  Meerkat will deal with your personal information in accordance with
  the provisions of our Privacy Policy which is available on our
  http://www.meerkat.co.za/resources and in compliance with all
  relevant laws. Your application for a service or product may be
  subject to a credit referencing or risk assessment process. This
  means that Meerkat may request and receive your Confidential
  Information, Consumer Credit Information and Prescribed Information
  (as defined in the NCA) (“Assessment Information”) from registered
  credit bureaus in order to perform a financial means assessment.
  Meerkat is entitled to perform this financial means test each time
  you apply for a service or product and at any time during the
  existence of any service with us or to assess your credit worthiness
  at a later time. In this regard you consent to Meerkat requesting,
  receiving and reporting your Assessment Information from and to
  registered credit bureaus in accordance with the provisions and for
  the purposes of the NCA; and the sharing of such Information by
  registered credit bureaus and such other persons as contemplated in
  the NCA, for the prescribed purposes of the NCA. Should your
  application be as a result of a referral by a debt counsellor, you
  consent to Meerkat requesting, receiving and reporting your
  Assessment Information from and to registered debt counsellors in
accordance with our Privacy Policy.<br />
  <h3> Intellectual Property Rights</h3>
  You agree to comply with all laws applicable to any Intellectual
  Property Rights in respect of any data, files and/or information
  accessed, retrieved or stored by you through your use of any of our
  services and/products. You are prohibited from using any Meerkat
Marks without the prior written approval of Meerkat.<br />
  <h3> Intermediary Appointment</h3>
  I confirm the engagement of MyMeerkat (Pty) Ltd., and its
  representatives, duly authorised, as my Financial Advisor. I
  understand and acknowledge: that advice received may entitle
  MyMeerkat (Pty) Ltd. to any future review fees and/or commissions
  that may be payable under my various investments and/or policies.
  that such appointment will be limited to: Product categories for
  which MyMeerkat (Pty) Ltd. is licensed with the Financial Services
  Board, and Companies with whom MyMeerkat (Pty) Ltd. has concluded
Intermediary Agreements.<br />
  <h3> FAIS Disclosures</h3>
  The business name is MyMeerkat (Pty) Ltd. The company registration
  number: 2015/102401/07. MyMeerkat (Pty) Ltd. is an authorised
  financial services provider, licensed by the FSB (Financial Services
  Board) under the following categories 1.1 Long-Term Insurance:
  Category A 1.2 Short-Term Insurance: Personal Lines 1.3 Long-Term
  Insurance: Category B 1.4 Long-Term Insurance: Category C 1.5 Retail
  Pension Benefits 1.6 Short-Term Insurance: Commercial Lines 1.7
  Pension Fund Benefits (excluding Retail Pension Benefits) 1.14
  Participatory Interests in one or more Collective Investment Schemes
  1.17 Deposits as defined in the Banks Act – exceeding 12 months 1.18
  Deposits as defined in the Banks Act – 12 months or less FSP Number:
  46535. Registered Address: 203 Pine Park, 1 Logan Way, Pinelands,
  Cape Town, 7450. Physical and Postal Address: 203 Pine Park, 1 Logan
  Way, Pinelands, Cape Town, 7450. Website: https://www.meerkat.co.za
  Tel. +27 (021) 003 4535. If you are not satisfied with any aspects
  of your policy or services provided by Meerkat on behalf of OMART,
  please contact us on: complaints@meerkat.co.za The Meerkat
  Compliance Officer is Anzel Jewaskiewitz of Masthead (Pty) Ltd,
  Telephone 021 555 4121, ajewaskiewitz@masthead.co.za. The Compliance
  Officer deals with issues relating to Meerkat’s compliance with the
  Financial Advisory and Intermediary Services Act, 37 of 2002 (FAIS
  Act). If you feel that Meerkat has contravened the provisions of the
  FAIS Act, and/or has rendered financial services in a manner that
  may affect you adversely, please contact us. Alternately, you may
  contact the FAIS Ombudsman: 012 470 9080 / info@faisombud.co.za /
  https://www.faisombud.co.za. If you have a complaint linked to the
  insurance contract, you may contact the Ombudsman for Long-term
  Insurance. 021 657 5000 / info@ombud.co.za /
  https://www.ombud.co.za. The Ombudsman is available for advice on
  complaints in respect of claims or any other matter which have not
  been satisfactorily resolved by Meerkat. Product Supplier: Old
  Mutual Alternative Risk Transfer Limited, a registered long-term
  insurer and Subscriber of the Old Mutual Group. Our consultants who
  do not meet the full Fit and Proper Requirements as defined by FAIS
  render services under management supervision. Meerkat has
  professional indemnity insurance. Meerkat has and maintains an
  Anti-Money Laundering Policy in accordance with the FAIS Act.
  Meerkat retains recordings of all interactions including telephone
  discussions. These will be made available to the client on request.
  No member of the Meerkat team holds more than 10% of the shares
  issued by any product provider. Meerkat received more than 30% of
  the last year’s commission and remuneration from Old Mutual
  Alternative Risk Transfer Limited. Please note that in accordance
  with legislation we keep an updated disclosure register. This
  register informs you, our client, of all financial and ownership
  interests that we may become entitled to and lists the business
  relationships that I have with the product suppliers. This document
  ensures transparency in our dealings with our customers and is
available for inspection.<br />
  <h3> Electronic transactions, communication and records</h3>
  You accept the risk of choosing electronic communication as the
  channel to be utilised by the parties. You are responsible to ensure
  that Meerkat has received the information sent electronically. You
  authorise Meerkat to act on the information that you send
  electronically. You thereby agree that all electronic agreements,
  notices, disclosures, and other communications sent by Meerkat t
  satisfy any legal requirement that such communications should be in
  writing. You acknowledge and consent that Meerkat, in its
  discretion, may retain and store your electronic communications as
  may be lawfully required. You agree that the electronic records
  stored by Meerkat will constitute conclusive proof (it may not be
  challenged) of the content of the records. Any e-mail, SMS or
  electronic notification sent to you will be deemed to have been
  received by you upon dispatch by Meerkat. We take all reasonable
  steps to protect your personal information and maintain
  confidentiality, including the use of encryption technology.
  However, we cannot guarantee the security of any information you
  transmit to us online and you do so at your own risk. Meerkat will
  not be liable for any loss or damage that you may suffer as a result
  of interception by unauthorised parties and any unlawful and
  unauthorised activities. In terms of the Electronic Communications
  and Transactions Act. Please note the following: Full name and legal
  status: MyMeerkat (PTY) Ltd., a private company incorporated in
  accordance with the laws of the Republic of South Africa;
  Registration number: 2015/102401/07; Physical address: 203 Pine
  Park, 1 Logan Way, Pinelands, Cape Town, 7450; Telephone number:
  (021) 003 4535; Website address: https://www.meerkat.co.za; E-mail
address: info@meerkat.co.za.<br />
  <h3> Your warranties</h3>
  You warrant your identity (i.e. you are who you say you are) and
  that you can prove your identity should we require you to do so. You
  warrant that all information you provide at any time to Meerkat
  using the Meerkat website, in writing, or to Meerkat staff, will in
  all respects be current, complete, and accurate. It is in your best
  interest to keep the information up-to-date and accurate. Meerkat
  reserves the right to refuse to provide services to you, and cancel
  any agreements with you, if you do not provide information, or
  provide information that is incorrect, or if we are unable to verify
  or authenticate any information you provide to us. Unsolicited
  Information By submitting any unsolicited information and materials,
  including comments, ideas, questions, designs, and other similar
  communications (collectively, “Unsolicited Information”), you agree
  to be bound by the following terms and conditions. If you do not
  agree with these terms and conditions, you should not provide any
  Unsolicited Information through the Site. All Unsolicited
  Information will be considered NON-CONFIDENTIAL and NON-PROPRIETARY.
  We may use such communication or material for any purpose
  whatsoever, including, but not limited to, reproduction, disclosure,
  transmission, publication, broadcast, and further posting. Further,
  we are free to use any ideas, concepts, know-how, or techniques
  contained in any communication or material you send to the site for
  any purpose whatsoever, including, but not limited to, developing,
  manufacturing, and marketing products. By submitting any Unsolicited
  Information, you are granting us a perpetual, royalty-free and
  irrevocable right and license to use, reproduce, modify, adapt,
  publish, translate, distribute, transmit, publicly display, publicly
  perform, sublicense, create derivative works from, transfer and sell
  such Unsolicited Information and to use your name and other
  identifying information in connection with such Unsolicited
Information.<br />
  <h3> Breach</h3>
  Subject to any other provisions set out in these terms and
  conditions, should you be in breach of any provision of this
  Agreement, then Meerkat shall be entitled, without prejudice to any
  other rights that it may have and to the extent required or
  permitted, as the case may be, by law, to forthwith: afford you a
  reasonable opportunity to remedy the breach, taking into account the
  nature of the breach in question; or suspend your access to the
Service; or cancel all agreements concluded between us.<br />
  <h3> Indemnity</h3>
  You hereby unconditionally and irrevocably indemnify Meerkat and
  agree to indemnify and hold Meerkat harmless against all loss,
  damages, claims, liability and/or costs, of whatsoever nature,
  howsoever and whensoever arising, suffered or incurred by Meerkat as
  a result of any claim instituted against Meerkat by a third party
  (other than you) as a result of (without limitation): your use of
  our Services other than as allowed or prescribed in the Agreement;
  any other cause whatsoever relating to the Agreement or the
  provision of Services to you where you have acted wrongfully or
failed to act when you had a duty to so act.<br />
  No representations, warranties or guarantees and limitation of
liability<br />
  Save to the extent otherwise provided for in this Agreement or where
  you are entitled to rely on or receive, by operation of law, any
  representations, warranties or guarantees, we do not make or provide
  any express or implied representations, warranties or guarantees
  regarding the availability, accuracy, reliability, timeliness,
  quality or security of any product or service. While we will take
  care to provide accurate information on the website, the information
  displayed on the website is provided without any express or implied
  warranty (guarantee that the information is correct) of any kind
  whatsoever. All information, including products and services or any
  terms or conditions pertaining to them, is subject to change at any
  time. All calculations made on the website should be confirmed
  before relying on them. Meerkat is not responsible for any defects
  in, malfunctions of, or inaccuracies in the performance of any
  models used in the calculations. The advice is based on data
  provided by you and received by us. Your needs may differ from what
  is described, depending on details we did not capture (including,
  but not limited to, your employer benefits, your linked investment
  service provider products and your health history). Some of our
  information is derived from external sources. We are not responsible
  for the incorrect information received from these external sources.
  Although we use reasonable efforts to ensure that the advice is
  accurate, no representation or warranty, express or implied, is
  given with respect to their accuracy or with respect to the accuracy
  of any other content. Without limiting the generality of the
  provisions of clause 16.2, Meerkat shall not be liable for and you
  will have no claim of whatsoever nature against Meerkat as a result
  of the loss of or access to any usernames and passwords which you
  are required to safeguard and not allow unauthorized access on the
  understanding that we will be entitled to assume that you are the
  person so using or gaining access to any service or account where
  your username and password is used; any unavailability of, or
  interruption in the Service due to an Uncontrolled Event; any
  damage, loss, cost or claim which you may suffer or incur arising
  from any suspension or termination of the service/s for any reason
  contemplated in the Agreement. In addition to and without prejudice
  to any other limitations of liability provided for in the Agreement
  and to the fullest extent permitted by applicable law, Meerkat shall
  not be liable to you for any direct damages howsoever arising and
  neither party shall be liable to the other for any special,
  indirect, incidental, consequential or punitive damages arising out
  of or relating to this Agreement, whether resulting from negligence,
  breach or any other cause. To the extent that a competent court or
  tribunal or other competent dispute resolution body or authority
  finally determines, notwithstanding the exclusion contained in this
  clause, that Meerkat is liable to you for any damages, Meerkat’s
  liability to you for any damages howsoever arising shall be limited
  to the amounts paid by you under this Agreement in consideration for
  a service or product during the immediately preceding 12 (twelve)
  month period in respect of the service or product which gave rise to
the liability in question.<br />
  <h3> Cession and Delegation</h3>
  You may not sell, cede, assign, delegate or in any other way
  alienate or dispose of any or all of your rights and obligations
  under and in terms of this Agreement without the prior written
  approval of Meerkat. Meerkat shall be entitled to sell, cede,
  assign, delegate, alienate, dispose or transfer any or all of its
  rights and obligations under and in terms of this Agreement to any
  of its affiliates or to any third party without your consent and
  without notice to you provided that you are not unduly prejudiced as
  a result. “Affiliates” for this purpose includes Meerkat’s holding
  company, the holding company(ies) of Meerkat’s holding company
  (collectively “its holding companies”), its subsidiaries,
  subsidiaries of its holding companies and any other companies which
  are directly or indirectly controlled by Meerkat or are under common
control with Meerkat.<br />
  <h3> Jurisdiction</h3>
  You hereby consent to the jurisdiction of the Magistrate's Court in
  the Republic of South Africa in respect of any proceedings that may
  be initiated by Meerkat arising out of this Agreement, provided that
  Meerkat shall be entitled, in its reasonable discretion, to
  institute such proceedings in the High Court of South Africa and, in
  such event, you consent to the jurisdiction of such court. The
  jurisdiction of the Small Claims Court is specifically excluded, as
  the parties agreed to follow the arbitration process set out in
clause 5 above.<br />
  <h3> Amendment of this agreement</h3>
  Meerkat reserves the right to amend this agreement from time to
  time. Any new version of the Agreement will be displayed on our
  Website together with the date on which it will become effective,
  which will never be less than 30 (thirty) days after the date on
  which it is first published. It is your obligation to visit our web
  site on a regular basis in order to determine whether any amendments
have been made.<br />
  <h3> General</h3>
  The parties acknowledge and agree that this Agreement constitutes
  the whole of the agreement between them and that no other
  agreements, guarantees, undertakings or representations, either
  verbal or in writing, relating to the subject matter of this
  Agreement not incorporated in this Agreement shall be binding on the
  parties. No variation or addition of this Agreement or the
  Application Form will be binding on any of the parties unless
  recorded in writing and signed by both parties. Meerkat is in terms
  of section 43 of the ECT Act required to make its contact details,
  its domicilia citandi et executandi and certain other information
  available to its Subscribers who enter into electronic transactions
  with Meerkat. This information is available on our website. You
  agree that any notices we send to you in terms of any agreement
  concluded between us may be sent via e-mail unless otherwise
  prescribed by law. No indulgence, leniency or extension of time
  which Meerkat may grant or show to you shall in any way prejudice
  Meerkat or preclude Meerkat from exercising any of its rights in the
  future. You warrant that as at the date of signature all the details
  furnished by you to Meerkat are true and correct and that you will
  notify Meerkat in the event of any change to such details. All our
  terms and conditions can be accessed, stored, and reproduced
  electronically by you. The physical address where Meerkat will
  receive legal service of documents/ domicilium citandi et executandi
  is the following: 203 Pine Park 1 Logan Way Pinelands Cape Town 7450
South Africa<br />
  <br />
  Date of last update: 15 September 2017<br />
  <br />`}

export default english