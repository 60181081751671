import React, { Component } from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import I18n from './strings/i18n';

export const buttonStyle = {
  background: 'rgb(255, 255, 255)',
  display: 'flex',
  position: 'relative',
  minHeight: '44px',
  margin: '7px',
  color: 'rgb(34, 148, 144)',
  border: '2px solid rgb(34, 148, 144)',
  borderRadius: '15px',
  cursor: 'pointer',
  justifyContent: 'center',
  fontSize: '1rem',
  lineHeight: 1,
  width: '75%',
  whiteSpace: 'normal'
}

export const services = [
  {
    value: 801,
    trigger: 'Loan',
    text: 'Loans'
  },
  {
    value: 802,
    trigger: 'DC',
    text: 'Debt Counselling'
  },
  {
    value: 803,
    trigger: 'CreditCheck',
    text: 'Credit Check'
  },
  {
    value: 804,
    trigger: 'DebtConsolidation',
    text: 'Debt Consolidation'
  },
  {
    value: 805,
    trigger: 'cl',
    text: 'Credit Life Insurance (to cover your debt) '
  }
]

export class OptionButtons extends Component {
// we need to determine what menu options to load
constructor(props) {
  super(props);
  this.state = {
    options:[]
  };
}

  componentDidMount() {
    let buttons = [];
    const { steps } = this.props;
    const terms = steps.termsPrompt && steps.termsPrompt.value == "yes"
    const prefix = terms ? "" : "" // add a prefix if we want to change destination trigger
    if (!steps.Loan) buttons.push({ value: 801, trigger: prefix+'Loan', text: I18n.t('menuLoan') })
    if (!steps.DC) buttons.push({ value: 802, trigger: prefix+'DC', text: I18n.t('menuDC') })
    if (!steps.CreditCheck) buttons.push({ value: 803, trigger: prefix+'CreditCheck', text: I18n.t('menuCC') })
    if (!steps.DebtConsolidation) buttons.push({ value: 804, trigger: prefix + 'DebtConsolidation', text: I18n.t('menuDConsolidate') })
    //if (!steps.CL) buttons.push({
    //  value: 805, trigger: prefix + 'cl', text: I18n.t('menuCLI') })

    if (buttons.length < 1) buttons.push({ value: 999, trigger: 'noSaveEnd', text: 'End' })

    this.setState({ options: buttons })
  }


  render() {
    let menuButtons = this.state.options

    return (
      <center style={{ width: '100%' }}>
        {menuButtons.map(button => <Button key={button.value} style={buttonStyle} onClick={() => this.props.triggerNextStep({ value: button.value, trigger: button.trigger })}>{button.text}</Button>)}
      </center>
    );
  }
}
OptionButtons.propTypes = {
  steps: PropTypes.object
};

OptionButtons.defaultProps = {
  steps: undefined
};


export class WhereNext extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nextStep: '',
      nextTrigger: '',
      nextValue:''
    };
  }


  componentDidMount() {
    const { steps } = this.props;
    const menuOption = steps['menuOption']
    services.forEach((d, i) => {
      if (d.value === menuOption.value) {
        console.log('next step is if' + d.trigger);
        this.setState({ nextTrigger: 'if' + d.trigger,nextValue: d.value, nextText: 'Run Checks'  });
      }
    })
    setTimeout ( () => (this.props.triggerNextStep({ value: this.state.nextValue, trigger: this.state.nextTrigger }), 100))
  }

  render() {
      return (
        <div>
        </div>
    );
  }
}

WhereNext.propTypes = {
  steps: PropTypes.object
};

WhereNext.defaultProps = {
  steps: undefined
};