import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isValidCellphoneNumber, isValidIdNumber, creditCheck, isInteger } from './Validator';
import { OptionButtons, services, WhereNext } from './menu'
import { AffordabilityCheck, Band, Checking, CompuScan, previousVisitor, SaveLeadInfo, StartChatSession, Signature } from './customComponents'
import { addLogging } from './lib'
import I18n from './strings/i18n'
import { commonSteps } from './commonSteps'
import { POA } from './mandates/poa';
import Numeral from 'numeral'
import 'numeral/locales/en-za'

//value={Numeral(policy.currentPremiumTotal).format('###,###0')}



let loanSteps = (stepState) => [
    // Loans
    // Affordability and Credit Score
    {
      id: 'Loan',
      message: I18n.t("loan.Loan"),
      trigger: 'Loan2'
    },
    {
      id: 'Loan2',
      message:I18n.t("loan.Loan2"),
      trigger: 'Loan3'
    },
    {
      id: 'Loan3',
      message: I18n.t("loan.Loan3"),
      trigger: 'LoanAffordability'
    },
    {
      id: 'LoanAffordability',
      message:I18n.t("loan.LoanAffordability"),
      trigger: 'getTerms'
    },
    {
      id: 'LoanPassFail',
      message:I18n.t('loan.LoanPassFail') ,
      trigger: 'saveBureau'
    },
    {
      id: 'LoanFailFail',
      message: I18n.t('loan.LoanFailFail'),
      trigger: 'saveBureau'
    },
    {
      id: 'LoanFailPass',
      message: I18n.t('loan.LoanFailPass'),
      trigger: 'otherServicesPrompt'
    },
    {
      id: 'LoanPassPass',
      message: I18n.t('loan.LoanPassPass'),
      trigger: 'otherServicesPrompt'
  }
]

let dcSteps = (stepState) => [
  {
    id: 'otherServicesPrompt',
    message: I18n.t('dc.otherServicesPrompt'),
    trigger: 'otherServices'
  },
  {
    id: 'otherServices', hideInput: true,
    options: [
      { value: "yes", label: I18n.t("Yes"), trigger: 'menuOption' },
      { value: "no", label: I18n.t("No"), trigger: 'saveEnd' }
    ]
  },
  {id: 'DC',
  message:  I18n.t("dc.DC"),
  trigger: 'DC2'
  },
  {
  id: 'DC2',
  message: I18n.t("dc.DC2"),
  trigger:  'getTerms'
  },
  {
    id: 'DCPassFail',
    message: ({ steps }) =>  steps.firstName.value + " " +  I18n.t("dc.saveYou1") + " R" + steps.affordability.value  +  I18n.t("dc.saveYou2"),
    trigger: 'callYesNo'
  },
  {
    id: 'DCFailFail',
    message: ({ steps }) => steps.firstName.value + " " +  I18n.t("dc.saveYou1") + " R" + steps.affordability.value  +  I18n.t("dc.saveYou2"),
    trigger: 'callYesNo'
  },
  {
    id: 'DCFailPass',
    message: I18n.t("dc.DCFailPass"),
    trigger: 'budgetTool'
  },
  {
    id: 'DCPassPass',
    message: I18n.t("dc.DCPassPass"),
    trigger: 'budgetTool'
  },
  {
    id: 'budgetTool',
    message: I18n.t("dc.budgetTool"),
    trigger: 'noSaveEnd'
  }
]

  let creditCheckSteps = (stepState) => [
  //CreditCheck
  {
    id: 'CreditCheck',
  message: I18n.t('cc.CreditCheck'),
  trigger: 'CreditCheck2'
  },
  {
  id: 'CreditCheck2',
  message: I18n.t('cc.CreditCheck2'),
  trigger: 'getTerms'
  },
  {
    id: 'CreditCheckPassFail',
    message: I18n.t('cc.CreditCheckPassFail'), trigger: 'CreditCheckPassFail2'
    },
    {
    id: 'CreditCheckPassFail2',
    message: ({ steps }) => steps.firstName.value + " " +  I18n.t("cc.saveYou1") + " R" + (steps.affordability ? steps.affordability.value : '') + " " + I18n.t("cc.saveYou2"),
    trigger: 'callYesNo'
  },
  {
    id: 'CreditCheckFailPass',
    message: ({ steps }) => steps.firstName.value + " " +  I18n.t("cc.saveYou1") + " R" + (steps.affordability ? steps.affordability.value : '') + " " + I18n.t("cc.saveYou2"),
    trigger: 'callYesNo'
  },
{
    id: 'CreditCheckFailFail',
    message: ({ steps }) => I18n.t("cc.FailFail") +  I18n.t("cc.saveYou1") + (steps.affordability ? steps.affordability.value : '') + I18n.t("cc.saveYou2"),
    trigger: 'callYesNo'
  },
  {
    id: 'CreditCheckPassPass',
    message:  I18n.t('cc.CreditCheckPassPass') ,
    trigger: 'budgetTool'
  }
]

let debtConsolidationSteps = (stepState) => [

  // Debt Consolidation
  {
    id: 'DebtConsolidation',
  message: I18n.t('debtC.DebtConsolidation'),
  trigger: 'DebtC2'
  },
  {
  id: 'DebtC2',
  message: I18n.t('debtC.DebtC2'),
  trigger: 'whatHelpPrompt'
  },
  {
    id: 'whatHelpPrompt',
    message: I18n.t('whatHelpPrompt'),
    trigger: 'menuOption'
  }]


let clSteps = (stepState) => [

  // Credit Life Insurance
  {
    id: 'cl',
    message: I18n.t('cl.cl'),
    trigger: 'getTerms'
  },
  {
    id: 'clPassFail',
    message:'...',
    trigger: 'cl2'
  },
  {
    id: 'clFailPass',
    message:'...',
    trigger: 'cl2'
  },
  {
    id: 'clFailFail',
    message:'...',
    trigger: 'cl2'
  },
  {
    id: 'clPassPass',
    message:'...',
    trigger: 'cl2'
  },
  {
    id: 'cl2',
    message: I18n.t('cl.cl2'),
    trigger: 'clHelp'
  },
  {
    id: 'clHelp',
    message: ({ steps }) => `According to our records you have ${steps.affordability.value.accounts} account(s) with R${Numeral(steps.affordability.value.debt).format('###,###0')} of debt. You probably pay around R${Numeral(steps.affordability.value.cli).format('###,###0')}  per month for insurance on this.`,
    trigger: 'yourDebt'
  },
  {
    id: 'yourDebt',
    message: I18n.t('cl.clHelp'),
    trigger: 'clYesNoPrompt'
  },
  {
    id: 'clYesNoPrompt', hideInput: true,
    options: [
      { value: "yes", label: I18n.t("Yes"), trigger: 'clYes' },
      { value: "no", label: I18n.t("No"), trigger: 'youSureYesNo' }
    ]
  },
  {
    id: 'clYes',
    message: I18n.t('cl.clNeedSig'),
    trigger: 'clGetSigYesNo'
  },
  {
    id: 'clGetSigYesNo', hideInput: true,
    options: [
      { value: "yes", label: I18n.t("Yes"), trigger: 'clSignature' },
      { value: "no", label: I18n.t("No"), trigger: 'youSureYesNo' }
    ]
  },
  {
    id: 'clSignature',
    hideInput: true,
    asMessage: false,
    component: <POA location={stepState.location} from={"moku"} />,
  },
  {
    id: 'clSigned',
    hideInput: true,
     message: `Just one last thing, would you mind letting us know if you smoke or not?`,
     trigger: 'clSmoke'
  },
  {
    id: 'clSmoke',
    hideInput: true,
    options: [
      { value: "yes", label: I18n.t("cl.SmokerYes"), trigger: 'clThanks' },
      { value: "no", label: I18n.t("cl.SmokerNo"), trigger: 'clThanks' }
    ]
  },
  {
    id: 'clThanks',
    hideInput: true,
     message: ({ steps }) =>`Thanks so much ${steps.firstName.value}, we’ll be in touch shortly with a competitive quote. Look out for our SMS.`,
  trigger: 'saveLeadInfo'
  }

]

// End Credit Life

const getAllSteps = (stepState) => {
  let steps = [...commonSteps(stepState), ...clSteps(stepState), ...loanSteps(stepState), ...dcSteps(stepState), ...creditCheckSteps(stepState), ...debtConsolidationSteps(stepState)]
  return addLogging(steps)
}

export {
  getAllSteps
}
