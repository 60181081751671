//first value should be same as the trigger in steps

const af = {
  loan: {
  "Loan":"Wonderlik, kom ons kyk wat ons vir jou kan doen in die leningsdepartement!",
  "Loan2":"Jy kwalifiseer vir 'n lening op grond van twee elemente - risiko en bekostigbaarheid.",
  "Loan3":"Risiko word gemeet deur jou kredietgradering. Dit kyk na jou huidige besteding, enige wanbetalings, oorgeslane betalings of oordelings van skuld wat jy dalk het. 'n Lae gradering is sleg. 'n Hoë gradering is goed.",
  "LoanAffordability":"Bekostigbaarheid is jou inkomste minus jou uitgawes. Alle kredietverskaffers het hul eie siening van risiko. Jou resultaat is dus 'n aanduiding. Kom ons kyk na bekostigbaarheid.",
  "LoanPassFail":"Jou kredietgradering wys dat jy 'n lae risiko is maar jou finansiële vermoë om bestaande en nuwe skuld te betaal, is swak. Jy kan krediet geweier word.",
  "LoanFailFail":"Jou kredietgradering is swak en jy het nie die finansiële vermoë om nuwe of bestaande skuld te betaal nie. Ek's jammer, maar dis onwaarskynlik dat jy krediet toegestaan gaan word. ",
  "LoanFailPass":"Jy het die finansiële vermoë om jou bestaande en nuwe skuld te betaal, maar jou kredietgradering wys dat jy 'n hoë risiko is. Jy kan krediet geweier word of 'n hoër rentekoers gehef word. ",
  "LoanPassPass": "Jy het 'n goeie kredietgradering en die finansiële vermoë om bestaande en nuwe skuld te betaal. Geluk! Jy sal waarskynlik vir verdere krediet kwalifiseer. ",
  },
  dc: {
  otherServicesPrompt: "Sal jy graag na ons ander dienste wil kyk?",
  "DC":"Jy kwalifiseer vir skuldberading as jy voltyds werk en oorverskuldig is.  ",
  "DC2":"'n Goeie duimreël dat jy oorverskuldig is, is as meer as die helfte van jou salaris, ná belasting, op skuldterugbetalings bestee word. ",
  "saveYou1":"dit lyk asof ons jou tot",
  "saveYou2":"elke maand bespaar. Kan een van my mensekollegas jou bel?",
  "DCFailPass":"Uit jou bekostigbaarheid lyk dit asof jy jou huidige kredietooreenkomste kan betaal. As jy elke maand sukkel, moet jy dalk probeer kyk of jy jou maandelikse uitgawes kan verminder.  ",
  "DCPassPass":"Uit jou bekostigbaarheid lyk dit asof jy jou huidige kredietooreenkomste kan betaal. As jy elke maand sukkel, moet jy dalk kyk of jy jou maandelikse uitgawes kan verminder.  ",
  "budgetTool":"As jy steeds bekommerd is oor jou finansies, gaan gerus na www.meerkat.co.za om na ons begrotingsinstrument te kyk.",
  },
  cc: {
    "CreditCheck": "Jou kredietprofiel sê vir leners of jy 'n goeie kandidaat is vir die krediet waarvoor jy aansoek doen of nie. ",
    "CreditCheck2": "Jou profiel is gebaseer op huidige kredietverbruik, wanbetalings of oordelings van skuld wat jy dalk het. 'n Lae gradering is sleg en 'n hoë gradering is goed. ",
    "CreditCheckPassFail": "Jou profiel lyk heel goed 😎 Geluk! Moet ek jou bekostigbaarheid ook nagaan?",
    "FailFail": "Jou profiel lyk nie te goed nie 🙁 Jy kan dalk sukkel om krediet te kry. ",
    "CreditCheckPassPass": "Uit jou bekostigbaarheid lyk dit asof jy jou huidige kredietooreenkomste kan betaal. As jy elke maand sukkel, moet jy dalk kyk of jy jou maandelikse uitgawes kan verminder. ",
    "saveYou1": "dit lyk asof ons jou tot R",
    "saveYou2": "Kan een van my menslike kollegas jou dalk skakel?"
  },
  debtC: {
    "DebtConsolidation":"Skuldkonsolidasie is waar jy 'n lening uitneem om jou skulde te betaal. Jy het dan een terugbetaling elke maand. Jy moet vir 'n lening kwalifiseer. Skuldkonsolidasie help jou egter nie om jou skuld te verminder nie.",
    "DebtC2":"Skuldberading is soortgelyk aan skuldkonsolidasie, maar jy neem nie 'n lening uit nie. Jou maandelikse betaling word eerder gekonsolideer in een verminderde maandelikse terugbetaling en dikwels word die rentekoerse verlaag. Dit is anders as skuldkonsolidasie, waar rentekoerse hoog kan wees. ",
  },
  cl: {
    "cl":"Great! Most of your loans and accounts have credit life insurance included as part of your premium. We might be able to reduce this to a more affordable amount.",
    "yourDebt": `according to our records you have "X accounts with Rx of DEBT.`,
    "cl2": `Normally we can save our customers on replacements. Putting more money back in your pocket.`,
    "clHelp": `Would you like us to have a look at this for you?`,
    "clNeedSig": "Thanks! I just need your permission for us to contact your credit providers on your behalf to confirm the actual amount of insurance you are paying.",
    "SmokerYes": "Yes, I smoke",
    "SmokerNo":"No, I don't"
  },
  intro: "Haai! 👋 Ek's Moku, jou robotassistent. Ek's hier om jou te help om van jou skuld ontslae te raak.",
  "intro_2":"As jy my 'n bietjie inligting gee, kan ek kyk of jou kredietgradering goed of sleg is, of jy vir 'n lening kwalifiseer en of ons skuldhersieningsproses jou kan help. ",
  "start": "Wil jy voortgaan?",
  "GetStarted": "Begin",
  "first":"Wat is jou voornaam?",
  "firstName":"voer asseblief jou voornaam in",
  "last":"Wat is jou van?",
  "Nice":"Aangename e-kennis",
  "AreUaCitizen":"! Voordat ons begin, wil ek net graag bevestig dat jy 'n Suid-Afrikaanse ID-nommer het.",
  "soSorry":"Ek's so jammer",
  "notSA":"maar op die oomblik kan ons slegs permanente Suid-Afrikaanse inwoners help.",
  "smile":"Dit beteken egter nie dat ek nie steeds 'n glimlag op jou gesig kan sit nie. Wil jy iets regtig oulik sien?",
  "menu":"Hoe kan ek jou vandag help?",
  "letMeSee":"Laat ek sien wat ek kan doen",
  "needPermission":"Ek benodig jou toestemming om die nodige inligting te kan kry, so aanvaar asseblief ons bepalings en voorwaardes. Jy kan dit lees deur op die ? te klik.",
  "anywayCanWeCall":"Reg so. Moet een van my mensekollegas jou bel?",
  "anywayCall":"Reg so. Bevestig net asseblief jou telefoonnommer.",
  "thanksanyway":"Dankie! Ek het jou besonderhede na ons inbelsentrum gestuur. Een van my mensekollegas sal jou binnekort bel!",
  "pity":"Dis nou jammer,",
  "getting":"ek het jou pas leer ken. Besoek asseblief www.meerkat.co.za vir enige verdere inligting.",
  "ThankYou":"Baie dankie",
  "confirm":"Gee jy om om jou telefoonnommer vir my te bevestig asseblief?",
  "invalidCell":"Jou selfoonnommer is ongeldig",
  "enterCell":"voer asseblief 'n selfoonnommer in",
  "working":"Wonderlik!👍.  Dit mag dalk 'n bietjie persoonlik wees, maar ek moet vra om jou te kan help. Het jy 'n werk?",
  "otherIncome":"Het jy enige ander soort gereelde inkomste?",
  "sorry":"Oeps, jammer! Ons kan slegs mense help wat 'n gereelde inkomste verdien. Besoek asseblief www.meerkat.co.za vir meer inligting aangaande skuld.",
  "incomeDetails":"Reg so, perfek. Hoeveel geld verdien jou huishouding elke maand?",
  "income2":"Indien van toepassing, wat is jou en jou lewensmaat se inkomstes?",
  "number":"Voer asseblief 'n bedrag in",
  "justNumber":"Voer asseblief slegs die bedrag in.",
  "idPrompt":"Kan ek ook jou ID-nommer kry?",
  "idNumberPrompt":"Reg so, voer asseblief jou ID-nommer in",
  "marriedPrompt":"Kan ek ook vra of jy getroud is of saam met iemand woon? Die inligting sal my help om jou beter by te staan.",
  "dependentsPrompt":"En vir hoeveel afhanklikes (insluitend jou eggenoot en uitgebreide familie) is jy verantwoordelik?",
  "expensesPrompt":"Kan jy vir my sê wat jou totale gemiddelde maandelikse uitgawes is?",
  "emailPrompt":"Voordat ons ons ontleding doen, wil jy graag 'n afskrif van jou kredietverslag hê?",
  "getEmail":"Wat is jou e-posadres?",
  "noEmail":"Voer asseblief jou e-posadres in",
  "invalidEmail":"E-posadres is nie geldig nie",
  "challenge1":"Ons word verplig om jou identiteit na te gaan voordat ons hierdie inligting kan vrystel. Beantwoord asseblief die volgende vrae:",
  "passedValidaton":"Wonderlik! Jy het die toets geslaag! Ek stuur nou die verslag.",
  "SendMsg":"verslag is gestuur, hier is 'n grafiese opsomming van jou risikogradering",
  "verificationFailed":"Ek's jammer, jy het nie die validasietoets geslaag nie en ek kan dus nie die verslag e-pos nie, maar ek kan steeds my ontleding doen.",
  "saveBureau1":"Een opsie wat kan help, is ons skuldberadingsproses. Ek kan jou tot soveel as",
  "saveBureau2":"per maand bespaar, maar ek is nuut met hierdie geselsding, so kan een van my mensekollegas jou dalk bel?",
  "youSure1":"Werklik? Jy wil nie",
  "youSure2":"elke maand spaar nie?",
  "so":"So,",
  "callYou":"kan een van my mensekollegas jou bel? Daar is geen verpligting vir jou nie.",
  "checkNumber":"Kan ek jou bel op",
  "newNumber":"Wat is jou selfoonnommer?",
  "timeOfDay":"Watter tyd van die dag moet ons bel?",
  "thanks":"Wonderlik! Een van my kollegas sal jou dan kontak.",
 // "working":"werk jy?",
  "sorry1":"Ek's so jammer ",
  "sorry2":"maar op die oomblik kan ons slegs diegene help wat 'n werk het",
  "bandMessage":"Ek en my broers is in 'n musiekgroep. Toerdatums sal binnekort bekendgemaak word 😉. Geniet jou dag!",
  "okThen":"Reg so,",
  "eMeet":"dit was lekker om jou te e-ontmoet",
  "saveEnd":"😀 Dit was baie lekker om jou te e-ontmoet!",
  "errorEnd":"🙈 Ek's jammer maar dit lyk of daar aan my binnegoed gewerk word.",
  "callAnyway":"Moet een van my mensekollegas jou bel om hierdie gesprek voort te sit?",
  "saveLeadError":"🙈  Ek's jammer maar dit lyk of daar aan my binnegoed gewerk word. Kom asseblief 'n bietjie later terug. ",
  "comeAgain":"As jy weer wil gesels, sal ek hier wees, maar jy kan my ook by 021 003 4535 bel. ",
  "byeBye":"Totsiens",
  "whatHelpPrompt":"So, waarmee moet ons jou bystaan?",
  "yesPlease": "ja, asseblief",
  "noThanks": "Nee dankie",
  "keepChattingYes": "Ek will graag aanhou gesels",
  "keepChattingNo": "Ek wil graag hê dat iemand my moet bel",
  "invalidID": "Jou ID nommer is ongeldig",
  "Yes":"Ja",
  "No":"Nee",
  "Married":"Getroud",
  "Single":"Enkellopend",
  "LiveTogether":"Woon saam",
  "Divorced":"Geskei",
  "Separated":"Vervreemd",
  "Now":"Nou",
  "Afternoon":"Middag",
  "Morning":"Oggend",
  "Evening":"Aand",
  "Thanks": "Dankie",
  "occupationPmt": "Wat is jou beroep?",
  "occupation": "Voer asseblief u beroep in",
  "employerPmt":"Waar is jy in diens (naam van werkgewer)?",
  "employer": "Vul asseblief u werkgewer in?",
  'menuLoan': "Lening",
  'menuDC': 'Debt Skuldberading',
  'menuCC': 'Kredietbeheer',
  'menuDC:nsolidate': 'Skuldkonsolidasie',
  'menuCLI':'Goedkoper versekering op lenings',
  'placeHolder':'Tik asseblief hier',
  'accept': 'Aanvaar',
  'cancel':'kanselleer',
  'selfEmployed': 'Self Employed', 'partTime': 'Part-Time', 'fullTime': 'Fully Employed',
  'employerTypePmt':'How are you employed?'

  }


export default af