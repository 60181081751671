import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {age } from './calculations'
import {
  isValidCellphoneNumber,
  isValidIdNumber,
  isValidEmail,
  isInteger
} from './Validator';
import { Terms } from './t&c';
import { OptionButtons, services, WhereNext } from './menu';
import {
  PercentPie,
  Affordability,
  Band,
  Challenge,
  CompuScan,
  Employer,
  Occupation,
  SaveLeadInfo,
  StartChatSession,
  SendCreditReport
} from './customComponents';
import { addLogging } from './lib';
import I18n from './strings/i18n';
import Numeral from 'numeral'
import 'numeral/locales/en-za'

//value={Numeral(policy.currentPremiumTotal).format('###,###0')}

let commonSteps = stepState => [
  // Introduction
  {
    id: 'intro',
    hideInput: true,
    asMessage: true,
    component: (
      <StartChatSession
        message={I18n.t('first')}
        language={I18n.locale}
        location={stepState.location}
      />
    )
  },
  {
    id: 'intro_2',
    hideInput: true,
    message: I18n.t('intro_2'),
    trigger: 'start'
  },
  {
    id: 'start',
    hideInput: true,
    message: I18n.t('start'),
    trigger: 'start-question'
  },
  {
    id: 'start-question',
    hideInput: true,
    options: [
      {
        value: 'GetStarted',
        label: I18n.t('GetStarted'),
        trigger: 'first'
      }
    ]
  },

  // Name
  { id: 'first', message: I18n.t('first'), trigger: 'firstName' },
  {
    id: 'firstName',
    user: true,
    placeholder: I18n.t('firstName'),
    validator: value => {
      if (value) {
        return true;
      } else return I18n.t('firstName');
    },
    trigger: 'last'
  },
  {
    id: 'last',
    message: I18n.t('last'),
    trigger: 'lastName'
  },
  {
    id: 'lastName',
    user: true,     placeholder: I18n.t('placeHolder'),
    trigger: 'AreUaCitizen'
  },

  // Citizen?

  {
    id: 'AreUaCitizen',
    hideInput: true,
    message: ({ steps }) =>
      I18n.t('Nice') +
      ' ' +
      steps.firstName.value +
      '! ' +
      I18n.t('AreUaCitizen'),
    trigger: 'SACitizen'
  },
  {
    id: 'SACitizen',
    hideInput: true,
    options: [
      { value: 'yes', label: I18n.t('Yes'), trigger: 'menu' },
      { value: 'no', label: I18n.t('No'), trigger: 'NotSA' }
    ]
  },

  // Sorry, only SA citizens
  {
    id: 'NotSA',
    hideInput: true,
    message: ({ steps }) =>
      I18n.t('soSorry') +
      ' ' +
      steps.firstName.value +
      ' ' +
      I18n.t('notSA') +
      ' 😞.',
    trigger: 'smile'
  },
  {
    id: 'smile',
    hideInput: true,
    message: I18n.t('smile'),
    trigger: 'theHappyEnd'
  },

  // menu?

  {
    id: 'menu',
    hideInput: true,
    message: ({ steps }) => I18n.t('menu'),
    trigger: 'menuOption'
  },
  {
    id: 'menuOption',
    hideInput: true,
    component: <OptionButtons />,
    asMessage: false
  },

  // Terms .. if already approved go to affordability
  {
    id: 'getTerms',
    hideInput: true,
    message: ({ steps }) =>
      steps.terms && steps.terms.value == 'yes'
        ? I18n.t('letMeSee')
        : I18n.t('needPermission'),
    trigger: ({ steps }) =>
      steps.terms && steps.terms.value == 'yes'
        ? 'affordability'
        : 'termsPrompt'
  },
  {
    id: 'termsPrompt',
    hideInput: true,
    component: <Terms />,
    asMessage: false,
    trigger: 'terms'
  },
  {
    id: 'terms',
    hideInput: true,
    options: [
      { value: 'yes', label: I18n.t('Yes'), trigger: 'phone' },
      { value: 'no', label: I18n.t('No'), trigger: 'anywayCanWeCall' }
    ]
  },

  // No permission, can we Call you
  {
    id: 'anywayCanWeCall',
    message: I18n.t('anywayCanWeCall'),
    trigger: 'anywayYesNo'
  },
  {
    id: 'anywayYesNo',
    hideInput: true,
    options: [
      { value: 'yes', label: I18n.t('Yes'), trigger: 'anywayCall' },
      { value: 'no', label: I18n.t('No'), trigger: 'pity' }
    ]
  },
  {
    id: 'anywayCall',
    placeholder: 'cell number',
    message: I18n.t('anywayCall'),
    trigger: 'anywayCellNumber'
  },
  {
    id: 'anywayCellNumber',
    update: 'cellNumber',
    trigger: 'thanksanyway'
  },
  {
    id: 'thanksanyway',
    message: I18n.t('thanksanyway'),
    trigger: 'saveEnd'
  },
  {
    id: 'pity',
    message: ({ steps }) =>
      I18n.t('pity') + ' ' + steps.firstName.value + ' ' + I18n.t('getting'),
    trigger: 'noSaveEnd'
  },

  // Phone
  {
    id: 'phone',
    placeholder: 'cell number',
    message: ({ steps }) =>
      I18n.t('ThankYou') +
      ' ' +
      steps.firstName.value +
      '. ' +
      I18n.t('confirm'),
    trigger: 'cellNumber'
  },
  {
    id: 'cellNumber',
    user: true,     placeholder: I18n.t('placeHolder'),
    validator: (value, steps) => {
      if (value) {
        if (isValidCellphoneNumber(value)) {
          return I18n.t('invalidCell');
        }
        return true;
      } else return I18n.t('enterCell');
    },
    trigger: 'working'
  },
  {
    id: 'working',
    message: I18n.t('working'),
    trigger: 'workingPrompt'
  },
  {
    id: 'workingPrompt',
    hideInput: true,
    options: [
      { value: 'yes', label: I18n.t('Yes'), trigger: 'incomeDetails' },
      { value: 'no', label: I18n.t('No'), trigger: 'otherIncome' }
    ]
  },
  {
    id: 'otherIncome',
    message: I18n.t('otherIncome'),
    trigger: 'otherIncomePrompt'
  },
  {
    id: 'otherIncomePrompt',
    hideInput: true,
    options: [
      { value: 'yes', label: I18n.t('Yes'), trigger: 'incomeDetails' },
      { value: 'no', label: I18n.t('No'), trigger: 'sorry' }
    ]
  },

  {
    id: 'sorry',
    message: I18n.t('sorry1'),
    trigger: 'noSaveEnd'
  },
  {
    id: 'incomeDetails',
    message: I18n.t('incomeDetails'),
    trigger: 'salary'
  },
  {
    id: 'income2',
    message: I18n.t('income2'),
    trigger: 'salary'
  },
  {
    id: 'salary',
    user: true,     placeholder: I18n.t('placeHolder'),
    validator: (value, steps) => {
      if (!value) return I18n.t('number');
      if (isInteger(value)) {
        return I18n.t('justNumber');
      }
      return true;
    },
    trigger: 'idPrompt'
  },
  {
    id: 'idPrompt',
    message: ({ steps }) =>
      I18n.t('Thanks') +
      ' ' +
      steps.firstName.value +
      ', ' +
      I18n.t('idPrompt'),
    trigger: 'keepChatting'
  },
  {
    id: 'keepChatting',
    hideInput: true,
    options: [
      {
        value: 'yes',
        label: I18n.t('keepChattingYes'),
        trigger: 'idNumberPrompt'
      },
      {
        value: 'no',
        label: I18n.t('keepChattingNo'),
        trigger: 'checkNumber'
      }
    ]
  },
  {
    id: 'idNumberPrompt',
    message: ({ steps }) => I18n.t('idNumberPrompt'),
    trigger: 'idNumber'
  },
  {
    id: 'idNumber',
    user: true,     placeholder: I18n.t('placeHolder'),
    validator: (value, steps) => {
      if (!value) return I18n.t('number');
      if (isValidIdNumber(value)) {
        return I18n.t('invalidID');
      }
      return true;
    },
    trigger: ({ value, steps }) =>
      (steps.menuOption.value == '805' && age(value)) > 55
        ? 'over55'
        : 'compuscan'
  },
  {
    id: 'over55',
    message:
      "Oh dear, I'm so sorry but we can't offer insurance to people over 55.",
    trigger: 'smile'
  },
  {
    id: 'compuscan',
    delay: 100,
    hideInput: true,
    component: <CompuScan />,
    asMessage: true
  },
  {
    id: 'occupationPmt',
    message: I18n.t('occupation'),
    trigger: 'occupation'
  },
  {
    id: 'occupation',
    hideInput: true,
    component: <Occupation />,
  },
  {
    id: 'employerPmt',
    message: I18n.t('employerPmt'),
    trigger: 'employer'
  },
  {
    id: 'employer',
    delay: 100,
    hideInput: true,
    component: <Employer  />
  ,
    asMessage: false
  },
  {
    id: 'employTypePmt',
    message: I18n.t('employerTypePmt'),
    trigger: 'employType'
  },
  {
    id: 'employType',
    delay: 100,
    hideInput: true,
    options: [
      {
        value: 'Self',
        label: I18n.t('selfEmployed'),
        trigger: 'emailPrompt'
      },
      {
        value: 'Part',
        label: I18n.t('partTime'),
        trigger: 'emailPrompt'
      },
      {
        value: 'Full',
        label: I18n.t('fullTime'),
        trigger: 'emailPrompt'
      }
    ]
  },
  {
    id: 'employerOLD',
    user: true,
    placeholder: I18n.t('employer'),
    validator: (value, steps) => {
      if (value) {
        return true;
      } else return I18n.t('employer');
    },
    trigger: 'emailPrompt'
  },
  {
    id: 'marriedPrompt',
    message: I18n.t('marriedPrompt'),
    trigger: 'married'
  },
  {
    id: 'married',
    hideInput: true,
    options: [
      {
        value: 'married',
        label: I18n.t('Married'),
        trigger: 'dependentsPrompt'
      },
      { value: 'single', label: I18n.t('Single'), trigger: 'dependentsPrompt' },
      {
        value: 'divorced',
        label: I18n.t('Divorced'),
        trigger: 'dependentsPrompt'
      },
      {
        value: 'livetogether',
        label: I18n.t('LiveTogether'),
        trigger: 'dependentsPrompt'
      },
      {
        value: 'seperated',
        label: I18n.t('Separated'),
        trigger: 'dependentsPrompt'
      }
    ]
  },
  {
    id: 'dependentsPrompt',
    message: I18n.t('dependentsPrompt'),
    trigger: 'dependants'
  },
  {
    id: 'dependants',
    user: true,     placeholder: I18n.t('placeHolder'),
    validator: (value, steps) => {
      if (!value) return I18n.t('number');
      if (isInteger(value)) {
        return I18n.t('justNumber');
      }
      return true;
    },
    trigger: 'expensesPrompt'
  },
  {
    id: 'expensesPrompt',
    message: I18n.t('expensesPrompt'),
    trigger: 'expenses'
  },
  {
    id: 'expenses',
    user: true,     placeholder: I18n.t('placeHolder'),
    validator: (value, steps) => {
      if (!value) return I18n.t('number');
      if (isInteger(value)) {
        return I18n.t('justNumber');
      }
      return true;
    },
    trigger: 'emailPrompt'
  },
  {
    id: 'emailPrompt',
    message: I18n.t('emailPrompt'),
    trigger: 'emailReport'
  },
  {
    id: 'emailReport',
    hideInput: true,
    options: [
      { value: 'yes', label: I18n.t('Yes'), trigger: 'getEmail' },
      { value: 'no', label: I18n.t('No'), trigger: 'noReport' }
    ]
  },
  {
    id: 'noReport',
    message: I18n.t('noReport'),
    trigger: 'affordability'
  },
  {
    id: 'getEmail',
    message: I18n.t('getEmail'),
    trigger: 'email'
  },
  {
    id: 'email',
    user: true,     placeholder: I18n.t('placeHolder'),
    validator: (value, steps) => {
      if (!value) return I18n.t('noEmail');
      if (isValidEmail(value)) {
        return I18n.t('invalidEmail');
      }
      return true;
    },
    trigger: 'challenge1'
  },
  {
    id: 'challenge1',
    message: I18n.t('challenge1'),
    trigger: 'challenge'
  },

  {
    id: 'challenge',
    delay: 100,
    hideInput: true,
    asMessage: true,
    component: <Challenge />
  },
  {
    id: 'passedValidaton',
    hideInput: true,
    message: I18n.t('passedValidaton'),
    trigger: 'sendCreditReport'
  },
  {
    id: 'sendCreditReport',
    hideInput: true,
    asMessage: true,
    component: <SendCreditReport message={I18n.t('SendMsg')} />
  },
  {
    id: 'RiskPie',
    hideInput: true,
    asMessage: true,
    component: <PercentPie />,
    trigger: 'affordability'
  },
  {
    id: 'AffordPie',
    hideInput: true,
    asMessage: true,
    component: <PercentPie />,
    trigger: 'affordability'
  },
  {
    id: 'verificationFailed',
    message: I18n.t('verificationFailed'),
    trigger: 'affordability'
  },
  {
    id: 'affordability',
    hideInput: true,
    asMessage: false,
    component: <Affordability />
  },
  {
    id: 'saveBureau',
    message: ({ steps }) =>
      I18n.t('saveBureau1') +
      ' R' +
      steps.affordability.value +
      ' ' +
      I18n.t('saveBureau2'),
    trigger: 'callYesNo'
  },
  {
    id: 'callYesNo',
    hideInput: true,
    options: [
      {
        value: 'yes',
        label: I18n.t('yesPlease') + ' Moku',
        trigger: 'checkNumber'
      },
      { value: 'no', label: I18n.t('noThanks') + ' Moku', trigger: 'youSure' }
    ]
  },
  {
    id: 'youSure',
    message: ({ steps }) =>
      I18n.t('youSure1') +
      ` R${
        steps.menuOption.value == '805'
          ? steps.affordability.value.outstandingDebt * 0.2
          : steps.affordability.value
      } ` +
      I18n.t('youSure2'),
    trigger: 'sureYesNo'
  },
  {
    id: 'youSureYesNo',
    message: ({ steps }) => steps.firstName.value + ' ' + I18n.t('callYou'),
    trigger: 'sureYesNo'
  },
  {
    id: 'sureYesNo',
    hideInput: true,
    options: [
      {
        value: 'yes',
        label: I18n.t('yesPlease'),
        trigger: 'checkNumber'
      },
      {
        value: 'no',
        label: I18n.t('noThanks'),
        trigger: 'noSaveEnd'
      }
    ]
  },
  // check phone number
  {
    id: 'checkNumber',
    message: ({ steps }) =>
      I18n.t('checkNumber') + ' ' + steps.cellNumber.value + '?',
    trigger: 'numberYesNo'
  },
  {
    id: 'numberYesNo',
    hideInput: true,
    options: [
      {
        value: 'yes',
        label: I18n.t('Yes'),
        trigger: 'goodNumber'
      },
      {
        value: 'no',
        label: I18n.t('No'),
        trigger: 'newNumber'
      }
    ]
  },
  {
    id: 'newNumber',
    placeholder: 'cell number',
    message: I18n.t('newNumber'),
    trigger: 'newCellNumber'
  },
  {
    id: 'newCellNumber',
    update: 'cellNumber',
    trigger: 'goodNumber'
  },
  {
    id: 'goodNumber',
    message: I18n.t('timeOfDay'),
    trigger: 'timePrompt'
  },
  // Time to call
  {
    id: 'timePrompt',
    hideInput: true,
    options: [
      { value: 'now', label: I18n.t('Now'), trigger: 'thanks' },
      { value: 'morning', label: I18n.t('Morning'), trigger: 'thanks' },
      { value: 'afternoon', label: I18n.t('Afternoon'), trigger: 'thanks' },
      { value: 'evening', label: I18n.t('Evening'), trigger: 'thanks' }
    ]
  },
  {
    id: 'thanks',
    message: I18n.t('thanks'),
    trigger: 'saveLeadInfo'
  },

  /* Should add code to check if under 55
  {
    id: 'howOld',
    hideInput: true,
    message: ({ steps }) =>
      'I see that you are ' +
      age(steps.idNumber.value) +
      ' years old 😉',
    trigger: 'working2'
  },
*/
  // Working
  {
    id: 'working2',
    hideInput: true,
    message: ({ steps }) => steps.firstName.value + ', are you working?',
    trigger: 'isWorking'
  },
  {
    id: 'isWorking',
    hideInput: true,
    options: [
      { value: 'yes', label: I18n.t('Yes'), trigger: 'incomeDetails' },
      { value: 'no', label: I18n.t('No'), trigger: 'NotWorking' }
    ]
  },

  // Can't help people with no salary
  {
    id: 'NotWorking',
    hideInput: true,
    message: ({ steps }) =>
      'I am so sorry ' +
      steps.firstName.value +
      ' but at the moment we are only able to offer assistance to those who are working 😞.',
    trigger: 'smile'
  },
  {
    id: 'theHappyEnd',
    hideInput: true,
    options: [
      { value: 'yes', label: I18n.t('Yes'), trigger: 'meerkatBand' },
      {
        value: 'no',
        label: I18n.t('No') + ' ' + I18n.t('Thanks'),
        trigger: 'noSaveEnd'
      }
    ]
  },
  {
    id: 'meerkatBand',
    component: <Band />,
    hideInput: true,
    trigger: 'bandMessage'
  },
  {
    id: 'bandMessage',
    hideInput: true,
    message: I18n.t('bandMessage'),
    end: true
  },
  {
    id: 'noSaveEnd',
    hideInput: true,
    message: ({ steps }) =>
      I18n.t('okThen') + ' ' + steps.firstName.value + ', ' + I18n.t('eMeet'),
    trigger: 'comeAgain'
  },
  {
    id: 'saveEnd',
    message: I18n.t('saveEnd'),
    trigger: 'saveLeadInfo'
  },
  {
    id: 'errorEnd',
    message: I18n.t('errorEnd'),
    trigger: 'callAnyway'
  },
  {
    id: 'callAnyway',
    message: I18n.t('callAnyway'),
    trigger: 'saveLeadPrompt'
  },
  {
    id: 'saveLeadPrompt',
    hideInput: true,
    options: [
      { value: 'yes', label: I18n.t('Yes'), trigger: 'newNumber' },
      {
        value: 'no',
        label: I18n.t('No') + ' ' + I18n.t('Thanks'),
        trigger: 'noSaveEnd'
      }
    ]
  },
  { id: 'saveLeadInfo', component: <SaveLeadInfo />, trigger: 'comeAgain' },
  {
    id: 'saveLeadError',
    hideInput: true,
    message: I18n.t('saveLeadError'),
    trigger: 'comeAgain'
  },
  {
    id: 'comeAgain',
    hideInput: true,
    message: I18n.t('comeAgain'),
    trigger: 'byeBye'
  },
  { id: 'byeBye', hideInput: true, message: 'Bye bye', end: true }
];

export { commonSteps };

// Example steps to update a value
/*
  { id: 'finished', message: "We've finished! Check out your summary", trigger: 'review' },
  { id: 'review', hideInput: true, component: <Review />, asMessage: true, trigger: 'update' },
  { id: 'update', message: 'Would you like to update some field?', trigger: 'update-question' },
  {
    id: 'update-question', hideInput: true,
    options: [
      { value: 'yes', label: 'Yes', trigger: 'update-yes' },
      { value: 'no', label: 'No', trigger: 'theEnd' }
    ]
  },
  { id: 'update-yes', message: 'What field would you like to update?', trigger: 'update-fields' },
  {
    id: 'update-fields', hideInput: true,
    options: [
      { value: 'name', label: 'Name', trigger: 'update-name' },
      { value: 'ID', label: 'ID', trigger: 'update-idNumber' },
      { value: 'income', label: 'Income', trigger: 'update-income' }
    ]
  },
  { id: 'update-name', update: 'name', trigger: 'finished' },
  { id: 'update-idNumber', update: 'idNumber', trigger: 'finished' },
  { id: 'update-income', update: 'income', trigger: 'finished' },
*/
